import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import BaseController from 'uplisting-frontend/pods/base/controller';
import AccountConfigurationModel from 'uplisting-frontend/models/account-configuration';
import SecurityDepositSettingModel from 'uplisting-frontend/models/security-deposit-setting';
import BulkSecurityDepositSettingModel from 'uplisting-frontend/models/bulk-security-deposit-setting';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { isSaveDisabled } from 'uplisting-frontend/utils';
import { isAnyInvalid } from 'uplisting-frontend/validations/helpers';

export default class AutomateDepositsController extends BaseController {
  @service pricing!: Services['pricing'];
  @service analytics!: Services['analytics'];
  @service('repositories/security-deposit-setting')
  securityDepositSettingRepository!: Services['repositories/security-deposit-setting'];

  @cached @tracked securityDepositSettings!: SecurityDepositSettingModel[];

  @cached @tracked securityDepositsActivated!: boolean;
  @cached @tracked showActivateSecurityDepositsModal = false;

  bulkSecurityDepositSetting!: BulkSecurityDepositSettingModel;

  @cached
  get hasEnabledSecurityDeposits(): boolean {
    return this.securityDepositSettings.some((item) => item.enabled);
  }

  @cached
  get isSecurityDepositsActive() {
    return this.hasEnabledSecurityDeposits || this.securityDepositsActivated;
  }

  @cached
  get isEnabledForAll(): boolean {
    return this.securityDepositSettings.every((item) => item.enabled);
  }

  @cached
  get changeset(): GenericChangeset<AccountConfigurationModel> {
    return this.accountConfigurationRepository.buildChangeset(
      this.currentAccountConfiguration,
    );
  }

  @cached
  get securityDepositSettingChangesets(): GenericChangeset<SecurityDepositSettingModel>[] {
    const {
      securityDepositSettingRepository,
      bulkSecurityDepositSetting: { securityDepositSettings },
    } = this;

    return securityDepositSettings.map((item) =>
      securityDepositSettingRepository.buildChangeset(item),
    );
  }

  @action
  handleActivateSecurityDeposits(): void {
    this.securityDepositsActivated = true;

    this.analytics.trackEvent(AnalyticsEvents.securityDepositActivated);
  }

  @action
  async handleToggleSettingEnabled(
    setting: GenericChangeset<SecurityDepositSettingModel>,
  ): Promise<void> {
    this.toggleSettingEnabled(setting);

    await this.saveChanges(this.bulkSecurityDepositSetting);
  }

  @action
  async handleToggleConfiguration(field: string): Promise<void> {
    this.changeset[field] = !this.changeset[field];

    await this.saveChanges(this.changeset);
  }

  @action
  async handleChangeEnableForAll(): Promise<void> {
    const enabled = !this.isEnabledForAll;

    this.securityDepositSettingChangesets.forEach((setting) => {
      this.toggleSettingEnabled(setting, enabled);
    });

    await this.saveChanges(this.bulkSecurityDepositSetting);
  }

  @action
  async handleChangeDefaultSecurityDepositAmount(): Promise<void> {
    const validations = await this.changeset.validate(
      'defaultSecurityDepositAmount',
    );

    if (isAnyInvalid(validations)) {
      return;
    }

    await this.changeset.save();

    this.securityDepositSettings
      .filter((item) => item.amount === 0)
      .forEach((item) => {
        item.amount = this.changeset.defaultSecurityDepositAmount;
      });

    await this.saveChanges(this.bulkSecurityDepositSetting);
  }

  @action
  async handleSaveSecurityDepositSetting(
    setting: GenericChangeset<SecurityDepositSettingModel>,
  ): Promise<void> {
    const saveDisabled = await isSaveDisabled(setting);

    if (saveDisabled) {
      return;
    }

    // as we are saving `bulk-security-deposit-setting` record, we need to make sure our changes are applied
    setting.execute();

    await this.saveChanges(this.bulkSecurityDepositSetting);
  }

  private async saveChanges(
    model:
      | BulkSecurityDepositSettingModel
      | GenericChangeset<AccountConfigurationModel>,
  ): Promise<void> {
    try {
      await model.save();

      this.notifications.info(
        'automate_deposits.notifications.deposit_settings_saved',
      );
    } catch {
      this.notifications.error();
    }
  }

  public setDefaultState(): void {
    this.securityDepositsActivated = this.hasEnabledSecurityDeposits;
  }

  private toggleSettingEnabled(
    setting: GenericChangeset<SecurityDepositSettingModel>,
    value?: boolean,
  ): void {
    setting.enabled = value ?? !setting.enabled;

    // as we are saving `bulk-security-deposit-setting` record, we need to make sure our changes are applied
    setting.execute();
  }
}
