import Model, { attr } from '@ember-data/model';
import { type IPhotoSchema } from 'uplisting-frontend/models/schemas';

export default class PhotoModel extends Model implements IPhotoSchema {
  @attr('string') url!: string;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    photo: PhotoModel;
  }
}
