import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import orderBy from 'lodash-es/orderBy';
import BaseController from 'uplisting-frontend/pods/base/controller';
import ClientModel from 'uplisting-frontend/models/client';
import ClientPropertyModel from 'uplisting-frontend/models/client-property';
import { toggleValue } from 'uplisting-frontend/utils';
import { HasManyState } from 'uplisting-frontend/models/helpers';

export default class ClientsClientPropertiesController extends BaseController {
  @cached @tracked client!: ClientModel;
  @cached @tracked clientProperties!: ClientPropertyModel[];
  @cached @tracked isSubmitting = false;
  @cached @tracked clientPropertiesOrdered!: ClientPropertyModel[];
  @cached @tracked selectedClientProperties!: ClientPropertyModel[];

  @cached @tracked hasManyState!: HasManyState<
    ClientModel,
    ClientPropertyModel
  >;

  @cached
  get clientHasNoPropertiesSelected(): boolean {
    return this.selectedClientProperties.length === 0;
  }

  @cached
  get hasChanges(): boolean {
    return this.hasManyState.hasChanges(this.selectedClientProperties);
  }

  @cached
  get isSubmitDisabled(): boolean {
    return (
      this.clientHasNoPropertiesSelected ||
      this.isSubmitting ||
      !this.hasChanges
    );
  }

  @cached
  get availableProperties(): ClientPropertyModel[] {
    return this.clientProperties.filter(
      (property) => !this.computeIsDisabledProperty(property),
    );
  }

  @cached
  get isAllSelected(): boolean {
    return this.availableProperties.every((property) =>
      this.selectedClientProperties.includes(property),
    );
  }

  @action
  handleClientPropertyClick(clientProperty: ClientPropertyModel): void {
    toggleValue(this, 'selectedClientProperties', clientProperty);
  }

  @action
  computeIsDisabledProperty(property: ClientPropertyModel): boolean {
    return property.hasOwner && property.client !== this.client;
  }

  @action
  handelSelectAllClick(): void {
    if (this.isAllSelected) {
      this.selectedClientProperties = [];
    } else {
      this.selectedClientProperties = this.availableProperties;
    }
  }

  @action
  computeDisabledText(property: ClientPropertyModel): string {
    const text = this.intl.t(
      'clients_index.create.form.client_properties.already_linked',
    );
    return `(${text} <b>${property.client.name}</b>)`;
  }

  @action
  async handleUpdateListings(): Promise<void> {
    if (this.isSubmitDisabled) {
      return;
    }

    this.isSubmitting = true;
    this.client.set('clientProperties', this.selectedClientProperties);

    try {
      await this.client.save();

      this.hasManyState.applyChanges(this.client.clientProperties);
      this.isSubmitting = false;

      this.notifications.info();
    } catch {
      this.notifications.error();
    }
  }

  public orderClientProperties(): void {
    this.clientPropertiesOrdered = orderBy(
      this.clientProperties.slice(),
      [(item) => item.client === this.client, 'hasOwner', 'name'],
      ['desc', 'asc', 'asc'],
    );
  }

  public setSelectedClientProperties(): void {
    this.selectedClientProperties = this.client.clientProperties.slice();

    this.hasManyState = new HasManyState(this.client, 'clientProperties');
  }
}
