import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import BookingModel from 'uplisting-frontend/models/booking';
import GuestInvoiceModel from 'uplisting-frontend/models/guest-invoice';

interface IArgs {
  invoice?: GuestInvoiceModel;
  booking: BookingModel;
}

export interface BookingPriceGuestInvoiceSignature {
  Element: null;

  Args: IArgs;

  Blocks: {
    default: [];
  };
}

export default class UiBookingPriceGuestInvoiceComponent extends Component<BookingPriceGuestInvoiceSignature> {
  @cached @tracked showModal = false;

  @action
  handleShowModal() {
    this.showModal = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Price::GuestInvoice': typeof UiBookingPriceGuestInvoiceComponent;
  }
}
