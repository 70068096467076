import type Transition from '@ember/routing/transition';
import { hash } from 'rsvp';
import { type Registry as Services, inject as service } from '@ember/service';
import ActionBookingsRepliesIndexController from 'uplisting-frontend/pods/action/bookings/replies/index/controller';
import BaseRoute from 'uplisting-frontend/pods/base/route';

export default class ActionBookingsRepliesIndexRoute extends BaseRoute {
  @service('repositories/saved-reply')
  savedReplyRepository!: Services['repositories/saved-reply'];

  model() {
    return hash({
      savedReplies: this.savedReplyRepository.findAll(),
      booking: this.modelFor('action.bookings'),
    });
  }

  setupController(
    controller: ActionBookingsRepliesIndexController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, model);
  }
}
