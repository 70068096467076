import Transform from '@ember-data/serializer/transform';
import { cached, tracked } from '@glimmer/tracking';
import {
  type IAdvancedReportConfigurationCriteria,
  type IAdvancedReportConfigurationCriteriaFilter,
  type IAdvancedReportConfigurationCriteriaFields,
} from 'uplisting-frontend/models/schemas';

export class AdvancedReportCriteria {
  filter!: IAdvancedReportConfigurationCriteriaFilter;
  sort!: string;

  @cached @tracked fields!: IAdvancedReportConfigurationCriteriaFields;
  @cached @tracked timeZone!: string;

  originalTimeZone?: string;
  originalOccasionFields!: string;

  constructor(value: IAdvancedReportConfigurationCriteria) {
    Object.assign(this, {
      sort: value.sort,
      filter: value.filter,
      fields: value.fields,
      timeZone: value.time_zone,
    });

    this.originalTimeZone = value.time_zone;
    this.originalOccasionFields = value.fields.occasions;
  }

  @cached
  get hasChanges(): boolean {
    return (
      this.originalTimeZone !== this.timeZone ||
      this.originalOccasionFields !== this.fields.occasions
    );
  }

  public toQuery(): IAdvancedReportConfigurationCriteria {
    return {
      time_zone: this.timeZone,
      filter: this.filter,
      sort: this.sort,
      fields: this.fields,
    };
  }
}

export default class AdvancedReportCriteriaTransform extends Transform {
  deserialize(
    value: IAdvancedReportConfigurationCriteria,
  ): AdvancedReportCriteria {
    return new AdvancedReportCriteria(value);
  }

  serialize(
    value: AdvancedReportCriteria,
  ): IAdvancedReportConfigurationCriteria {
    if (!value) {
      return {} as IAdvancedReportConfigurationCriteria;
    }

    return value.toQuery ? value.toQuery() : value;
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    'advanced-report-criteria': AdvancedReportCriteriaTransform;
  }
}
