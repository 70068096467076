import DS from 'ember-data';
import { type Registry as Services, inject as service } from '@ember/service';
import { hash } from 'rsvp';
import type Transition from '@ember/routing/transition';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import ClientStatementModel from 'uplisting-frontend/models/client-statement';
import TaxModel from 'uplisting-frontend/models/tax';
import ClientStatementShowController from 'uplisting-frontend/pods/clients/statements/show/controller';

interface IClientStatementsShowModel {
  statement: DS.PromiseObject<ClientStatementModel>;
  taxes: DS.PromiseArray<TaxModel>;
}

interface IClientStatementsShowModelResolved {
  statement: ClientStatementModel;
  taxes: TaxModel[];
}

export default class ClientStatementsShowRoute extends PermittedRoute {
  @service('repositories/tax') taxRepository!: Services['repositories/tax'];
  @service('repositories/client')
  clientRepository!: Services['repositories/client'];

  @service('repositories/client-statement')
  clientStatementRepository!: Services['repositories/client-statement'];

  permission = 'client_statements.show';

  async model({ id }: { id: string }): Promise<IClientStatementsShowModel> {
    return hash<IClientStatementsShowModel>({
      statement: this.clientStatementRepository.findRecord(id, {
        reload: true,
      }),
      taxes: this.taxRepository.findAll(),
    });
  }

  setupController(
    controller: ClientStatementShowController,
    model: IClientStatementsShowModelResolved,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, {
      ...model,
      route: this,
    });

    controller.setActiveView();
  }
}
