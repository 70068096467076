import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import BookingModel from 'uplisting-frontend/models/booking';
import PaymentModel from 'uplisting-frontend/models/payment';
import {
  type IBookingPaymentSchema,
  BookingPaymentStatus,
} from 'uplisting-frontend/models/schemas';

export default class BookingPaymentModel
  extends Model
  implements IBookingPaymentSchema
{
  @attr('boolean') cardValid!: boolean;
  @attr('string') paymentStatus!: BookingPaymentStatus;
  @attr('string') stripeError!: string;
  @attr('boolean') splitPayment!: boolean;
  @attr('boolean') needsCharging!: boolean;
  @attr('string') externalCustomerLink!: string;

  @belongsTo('booking', { async: false, inverse: null })
  booking!: BookingModel;

  @hasMany('payment', { async: false, inverse: null })
  payments!: PaymentModel[];

  @cached
  get hasPaymentInformation(): boolean {
    return this.paymentStatus === BookingPaymentStatus.hasPaymentInformation;
  }

  @cached
  get canBeMarkedAsPaid(): boolean {
    const { payments } = this;

    const hasPayments = payments.length > 0;
    const isEveryPaid = payments.every((payment) => payment.isPaid);

    return !(hasPayments && isEveryPaid);
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'booking-payment': BookingPaymentModel;
  }
}
