import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import NetRevenueRuleModel from 'uplisting-frontend/models/net-revenue-rule';
import BaseController from 'uplisting-frontend/pods/base/controller';
import { toggleValue } from 'uplisting-frontend/utils';
import {
  type NetRevenueRuleAdditions,
  type NetRevenueRuleDeductions,
} from 'uplisting-frontend/models/schemas';

export default class InsightsSettingsController extends BaseController {
  @cached @tracked netRevenueRule!: NetRevenueRuleModel;

  @action
  async handleSave(): Promise<void> {
    try {
      await this.netRevenueRule.save();

      this.notifications.info('insights_settings.notifications.success');
    } catch {
      this.notifications.error();
    }
  }

  @action
  handleUpdateAttr(
    type: 'additions' | 'deductions',
    name: NetRevenueRuleAdditions | NetRevenueRuleDeductions,
  ) {
    toggleValue(this.netRevenueRule, type, name);
  }
}
