import templateOnly from '@ember/component/template-only';
import AutomatedReviewTemplateModel from 'uplisting-frontend/models/automated-review-template';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';

interface IArgs {
  changeset: GenericChangeset<AutomatedReviewTemplateModel>;
  onSave(): Promise<void>;
  onClose(): void;
}

interface AutomateCustomPropertyAttributeRowSignature {
  Element: HTMLFormElement;

  Args: IArgs;
}

const UiAutomateAutomatedReviewTemplateFormComponent =
  templateOnly<AutomateCustomPropertyAttributeRowSignature>();

export default UiAutomateAutomatedReviewTemplateFormComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Automate::AutomatedReviewTemplate::Form': typeof UiAutomateAutomatedReviewTemplateFormComponent;
  }
}
