import Model, { attr, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { isNone } from '@ember/utils';
import {
  type ISeamConnectionSchema,
  SeamConnectionProvider,
} from 'uplisting-frontend/models/schemas';
import SeamLockableModel from 'uplisting-frontend/models/seam-lockable';

export default class SeamConnectionModel
  extends Model
  implements ISeamConnectionSchema
{
  @attr('string') connectWebviewUrl!: string;
  @attr('string') provider!: SeamConnectionProvider;

  @hasMany('seam-lockable', { async: false, inverse: null })
  seamLockables!: SeamLockableModel[];

  @cached
  get providerPrettified(): string {
    switch (this.provider) {
      case SeamConnectionProvider.august:
        return 'August';
      case SeamConnectionProvider.nuki:
        return 'Nuki';
      case SeamConnectionProvider.kwikset:
        return 'Kwikset';
      case SeamConnectionProvider.schlage:
        return 'Schlage Encode';
      case SeamConnectionProvider.yale:
        return 'Yale';
      case SeamConnectionProvider.igloohome:
        return 'Igloohome';
      case SeamConnectionProvider.ttlock:
        return 'TTLock';
      case SeamConnectionProvider.smartthings:
        return 'SmartThings';
      case SeamConnectionProvider.dormakabaOracode:
        return 'Dormakaba Oracode';
      case SeamConnectionProvider.salto:
        return 'Salto';
      case SeamConnectionProvider.tedee:
        return 'Tedee';
    }
  }

  @cached
  get anyLockableHasKeypadBattery(): boolean {
    return this.seamLockables.some(
      (lockable) => !isNone(lockable.keypadBatteryLevel),
    );
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'seam-connection': SeamConnectionModel;
  }
}
