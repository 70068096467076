import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

type AlertType = 'success' | 'danger' | 'warning';

interface IArgs {
  title?: string;
  description?: string;
  type: AlertType;
}

interface InlineAlertSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    title?: [];
    description?: [];
    footer?: [];
  };
}

export default class UiHInlineAlertComponent extends Component<InlineAlertSignature> {
  @cached
  get alertClass():
    | 'inline-alert-warning'
    | 'inline-alert-danger'
    | 'inline-alert-processing'
    | 'inline-alert-success'
    | '' {
    switch (this.args.type) {
      case 'success':
        return 'inline-alert-success';
      case 'danger':
        return 'inline-alert-danger';
      case 'warning':
        return 'inline-alert-warning';
      default:
        return '';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::InlineAlert': typeof UiHInlineAlertComponent;
  }
}
