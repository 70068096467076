import Model, { attr, belongsTo } from '@ember-data/model';
import { type IBookingDotComActionSchema } from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';

export default class BookingDotComActionModel
  extends Model
  implements IBookingDotComActionSchema
{
  @attr('boolean') noShow!: boolean;
  @attr('boolean') invalidCreditCard!: boolean;

  @belongsTo('booking', { inverse: 'bookingDotComAction', async: false })
  booking!: BookingModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'booking-dot-com-action': BookingDotComActionModel;
  }
}
