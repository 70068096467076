import { type Registry as Services, inject as service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import { isValid as isValidDate } from 'date-fns/isValid';
import ActionController from 'uplisting-frontend/pods/action/controller';
import { formatDateQuery } from 'uplisting-frontend/utils';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';

interface IParams {
  from?: string;
}

export default class ActionRoute extends PermittedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/property-tag')
  propertyTagRepository!: Services['repositories/property-tag'];

  permission = 'action';

  async beforeModel(transition: Transition): Promise<void> {
    await super.beforeModel(transition);

    if (this.isFromBlankOrInvalid(transition.to?.queryParams?.['from'])) {
      transition.abort();

      await this.router.replaceWith('action', {
        queryParams: {
          from: formatDateQuery(new Date()),
        },
      });
    }

    await Promise.all([
      this.propertyRepository.findAll(),
      this.propertyTagRepository.findAll(),
    ]);
  }

  model(params: IParams) {
    const controller = this.controllerFor('action') as ActionController;

    Object.assign(controller, params);

    controller.setPropertiesConfig();

    return controller.handleLoadData(true);
  }

  private isFromBlankOrInvalid(value?: string): boolean {
    return !value || !isValidDate(new Date(value));
  }

  resetController(): void {
    this.store.unloadAll('airbnb-official-link');
    this.store.unloadAll('booking');
    this.store.unloadAll('calendar');
    this.store.unloadAll('changeover');
    this.store.unloadAll('property');
    this.store.unloadAll('property-tag');
    this.store.unloadAll('multi-unit');
  }
}
