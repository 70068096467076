import templateOnly from '@ember/component/template-only';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface IArgs {}

interface ActionTableRowEmptySignature {
  Element: HTMLTableRowElement;

  Args: IArgs;
}

const UiActionTableRowEmptyComponent =
  templateOnly<ActionTableRowEmptySignature>();

export default UiActionTableRowEmptyComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Action::Table::Row::Empty': typeof UiActionTableRowEmptyComponent;
  }
}
