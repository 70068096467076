import { cached } from '@glimmer/tracking';
import sortBy from 'lodash-es/sortBy';
import UiBookingPriceTablesIndexComponent from 'uplisting-frontend/pods/components/ui/booking/price/tables';
import BalanceAdjustmentModel from 'uplisting-frontend/models/balance-adjustment';
import HistoryBookingPriceModel from 'uplisting-frontend/models/history-booking-price';

export default class UiBookingPriceTablesBalanceComponent extends UiBookingPriceTablesIndexComponent {
  @cached
  get balanceItems(): (BalanceAdjustmentModel | HistoryBookingPriceModel)[] {
    const { booking } = this.price;

    const existingBalanceAdjustments =
      booking.balanceAdjustments.filter((item) => item.id) ?? [];

    const existingPriceChanges =
      booking.priceChanges.filter((item) => item.id) ?? [];

    return sortBy(
      [...existingBalanceAdjustments, ...existingPriceChanges],
      'createdAt',
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Price::Tables::Balance': typeof UiBookingPriceTablesBalanceComponent;
  }
}
