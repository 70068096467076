import Model, { attr, belongsTo } from '@ember-data/model';
import { type IGuestIdentityVerificationActionSchema } from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';

export default class GuestIdentityVerificationActionModel
  extends Model
  implements IGuestIdentityVerificationActionSchema
{
  @attr('boolean') overridden!: boolean;

  @belongsTo('booking', {
    async: false,
    inverse: 'guestIdentityVerificationAction',
  })
  booking!: BookingModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'guest-identity-verification-action': GuestIdentityVerificationActionModel;
  }
}
