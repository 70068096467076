import Model from '@ember-data/model';
import { type IInboxMessageSchema } from 'uplisting-frontend/models/schemas';

export default class InboxMessageModel
  extends Model
  implements IInboxMessageSchema {}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'inbox-message': InboxMessageModel;
  }
}
