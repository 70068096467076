import Model, { attr } from '@ember-data/model';
import { type IAirbnbOfficialListingSchema } from 'uplisting-frontend/models/schemas';

export default class AirbnbOfficialListingModel
  extends Model
  implements IAirbnbOfficialListingSchema
{
  @attr('string') externalId!: string;
  @attr('string') name!: string;
  @attr('string') nickname!: string;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'airbnb-official-listing': AirbnbOfficialListingModel;
  }
}
