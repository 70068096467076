import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import BaseController from 'uplisting-frontend/pods/base/controller';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import AutoresponderModel from 'uplisting-frontend/models/autoresponder';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { isSaveDisabled } from 'uplisting-frontend/utils';

interface IErr {
  code?: string;
}

export default class AutomateEnquiriesController extends BaseController {
  @service analytics!: Services['analytics'];
  @service('repositories/autoresponder')
  autoresponderRepository!: Services['repositories/autoresponder'];

  tags = [
    'guest_name',
    'host_first_name',
    'host_surname',
    'listing_name',
    'listing_address',
    'listing_location',
    'length_of_stay',
    'check_in_time',
    'check_in_date',
    'check_out_time',
    'check_out_date',
  ];

  @cached @tracked isSaving = false;
  @cached @tracked autoresponder!: AutoresponderModel;

  @cached
  get changeset(): GenericChangeset<AutoresponderModel> {
    return this.autoresponderRepository.buildChangeset(this.autoresponder);
  }

  @action
  handleCancel(): void {
    this.changeset.rollback();
  }

  @action
  async handleChangeAutoresponderEnabled() {
    this.changeset.enabled = !this.changeset.enabled;

    await this.handleSave();

    const eventName = this.changeset.enabled
      ? AnalyticsEvents.automatedResponderTurnedOn
      : AnalyticsEvents.automatedResponderTurnedOff;

    this.analytics.trackEvent(eventName);
  }

  @action
  async handleSave(): Promise<void> {
    const saveDisabled = await isSaveDisabled(this.changeset);

    if (saveDisabled) {
      return;
    }

    this.isSaving = true;

    try {
      await this.changeset.save();

      this.notifySaved();
    } catch (e) {
      if ((e as IErr).code) {
        this.notifications.error();
      } else {
        this.notifySaved();
      }
    } finally {
      this.isSaving = false;
    }
  }

  private notifySaved() {
    this.notifications.info();
    this.analytics.trackEvent(AnalyticsEvents.automatedResponderEdit);
  }
}
