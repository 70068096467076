import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';

export async function isSaveDisabled<T>(
  changeset?: GenericChangeset<T>,
): Promise<boolean> {
  if (!changeset) {
    return true;
  }

  // skip if no changes was made
  if (changeset.changes.length === 0) {
    return true;
  }

  await changeset.validate();

  return changeset.isInvalid;
}
