import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import BaseController from 'uplisting-frontend/pods/base/controller';
import BookingModel from 'uplisting-frontend/models/booking';
import { formatDateQuery, isSaveDisabled } from 'uplisting-frontend/utils';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';

export default class ActionBookingsPriceController extends BaseController {
  @service('repositories/booking')
  bookingRepository!: Services['repositories/booking'];

  numberOfGuestsOptions = Array.from(Array(30), (_, i) => i + 1);

  @cached @tracked booking!: BookingModel;

  @cached
  get isEditable(): boolean {
    return (
      this.booking.isEditable &&
      this.currentUser.hasAccessTo('bookings.price.edit')
    );
  }

  @cached
  get isPricePermissible(): boolean {
    return this.currentUser.hasAccessTo('bookings.price.visible');
  }

  @cached
  get changeset(): GenericChangeset<BookingModel> {
    return this.bookingRepository.buildChangeset(this.booking);
  }

  @action
  handleDatesSelect(date1: Date, date2: Date): void {
    const { changeset } = this;

    changeset.checkIn = formatDateQuery(date1);
    changeset.checkOut = formatDateQuery(date2);

    changeset.execute();
  }

  @action
  handleNumberOfGuestsChange(numberOfGuests: number): void {
    const { changeset } = this;

    changeset.price.numberOfGuests = numberOfGuests;

    changeset.execute();
  }

  @action
  async handleFormSubmit(): Promise<void> {
    const { changeset } = this;

    const saveDisabled = await isSaveDisabled(changeset);

    if (saveDisabled) {
      return;
    }

    const numberOfGuestsChanged = changeset.changes.find(
      (change) => change['key'] === 'price.numberOfGuests',
    );

    if (numberOfGuestsChanged) {
      changeset.price.hostEdited = true;
    }

    try {
      const promises: Promise<any>[] = [];

      promises.push(changeset.save());

      if (numberOfGuestsChanged) {
        promises.push(this.booking.price.save());
      }

      await Promise.all(promises);

      this.notifications.info();
    } catch {
      this.notifications.error();
    }
  }
}
