import Model, { attr } from '@ember-data/model';
import {
  type IAccountConfigurationSchema,
  SecurityDepositPer,
} from 'uplisting-frontend/models/schemas';

export default class AccountConfigurationModel
  extends Model
  implements IAccountConfigurationSchema
{
  @attr('boolean') defaultAirbnbPriceSyncEnabled!: boolean;
  @attr('number') defaultSecurityDepositAmount!: number;
  @attr('boolean') defaultSecurityDepositEnabled!: boolean;
  @attr('boolean') securityDepositsEnabledUplisting!: boolean;
  @attr('boolean') securityDepositsEnabledBookingDotCom!: boolean;
  @attr('boolean') securityDepositsEnabledGoogle!: boolean;
  @attr('boolean') securityDepositsEnabledHomeaway!: boolean;
  @attr('boolean') securityDepositsEnabledAirbnbOfficial!: boolean;
  @attr('string') securityDepositPer!: SecurityDepositPer;
  @attr('boolean') automatedReviewsEnabled!: boolean;
  @attr('number') automatedReviewsDelay!: number;
  @attr('string') displayName!: string;
  @attr('boolean') bookingDotComPaymentsEnabled!: boolean;
  @attr('number') lockCodeLength!: number;
  @attr('boolean') clientManagementEnabled!: boolean;
  @attr('boolean') clientStatementsEnabled!: boolean;
  @attr('string') timezone!: string;
  @attr('number', { defaultValue: 0 }) smartLockCheckInBuffer!: number;
  @attr('number', { defaultValue: 0 }) smartLockCheckOutBuffer!: number;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'account-configuration': AccountConfigurationModel;
  }
}
