import ApplicationInstance from '@ember/application/instance';
import config from 'ember-get-config';
import { type Registry as Services } from '@ember/service';
import {
  storage,
  StorageKey,
  type ISessionData,
} from 'uplisting-frontend/utils/storage';

export function initialize(appInstance: ApplicationInstance): void {
  if (config.IS_TEST) {
    return;
  }

  const data = storage.local.get<ISessionData>(StorageKey.SESSION);

  const token = (data as ISessionData)?.authenticated?.idToken;

  const session = appInstance.lookup('service:session') as Services['session'];

  if (!token) {
    session.handleLogout();
  }
}

export default {
  initialize,
};
