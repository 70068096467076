import BookingPaymentModel from 'uplisting-frontend/models/booking-payment';

export enum PaymentStatus {
  authorized = 'authorized',
  pending = 'pending',
  charged = 'charged',
  markedAsPaid = 'marked_as_paid',
  failing = 'failing',
  failed = 'failed',
  refunded = 'refunded',
}

export interface IPaymentSchema {
  dueAt: Date;
  currency: string;
  amount: number;
  status: PaymentStatus;
  lastErrorMessage: string;

  bookingPayment: BookingPaymentModel;
}
