import Model, { attr, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import PropertyModel from 'uplisting-frontend/models/property';
import { type IPromotionSchema } from 'uplisting-frontend/models/schemas';

export enum PromotionType {
  percentage = 'percentage',
  fixed = 'fixed',
}

export default class PromotionModel extends Model implements IPromotionSchema {
  @attr('string') name!: string;
  @attr('string') code!: string;
  @attr('boolean', { defaultValue: true }) enabled!: boolean;
  @attr('number', { defaultValue: 0 }) flatAmount!: number;
  @attr('number', { defaultValue: 0 }) percentageAmount!: number;

  @hasMany('property', { async: false, inverse: null })
  properties!: PropertyModel[];

  private _promotionType!: PromotionType;

  @cached
  get isPersisted(): boolean {
    return !!this.id;
  }

  @cached
  get order(): number {
    if (!this.isPersisted) {
      return 0;
    }

    const id = Number(this.id);

    if (!this.enabled) {
      return id;
    }

    return 1 - 1 / id;
  }

  @cached
  get promotionType(): PromotionType {
    if (!this._promotionType) {
      if (this.isPersisted) {
        this._promotionType =
          this.flatAmount === 0
            ? PromotionType.percentage
            : PromotionType.fixed;
      } else {
        this._promotionType = PromotionType.percentage;
      }
    }

    return this._promotionType;
  }

  set promotionType(type: PromotionType) {
    this._promotionType = type;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    promotion: PromotionModel;
  }
}
