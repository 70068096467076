import { helper } from '@ember/component/helper';
import UpsellModel from 'uplisting-frontend/models/upsell';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { TaxRateType } from 'uplisting-frontend/models/schemas';

function getPriceWithTax([item]: [
  GenericChangeset<UpsellModel> | UpsellModel,
]): number {
  const price = Number(item.price);
  const { tax } = item;

  if (!tax) {
    return price;
  }

  const { rate, rateType } = tax;

  if (rateType === TaxRateType.exclusive) {
    const tax = (price * rate) / 100;

    return price + tax;
  }

  return price;
}

export default helper(getPriceWithTax);
