import Model, { attr, belongsTo } from '@ember-data/model';
import {
  type ISecurityDepositSchema,
  SecurityDepositStatus,
} from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';

export default class SecurityDepositModel
  extends Model
  implements ISecurityDepositSchema
{
  @attr('number') amount!: number;
  @attr('string') status!: SecurityDepositStatus;
  @attr('number') remindersSent!: number;
  @attr('string') stripeError!: string;

  @belongsTo('booking', {
    inverse: 'securityDeposit',
    async: false,
  })
  booking!: BookingModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'security-deposit': SecurityDepositModel;
  }
}
