import { hash } from 'rsvp';
import { type Registry as Services, inject as service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import OperationsManagementFeesController from 'uplisting-frontend/pods/automate/operations/management-fees/controller';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';

export default class OperationsManagementFeesRoute extends PermittedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/property-management-fee-setting')
  propertyManagementFeeSettingRepository!: Services['repositories/property-management-fee-setting'];

  permission = 'automate.operations.management_fees';

  model() {
    return hash({
      properties: this.propertyRepository.findAll(),
      managementFees: this.propertyManagementFeeSettingRepository.findAll(),
    });
  }

  setupController(
    controller: OperationsManagementFeesController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { managementFees: model.managementFees.slice() });
  }

  resetController(): void {
    this.propertyManagementFeeSettingRepository.unloadAll();
  }
}
