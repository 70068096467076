import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import { endOfMonth } from 'date-fns/endOfMonth';
import { isAfter } from 'date-fns/isAfter';
import { type IItemConfig } from 'uplisting-frontend/pods/components/ui/picker/month/component';

interface IArgs {
  month: number;
  config: IItemConfig;
}

interface PickerMonthItemSignature {
  Element: HTMLSpanElement;

  Args: IArgs;
}

const endOfCurrentMonth = endOfMonth(new Date());

export default class UiPickerMonthItemComponent extends Component<PickerMonthItemSignature> {
  @cached
  get month(): number {
    return this.args.month;
  }

  @cached
  get year(): number {
    return this.args.config.year;
  }

  @cached
  get allowFuture(): boolean {
    return this.args.config.allowFuture;
  }

  @cached
  get date(): Date {
    return this.args.config.date;
  }

  @cached
  get isActiveMonth(): boolean {
    return this.date.getUTCMonth() === this.month;
  }

  @cached
  get isActiveMonthAndYear(): boolean {
    return this.isActiveMonth && this.date.getUTCFullYear() === this.year;
  }

  @cached
  get isInFuture(): boolean {
    return isAfter(new Date(this.year, this.month), endOfCurrentMonth);
  }

  @cached
  get isDisabled(): boolean {
    return !this.allowFuture && this.isInFuture;
  }

  @cached
  get itemClass(): string | undefined {
    if (this.isActiveMonthAndYear) {
      return 'active';
    } else if (this.isDisabled) {
      return 'disabled';
    } else if (this.isActiveMonth) {
      return 'focused';
    }
  }

  @action
  handleClick(): void {
    if (this.isDisabled) {
      return;
    }

    this.args.config.onClick(this.month);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Picker::Month::Item': typeof UiPickerMonthItemComponent;
  }
}
