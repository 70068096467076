import { action } from '@ember/object';
import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import AccountConfigurationModel from 'uplisting-frontend/models/account-configuration';
import BaseController from 'uplisting-frontend/pods/base/controller';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import AutomatedReviewTemplateModel from 'uplisting-frontend/models/automated-review-template';

interface IDelayOption {
  value: number;
  name: string;
}

const HOURS_IN_DAY = 24;

export default class AutomateReviewsController extends BaseController {
  @service analytics!: Services['analytics'];

  @service('repositories/automated-review-template')
  automatedReviewTemplateRepository!: Services['repositories/automated-review-template'];

  @cached @tracked automatedReviewTemplates: AutomatedReviewTemplateModel[] =
    [];

  @cached
  get delayOptions(): IDelayOption[] {
    const { intl } = this;

    const days = Array.from({ length: 13 }, (_i, index) => {
      const day = index + 1;

      return {
        name: intl.t('time_formatted.days', { count: day }),
        value: day * HOURS_IN_DAY,
      };
    });

    return [
      {
        name: intl.t('time_formatted.hours', { count: 6 }),
        value: 6,
      },
      {
        name: intl.t('time_formatted.hours', { count: 12 }),
        value: 12,
      },

      ...days,
    ];
  }

  @cached
  get selectedDelayOption(): IDelayOption | undefined {
    const { automatedReviewsDelay } = this.currentAccountConfiguration;

    return this.delayOptions.find(
      (option) => option.value === automatedReviewsDelay,
    );
  }

  @action
  async handleDelayOptionChange(delay: IDelayOption): Promise<void> {
    const { currentAccountConfiguration } = this;

    currentAccountConfiguration.automatedReviewsDelay = delay.value;

    await this.handleSave(currentAccountConfiguration);
  }

  @action
  async handleToggleConfigurationEnabled(): Promise<void> {
    const { currentAccountConfiguration } = this;

    currentAccountConfiguration.automatedReviewsEnabled =
      !currentAccountConfiguration.automatedReviewsEnabled;

    await this.handleSave(currentAccountConfiguration);

    if (currentAccountConfiguration.automatedReviewsEnabled) {
      const templates = await this.automatedReviewTemplateRepository.findAll({
        reload: true,
      });

      this.automatedReviewTemplates = templates.filter(
        (template) => !template.isSaving,
      );
    }

    this.trackReviewsEnabledEvent(currentAccountConfiguration);
  }

  @action
  async onReloadAccountConfiguration(): Promise<void> {
    const wasEnabledPreviously =
      this.currentAccountConfiguration.automatedReviewsEnabled;

    const reloadedConfiguration =
      await this.currentAccountConfiguration.reload();

    const isEnabledNow = reloadedConfiguration.automatedReviewsEnabled;

    if (wasEnabledPreviously === isEnabledNow) {
      return;
    }

    this.trackReviewsEnabledEvent(reloadedConfiguration);
  }

  private trackReviewsEnabledEvent(
    configuration: AccountConfigurationModel,
  ): void {
    const event = configuration.automatedReviewsEnabled
      ? AnalyticsEvents.automatedReviewsActivated
      : AnalyticsEvents.automatedReviewsDisabled;

    this.analytics.trackEvent(event);
  }

  private async handleSave(
    configuration: AccountConfigurationModel,
  ): Promise<void> {
    try {
      await configuration.save();

      this.notifications.info('automate_reviews.notifications.setting_saved');
    } catch {
      this.notifications.error();
    }
  }
}
