import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { GuestIdentityVerificationStatus } from 'uplisting-frontend/models/schemas';

interface IArgs {
  status: GuestIdentityVerificationStatus;
}

export interface BookingStatusGuestIdentityVerificationSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiBookingStatusGuestIdentityVerificationComponent extends Component<BookingStatusGuestIdentityVerificationSignature> {
  @cached
  get status(): GuestIdentityVerificationStatus {
    return this.args.status;
  }

  @cached
  get alertClass():
    | 'inline-alert-danger'
    | 'inline-alert-processing'
    | 'inline-alert-success' {
    switch (this.status) {
      case GuestIdentityVerificationStatus.requiresInput:
      case GuestIdentityVerificationStatus.requiresAction:
        return 'inline-alert-danger';
      case GuestIdentityVerificationStatus.processing:
      case GuestIdentityVerificationStatus.overridden:
        return 'inline-alert-processing';
      case GuestIdentityVerificationStatus.verified:
      case GuestIdentityVerificationStatus.succeeded:
        return 'inline-alert-success';
    }
  }

  @cached
  get icon(): 'circle-xmark' | 'circle-check' | 'clock' | undefined {
    switch (this.status) {
      case GuestIdentityVerificationStatus.requiresInput:
      case GuestIdentityVerificationStatus.requiresAction:
        return 'circle-xmark';
      case GuestIdentityVerificationStatus.processing:
      case GuestIdentityVerificationStatus.overridden:
        return 'clock';
      case GuestIdentityVerificationStatus.verified:
      case GuestIdentityVerificationStatus.succeeded:
        return 'circle-check';
    }
  }

  @cached
  get iconPrefix(): 'far' | undefined {
    if (this.icon === 'clock') {
      return 'far';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Status::GuestIdentityVerification': typeof UiBookingStatusGuestIdentityVerificationComponent;
  }
}
