import Model, { attr, hasMany } from '@ember-data/model';
import { type IEdgestateConnectionSchema } from 'uplisting-frontend/models/schemas';
import EdgestateLockableModel from 'uplisting-frontend/models/edgestate-lockable';

export default class EdgestateConnectionModel
  extends Model
  implements IEdgestateConnectionSchema
{
  @attr('string') name!: string;
  @attr('string') code!: string;
  @attr('string') edgestateAccountId!: string;

  @hasMany('edgestate-lockable', { async: false, inverse: null })
  edgestateLockables!: EdgestateLockableModel[];
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'edgestate-connection': EdgestateConnectionModel;
  }
}
