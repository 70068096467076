import { cached } from '@glimmer/tracking';
import BaseController from 'uplisting-frontend/pods/base/controller';

export default class AccountLapsedController extends BaseController {
  @cached
  get isAccountOwner(): boolean {
    return this.currentUser.isAccountOwner;
  }

  @cached
  get titleKey(): string {
    return `route_names.lapsed.${this.isAccountOwner ? 'account_owner' : 'other'}`;
  }
}
