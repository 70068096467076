import { type Registry as Services, inject as service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import ClientModel from 'uplisting-frontend/models/client';
import BaseController from 'uplisting-frontend/pods/base/controller';

export default class ClientsClientProfileController extends BaseController {
  @service('repositories/tax') taxRepository!: Services['repositories/tax'];
  @service('repositories/client')
  clientRepository!: Services['repositories/client'];

  @cached @tracked client!: ClientModel;

  @cached
  get isActionAllowed(): boolean {
    return this.currentUser.hasAccessTo('clients.edit');
  }

  @cached
  get isActionForbidden(): boolean {
    return !this.isActionAllowed;
  }

  @cached
  get changeset() {
    return this.clientRepository.buildChangeset(this.client);
  }

  @action
  async handleFormSubmit(): Promise<void> {
    if (this.isActionForbidden) {
      return;
    }

    await this.changeset.validate();

    if (this.changeset.isInvalid) {
      this.resetState();

      return;
    }

    try {
      await this.changeset.save();

      this.notifications.info();
      this.resetState();
    } catch {
      this.notifications.error();
    }
  }

  private resetState(): void {
    this.changeset.rollback();
    this.client.rollbackAttributes();
  }
}
