import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import ClientPropertyModel from 'uplisting-frontend/models/client-property';

export default class ClientPropertyRepositoryService extends BaseRepositoryService<ClientPropertyModel> {
  recordName = 'client-property';
  implementMethods = ['findAll', 'unloadAll', 'peekAll'];
}

declare module '@ember/service' {
  interface Registry {
    'repositories/client-property': ClientPropertyRepositoryService;
  }
}
