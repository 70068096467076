import Model, { hasMany } from '@ember-data/model';
import { type IBulkSecurityDepositSettingSchema } from 'uplisting-frontend/models/schemas';
import SecurityDepositSettingModel from 'uplisting-frontend/models/security-deposit-setting';

export default class BulkSecurityDepositSettingModel
  extends Model
  implements IBulkSecurityDepositSettingSchema
{
  @hasMany('security-deposit-setting', { inverse: null, async: false })
  securityDepositSettings!: SecurityDepositSettingModel[];
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'bulk-security-deposit-setting': BulkSecurityDepositSettingModel;
  }
}
