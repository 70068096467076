import type Transition from '@ember/routing/transition';
import BaseRoute from 'uplisting-frontend/pods/base/route';
import ConnectCallbackStripeController from 'uplisting-frontend/pods/connect/callback/stripe/controller';

export default class ConnectCallbackStripeRoute extends BaseRoute {
  async setupController(
    controller: ConnectCallbackStripeController,
    model,
    transition: Transition,
  ): Promise<void> {
    super.setupController(controller, model, transition);

    await controller.handleCallbackResponse();
  }
}
