import Model, { attr } from '@ember-data/model';
import { type ITotalRevenueMetricSchema } from 'uplisting-frontend/models/schemas';

export default class TotalRevenueMetricModel
  extends Model
  implements ITotalRevenueMetricSchema
{
  @attr('number') amount!: number;
  @attr('number') booked!: number;
  @attr('string') currency!: string;
  @attr('date') createdAt!: Date;
  @attr('date') month!: Date;
  @attr('date') updatedAt!: Date;
  @attr('number') netAmount!: number;
  @attr('number') ownerNetAmount!: number;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'total-revenue-metric': TotalRevenueMetricModel;
  }
}
