import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { isNone } from '@ember/utils';
import {
  type IPropertySchema,
  DirectBookingTypes,
  AirbnbOfficialLinkStatus,
} from 'uplisting-frontend/models/schemas';
import ManagementFeeRuleModel from 'uplisting-frontend/models/management-fee-rule';
import PropertyTagModel from 'uplisting-frontend/models/property-tag';
import MultiUnitModel from 'uplisting-frontend/models/multi-unit';
import UpsellModel from 'uplisting-frontend/models/upsell';
import StripeConnectionModel from 'uplisting-frontend/models/stripe-connection';
import PropertyManagementFeeSettingModel from 'uplisting-frontend/models/property-management-fee-setting';
import ChangeoverSettingModel from 'uplisting-frontend/models/changeover-setting';
import AirbnbOfficialLinkModel from 'uplisting-frontend/models/airbnb-official-link';
import AddressModel from 'uplisting-frontend/models/address';

export default class PropertyModel extends Model implements IPropertySchema {
  @attr('string') nickname!: string;
  @attr('string') currency!: string;
  @attr('string') timeZone!: string;
  @attr('string') directBookingType!: DirectBookingTypes;
  @attr('boolean', { defaultValue: true }) showOnDirectBookingEngine!: boolean;
  @attr('number') checkOutTime!: number;
  @attr('number') checkInTime!: number;
  @attr('number') order!: number;
  @attr('number') bedrooms!: number;
  @attr('number') bathrooms!: number;
  @attr('number') beds!: number;

  @belongsTo('management-fee-rule', {
    inverse: 'properties',
    async: false,
  })
  managementFeeRule!: ManagementFeeRuleModel;

  @belongsTo('stripe-connection', {
    inverse: 'properties',
    async: false,
  })
  stripeConnection!: StripeConnectionModel;

  @belongsTo('property-management-fee-setting', {
    inverse: 'property',
    async: false,
  })
  propertyManagementFeeSetting!: PropertyManagementFeeSettingModel;

  @belongsTo('changeover-setting', { inverse: 'property', async: false })
  changeoverSetting!: ChangeoverSettingModel;

  @belongsTo('airbnb-official-link', { inverse: 'property', async: false })
  airbnbOfficialLink?: AirbnbOfficialLinkModel;

  @belongsTo('address', { inverse: 'property', async: false })
  address!: AddressModel;

  @hasMany('upsell', {
    inverse: 'properties',
    async: true,
  })
  upsells!: UpsellModel[];

  @hasMany('property-tag', {
    inverse: 'properties',
    async: false,
  })
  tags!: PropertyTagModel[];

  @hasMany('multi-unit', {
    inverse: 'property',
    async: false,
  })
  multiUnits!: MultiUnitModel[];

  @cached
  get searchName(): string {
    return this.nickname;
  }

  @cached
  get hasFeeRule(): boolean {
    return !!this.managementFeeRule?.id;
  }

  @cached
  get forLockable(): string {
    return this.nickname;
  }

  @cached
  get instantBookable(): boolean {
    return this.directBookingType === DirectBookingTypes.instantBook;
  }

  @cached
  get hasConfirmedAirbnbLink(): boolean {
    return (
      this.airbnbOfficialLink?.status === AirbnbOfficialLinkStatus.confirmed
    );
  }

  @cached
  get hasAccommodationInfo(): boolean {
    return ['bedrooms', 'beds', 'bathrooms'].every((key) => !isNone(this[key]));
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    property: PropertyModel;
  }
}
