import type Transition from '@ember/routing/transition';
import BaseRoute from 'uplisting-frontend/pods/base/route';
import ClientsClientUsersController from 'uplisting-frontend/pods/clients/client/users/controller';

export default class ClientsClientUsersRoute extends BaseRoute {
  model() {
    return this.modelFor('clients.client');
  }

  setupController(
    controller: ClientsClientUsersController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    controller.buildChangeset();
  }

  resetController(controller: ClientsClientUsersController) {
    controller.invitationToRevoke = undefined;
    controller.userToUnlink = undefined;

    controller.showInviteUserModal = false;
    controller.showRevokeInvitationModal = false;
    controller.showUnlinkUserModal = false;
  }
}
