import DS from 'ember-data';
import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import ZapierApiKeyModel from 'uplisting-frontend/models/zapier-api-key';

export default class ZapierApiKeyRepositoryService extends BaseRepositoryService<ZapierApiKeyModel> {
  recordName = 'zapier-api-key';
  implementMethods = ['findAll', 'createRecord'];

  public async getDefaultRecord(): Promise<
    DS.PromiseObject<ZapierApiKeyModel>
  > {
    const data = await this.findAll();

    return data[0] || this.createRecord();
  }
}

declare module '@ember/service' {
  interface Registry {
    'repositories/zapier-api-key': ZapierApiKeyRepositoryService;
  }
}
