import Model, { attr } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { type IAirbnbOfficialOpportunityValueConstraintSchema } from 'uplisting-frontend/models/schemas';

export default class AirbnbOfficialOpportunityValueConstraintModel
  extends Model
  implements IAirbnbOfficialOpportunityValueConstraintSchema
{
  @attr('number') maxValue!: number | null;
  @attr('number') minValue!: number | null;
  @attr('array') options!: string[];
  @attr('string') valueType!: 'PERCENTAGE' | 'INTEGER' | 'OPTION';

  @cached
  get isNumberType(): boolean {
    const { valueType } = this;

    return valueType === 'PERCENTAGE' || valueType === 'INTEGER';
  }

  @cached
  get isOptionType(): boolean {
    return this.valueType === 'OPTION';
  }

  @cached
  get defaultValue(): number | string {
    if (this.isNumberType) {
      return this.minValue as number;
    } else if (this.isOptionType) {
      return this.options[0] as string;
    }

    return '';
  }

  public isValidValue(value: number | string): boolean {
    if (this.isNumberType) {
      return this.isValidNumber(value as number);
    }

    if (this.isOptionType) {
      return this.isValidOption(value as string);
    }

    return false;
  }

  private isValidNumber(number: number): boolean {
    if (!this.minValue || !this.maxValue) {
      return false;
    }

    return number >= this.minValue && number <= this.maxValue;
  }

  private isValidOption(value: string): boolean {
    return this.options.includes(value);
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'airbnb-official-opportunity-value-constraint': AirbnbOfficialOpportunityValueConstraintModel;
  }
}
