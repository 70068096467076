import Model, { hasMany } from '@ember-data/model';
import { type IChannelNotificationSchema } from 'uplisting-frontend/models/schemas';
import AirbnbOfficialNotificationModel from 'uplisting-frontend/models/airbnb-official-notification';
import AirbnbOfficialOpportunityModel from 'uplisting-frontend/models/airbnb-official-opportunity';

export default class ChannelNotificationModel
  extends Model
  implements IChannelNotificationSchema
{
  @hasMany('airbnb-official-notification', {
    inverse: 'channelNotification',
    async: false,
  })
  airbnbOfficialNotifications!: AirbnbOfficialNotificationModel[];

  @hasMany('airbnb-official-opportunity', {
    inverse: 'channelNotification',
    async: false,
  })
  airbnbOfficialOpportunities!: AirbnbOfficialOpportunityModel[];
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'channel-notification': ChannelNotificationModel;
  }
}
