import Model, { attr, belongsTo } from '@ember-data/model';
import BookingModel from 'uplisting-frontend/models/booking';
import { type IBookingManagementFeeRuleSchema } from 'uplisting-frontend/models/schemas';

export default class BookingManagementFeeRuleModel
  extends Model
  implements IBookingManagementFeeRuleSchema
{
  @attr('string') name!: string;
  @attr('string', { defaultValue: '' }) formula!: string;

  @belongsTo('booking', { async: false, inverse: null })
  booking!: BookingModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'booking-management-fee-rule': BookingManagementFeeRuleModel;
  }
}
