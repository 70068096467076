import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import sum from 'lodash-es/sum';
import {
  type IGuestInvoiceSchema,
  GuestInvoiceStatus,
} from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';
import AdditionalBookingChargeModel from 'uplisting-frontend/models/additional-booking-charge';

export default class GuestInvoiceModel
  extends Model
  implements IGuestInvoiceSchema
{
  @attr('string') status!: GuestInvoiceStatus;
  @attr('string') externalId!: string;

  @belongsTo('booking', { async: false, inverse: null })
  booking!: BookingModel;

  @hasMany('additional-booking-charge', { async: false, inverse: null })
  additionalBookingCharges!: AdditionalBookingChargeModel[];

  @cached
  get displayStatusKey(): string {
    switch (this.status) {
      case GuestInvoiceStatus.open:
      case GuestInvoiceStatus.paymentError:
      case GuestInvoiceStatus.paymentMethodMissing:
        return 'action_bookings_price.guest_invoice_status.open';
      case GuestInvoiceStatus.paid:
      case GuestInvoiceStatus.markedAsPaid:
        return 'action_bookings_price.guest_invoice_status.paid';
      default:
        return 'action_bookings_price.guest_invoice_status.void';
    }
  }

  @cached
  get amount(): number {
    return sum(this.additionalBookingCharges.map((item) => item.amountWithTax));
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'guest-invoice': GuestInvoiceModel;
  }
}
