import { action } from '@ember/object';
import BaseController from 'uplisting-frontend/pods/base/controller';

export default class AutomatePaymentsController extends BaseController {
  @action
  async handleToggleBookingDotComPaymentsEnabled(): Promise<void> {
    const { currentAccountConfiguration: configuration } = this;

    configuration.bookingDotComPaymentsEnabled =
      !configuration.bookingDotComPaymentsEnabled;

    try {
      await configuration.save();

      this.notifications.info('automate_payments.notifications.saved');
    } catch {
      this.notifications.error();
    }
  }
}
