import { validateNumber } from 'ember-changeset-validations/validators';
import { DEFAULT_BOOKING_WINDOW_DAYS } from 'uplisting-frontend/models/booking-window-rule';

export const BookingWindowRuleValidation = {
  windowDays: [
    validateNumber({
      integer: true,
      gte: 0,
      lte: DEFAULT_BOOKING_WINDOW_DAYS * 2,
      allowBlank: true,
    }),
  ],
};
