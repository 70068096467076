import BookingModel from 'uplisting-frontend/models/booking';

export enum SecurityDepositStatus {
  authorized = 'authorized',
  collected = 'collected',
  overridden = 'overridden',
  failed = 'failed',
  failing = 'failing',
  needsPaymentInformation = 'needs_payment_information',
  hasPaymentInformation = 'has_payment_information',
}

export interface ISecurityDepositSchema {
  amount: number;
  status: SecurityDepositStatus;
  remindersSent: number;
  stripeError: string;

  booking: BookingModel;
}
