import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface IArgs {}

export interface BookingPriceAdditionalChargeSignature {
  Element: HTMLTableCellElement;

  Args: IArgs;
}

export default class UiBookingPriceAdditionalChargeComponent extends Component<BookingPriceAdditionalChargeSignature> {
  @cached @tracked showCreateModal = false;

  @action
  handleShowCreateModal(): void {
    this.showCreateModal = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Price::AdditionalCharge': typeof UiBookingPriceAdditionalChargeComponent;
  }
}
