import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import DataModel from '@ember-data/model';
import { type OccasionStoreFilter } from 'uplisting-frontend/utils/occasion-filters';
import { searchOptions } from 'uplisting-frontend/utils';

interface IArgs {
  filter: OccasionStoreFilter;
}

interface ReportFiltersOptionsSignature {
  Element: HTMLUListElement;

  Args: IArgs;
}

export default class UiReportFiltersOptionsStoreMultiSelectComponent extends Component<ReportFiltersOptionsSignature> {
  @cached @tracked inputValue = '';

  @cached
  get filter(): OccasionStoreFilter {
    return this.args.filter;
  }

  @cached
  get isSearchNeeded(): boolean {
    return this.filter.optionsList.length >= 10;
  }

  @cached
  get filteredOptions(): DataModel[] {
    return searchOptions<DataModel>(
      this.filter.optionsList,
      this.inputValue,
      this.filter.modelField as string,
    );
  }

  @action
  handleInputSearch(value: string): void {
    this.inputValue = value;
  }

  @action
  handleOptionSelect(option: DataModel): void {
    // @ts-expect-error - need to improve TS here, so that it would know which exact type to expect here
    this.filter.updateValues(option);
  }

  @action
  computeIsOptionSelected(option: DataModel): boolean {
    return this.filter.values.includes(option.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::ReportFilters::Options::StoreMultiSelect': typeof UiReportFiltersOptionsStoreMultiSelectComponent;
  }
}
