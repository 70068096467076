import Model, { attr, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { isNone } from '@ember/utils';
import { type IAirbnbOfficialConnectionSchema } from 'uplisting-frontend/models/schemas';
import PropertyModel from 'uplisting-frontend/models/property';
import AirbnbOfficialConnectionListingModel from 'uplisting-frontend/models/airbnb-official-connection-listing';

export default class AirbnbOfficialConnectionModel
  extends Model
  implements IAirbnbOfficialConnectionSchema
{
  @attr('number') airbnbUserId!: number;
  @attr('string') fullName!: string;
  @attr('string') pictureUrl!: string;
  @attr('string') email!: string;
  @attr('string') code!: string;
  @attr('string') status!: string;
  @attr('string') accessLevel!: string;
  @attr('number') propertiesCount!: number;
  @attr('number') processedPropertiesCount!: number;
  @attr('boolean') apiAccess!: boolean;
  @attr('array') selectedListingIds!: string[];

  @hasMany('property', { inverse: null, async: false })
  properties!: PropertyModel[];

  @hasMany('airbnb-official-connection-listing', {
    inverse: null,
    async: false,
  })
  allListings!: AirbnbOfficialConnectionListingModel[];

  @cached
  get isComplete(): boolean {
    return this.status === 'complete';
  }

  @cached
  get isConnected(): boolean {
    return this.status === 'connected';
  }

  @cached
  get readOnly(): boolean {
    return this.accessLevel === 'read_only';
  }

  @cached
  get propertiesCountPresent(): boolean {
    return !isNone(this.propertiesCount) && this.propertiesCount !== 0;
  }

  @cached
  get isOnboarding(): boolean {
    if (!this.isConnected) {
      return false;
    }

    return (
      this.processedPropertiesCount === 0 ||
      this.processedPropertiesCount === null ||
      this.processedPropertiesCount < this.propertiesCount
    );
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'airbnb-official-connection': AirbnbOfficialConnectionModel;
  }
}
