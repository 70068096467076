import Model, { attr, belongsTo } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { type IMultiUnitSchema } from 'uplisting-frontend/models/schemas';
import PropertyModel from 'uplisting-frontend/models/property';

export default class MultiUnitModel extends Model implements IMultiUnitSchema {
  @attr('string') name!: string;

  @belongsTo('property', { async: false, inverse: 'multiUnits' })
  property!: PropertyModel;

  @cached
  get forLockable(): string {
    return `↳ ${this.name}`;
  }

  @cached
  get searchName(): string {
    return this.name;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'multi-unit': MultiUnitModel;
  }
}
