import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import ApplicationInstance from '@ember/application/instance';
import { addDays } from 'date-fns/addDays';
import { subDays } from 'date-fns/subDays';
import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import OccasionModel, {
  SectionIds,
  TableSectionFields,
  sectionFields,
  OccasionAttribute,
} from 'uplisting-frontend/models/occasion';
import {
  OccasionType,
  BookingStatus,
  GuestIdentityVerificationStatus,
  SecurityDepositStatus,
  BookingRentalAgreementStatus,
  type IOccasionFilter,
  OccasionFilterType,
} from 'uplisting-frontend/models/schemas';
import {
  storage,
  StorageKey,
  type ITableColumnSelector,
} from 'uplisting-frontend/utils/storage';
import {
  type ISectionCount,
  type IDashboardData,
  type IDashboardRecords,
  type IDashboardBookingData,
  type IDashboardActivityData,
  type IDashboardActionData,
  type IBookingData,
  type IActivityData,
  type IActionData,
  type IOccasionSectionMeta,
  type IOccasionRequestMeta,
  DashboardDateOptions,
} from 'uplisting-frontend/utils/interfaces';

export interface IDateOption {
  id: DashboardDateOptions;
  name: string;
  dates: [Date, Date];
}

interface IFetchSections {
  bookingSection: SectionIds;
  activitySection: SectionIds;
  actionSection: SectionIds;
}

const counterQuery = {
  page: {
    size: 0,
  },
};

const bookingStatusFilter = {
  field: TableSectionFields.bookingStatus,
  type: OccasionFilterType.in,
  values: [
    BookingStatus.confirmed,
    BookingStatus.checkedIn,
    BookingStatus.checkedOut,
    BookingStatus.needsCheckIn,
    BookingStatus.needsCheckOut,
  ],
};

export default class OccasionRepositoryService extends BaseRepositoryService<OccasionModel> {
  @service intl!: Services['intl'];
  @service('occasions') occasionsService!: Services['occasions'];

  recordName = 'occasion';
  implementMethods = ['query', 'unloadRecords', 'unloadAll'];

  constructor(appInstance: ApplicationInstance) {
    super(appInstance);

    this.setupDefaultSelectedColumns();
  }

  @cached @tracked isFetchingAll = false;
  @cached @tracked isFetchingBookings = false;
  @cached @tracked isFetchingActivity = false;
  @cached @tracked isFetchingAction = false;

  @cached @tracked timezone!: string;

  @cached
  get isAnyBeingFetched(): boolean {
    return (
      this.isFetchingBookings ||
      this.isFetchingActivity ||
      this.isFetchingAction
    );
  }

  @cached
  get bookingFields(): string {
    return [
      'booking_property_nickname',
      'booking_multi_unit_name',
      'booking_guest_name',
      'booking_check_in',
      'booking_check_out',
      'booking_arrival_time',
      'booking_departure_time',
      'booking_number_of_guests',
      'booking_duration',
      'booking_channel',
      'booking_status',
      'booking_remaining_nights',
      'booking_total_payout',
      'booking_creation_date',
      'booking_cancellation_date',
      'booking_currency',
      'booking_outstanding_amount',
      'booking_payment_due_date',
      'booking_payment_status',
      'booking_security_deposit_amount',
      'booking_security_deposit_due_date',
      'booking_security_deposit_status',
      'booking_guest_identity_verification_status',
      'booking_rental_agreement_status',
    ].join(',');
  }

  @cached
  get allBookingFields(): string {
    return [
      ...this.bookingFields.split(','),
      'booking_property_id',
      'booking_property_time_zone',
      'booking_source',
      'booking_guest_email',
      'booking_guest_phone',
      'booking_note',
      'booking_confirmation_code',
      'booking_price_accommodation_total',
      'booking_price_cleaning_fee',
      'booking_price_extra_guest_charges',
      'booking_price_extra_charges',
      'booking_price_discounts',
      'booking_price_taxes',
      'booking_price_payment_processing_fee',
      'booking_price_commission',
      'booking_price_commission_tax',
      'booking_price_cancellation_fee',
      'booking_price_accommodation_management_fee',
      'booking_price_cleaning_management_fee',
      'booking_price_total_management_fee',
      'booking_price_gross_revenue',
      'booking_price_net_revenue',
      'booking_price_balance',
      'booking_price_upsells_total',
    ].join(',');
  }

  @cached
  get enquiryFields(): string {
    return [
      'enquiry_property_nickname',
      'enquiry_guest_name',
      'enquiry_check_in',
      'enquiry_check_out',
      'enquiry_number_of_guests',
      'enquiry_duration',
      'enquiry_channel',
      'enquiry_status',
      'enquiry_creation_date',
    ].join(',');
  }

  @cached
  get bookingRequestsFields(): string {
    return [
      'booking_request_property_nickname',
      'booking_request_multi_unit_name',
      'booking_request_guest_name',
      'booking_request_check_in',
      'booking_request_check_out',
      'booking_request_number_of_guests',
      'booking_request_duration',
      'booking_request_channel',
      'booking_request_status',
      'booking_request_creation_date',
    ].join(',');
  }

  @cached
  get dateOptions(): IDateOption[] {
    return [
      {
        id: DashboardDateOptions.yesterday,
        name: this.intl.t('dashboard_index.day_tabs.yesterday'),
        dates: [subDays(new Date(), 1), subDays(new Date(), 1)],
      },
      {
        id: DashboardDateOptions.today,
        name: this.intl.t('dashboard_index.day_tabs.today'),
        dates: [new Date(), new Date()],
      },
      {
        id: DashboardDateOptions.tomorrow,
        name: this.intl.t('dashboard_index.day_tabs.tomorrow'),
        dates: [addDays(new Date(), 1), addDays(new Date(), 1)],
      },
      {
        id: DashboardDateOptions.next_7_days,
        name: this.intl.t('dashboard_index.day_tabs.next_7_days'),
        dates: [new Date(), addDays(new Date(), 6)],
      },
    ];
  }

  /**
   * @description fetch occasion data for all tables & sections
   *
   * @param {String} dateFilterValue date ranges for start and end dates
   * @param {IFetchSections} sections active selected tables sections
   * @param {Boolean} fetchMeta defines wether meta data(counters) should be fetched from the BE
   *
   * @returns {Promise<IDashboardData | IDashboardRecords>} occasion data | occasion data & meta
   */
  public async fetchDashboardData(
    dateFilterValue: string,
    sections: IFetchSections,
    fetchMeta = true,
  ): Promise<IDashboardData | IDashboardRecords> {
    const promises: Promise<any>[] = [];

    this.isFetchingAll = true;

    promises.push(
      this.fetchBookings(dateFilterValue, sections.bookingSection, fetchMeta),
    );

    promises.push(
      this.fetchActions(dateFilterValue, sections.actionSection, fetchMeta),
    );

    promises.push(
      this.fetchBookingActivity(
        dateFilterValue,
        sections.activitySection,
        fetchMeta,
      ),
    );

    const data = (await Promise.all(promises)) as [
      IDashboardBookingData | IBookingData,
      IDashboardActivityData | IActivityData,
      IDashboardActionData | IActionData,
    ];

    this.isFetchingAll = false;

    return data.reduce((acc, item) => Object.assign(acc, item), {}) as
      | IDashboardData
      | IDashboardRecords;
  }

  /**
   * @description fetch records for the bookings table
   *
   * @param {String} dateFilterValue date ranges for start and end dates
   * @param {SectionIds} section active selected booking table section
   * @param {Boolean} fetchMeta defines wether meta data(counters) should be fetched from the BE
   *
   * @returns {Promise<IDashboardBookingData | IBookingData>} booking data | booking data & meta
   */
  public async fetchBookings(
    dateFilterValue: string,
    section: SectionIds,
    fetchMeta: boolean,
    sort?: string,
  ): Promise<IDashboardBookingData | IBookingData> {
    const promises: Promise<any>[] = [];

    this.isFetchingBookings = true;

    promises.push(
      this.query({
        filter: {
          occasion_type: OccasionType.booking,
          occasion_filters: [
            {
              field: sectionFields[section],
              type: 'between',
              values: [dateFilterValue],
            },
            bookingStatusFilter,
          ],
        },
        fields: {
          occasions: this.bookingFields,
        },
        sort: sort || undefined,
        time_zone: this.timezone,
      }),
    );

    if (fetchMeta) {
      promises.push(
        this.query({
          filter: {
            occasion_type: OccasionType.booking,
            occasion_filters: [
              {
                field: TableSectionFields.checkIn,
                type: 'between',
                values: [dateFilterValue],
              },
              bookingStatusFilter,
            ],
            counter_filters: [
              {
                field: 'booking_status',
                type: 'in',
                values: [BookingStatus.checkedIn],
              },
            ],
          },
          time_zone: this.timezone,
          ...counterQuery,
        }),
      );

      promises.push(
        this.query({
          filter: {
            occasion_type: OccasionType.booking,
            occasion_filters: [
              {
                field: TableSectionFields.checkOut,
                type: 'between',
                values: [dateFilterValue],
              },
              bookingStatusFilter,
            ],
            counter_filters: [
              {
                field: 'booking_status',
                type: 'in',
                values: [BookingStatus.checkedOut],
              },
            ],
          },
          time_zone: this.timezone,
          ...counterQuery,
        }),
      );

      promises.push(
        this.query({
          filter: {
            occasion_type: OccasionType.booking,
            occasion_filters: [
              {
                field: TableSectionFields.stays,
                type: 'between',
                values: [dateFilterValue],
              },
              bookingStatusFilter,
            ],
          },
          time_zone: this.timezone,
          ...counterQuery,
        }),
      );
    }

    const [data, checkedInData, checkedOutData, staysData] = (await Promise.all(
      promises,
    )) as unknown as [
      OccasionModel[],
      IOccasionRequestMeta,
      IOccasionRequestMeta,
      IOccasionRequestMeta,
    ];

    this.isFetchingBookings = false;
    const occasionBookings = data.slice();

    if (fetchMeta) {
      return {
        checkedInCount: this.formatSectionCount(checkedInData.meta),
        checkedOutCount: this.formatSectionCount(checkedOutData.meta),
        staysCount: this.formatSectionCount(staysData.meta),
        occasionBookings,
      };
    }

    return {
      occasionBookings,
    };
  }

  /**
   * @description fetch records for the activity table
   *
   * @param {String} dateFilterValue date ranges for start and end dates
   * @param {SectionIds} section active selected activity table section
   * @param {Boolean} fetchMeta defines wether meta data(counters) should be fetched from the BE
   *
   * @returns {Promise<IDashboardActivityData | IActivityData>} activity data | activity data & meta
   */
  public async fetchBookingActivity(
    dateFilterValue: string,
    section: SectionIds,
    fetchMeta: boolean,
    sort?: string,
  ): Promise<IDashboardActivityData | IActivityData> {
    const promises: Promise<any>[] = [];

    this.isFetchingActivity = true;

    const occasionType = this.getOccasionType(section);
    const occasionFields = this.getOccasionFields(section);

    const occasionFilters = this.buildOccasionFilters(section, dateFilterValue);

    promises.push(
      this.query({
        filter: {
          occasion_type: occasionType,
          occasion_filters: occasionFilters,
        },
        fields: {
          occasions: occasionFields,
        },
        sort: sort || undefined,
        time_zone: this.timezone,
      }),
    );

    if (fetchMeta) {
      promises.push(
        this.query({
          filter: {
            occasion_type: occasionType,
            occasion_filters: [
              {
                field: sectionFields[section],
                type: 'between',
                values: [dateFilterValue],
              },
              this.getStatusFilter(section),
            ],
          },
          ...counterQuery,
          time_zone: this.timezone,
        }),
      );

      promises.push(
        this.query({
          filter: {
            occasion_type: OccasionType.enquiry,
            occasion_filters: [
              {
                field: TableSectionFields.enquiries,
                type: 'between',
                values: [dateFilterValue],
              },
              {
                field: TableSectionFields.enquiryStatus,
                type: 'in',
                values: [
                  BookingStatus.pending,
                  BookingStatus.expired,
                  BookingStatus.accepted,
                  BookingStatus.denied,
                  BookingStatus.booked,
                  BookingStatus.notPossible,
                  BookingStatus.declined,
                  BookingStatus.active,
                  BookingStatus.externallyRemoved,
                ],
              },
            ],
          },
          ...counterQuery,
          time_zone: this.timezone,
        }),
      );

      promises.push(
        this.query({
          filter: {
            occasion_type: OccasionType.bookingRequest,
            occasion_filters: [
              {
                field: TableSectionFields.bookingRequests,
                type: 'between',
                values: [dateFilterValue],
              },
              {
                field: TableSectionFields.bookingRequestStatus,
                type: 'in',
                values: [
                  BookingStatus.pending,
                  BookingStatus.rejected,
                  BookingStatus.approved,
                ],
              },
            ],
          },
          ...counterQuery,
          time_zone: this.timezone,
        }),
      );

      promises.push(
        this.query({
          filter: {
            occasion_type: OccasionType.booking,
            occasion_filters: [
              {
                field: TableSectionFields.pendingBookings,
                type: 'between',
                values: [dateFilterValue],
              },
              {
                field: TableSectionFields.bookingStatus,
                type: 'in',
                values: [BookingStatus.pending],
              },
            ],
          },
          ...counterQuery,
          time_zone: this.timezone,
        }),
      );

      promises.push(
        this.query({
          filter: {
            occasion_type: OccasionType.booking,
            occasion_filters: [
              {
                field: TableSectionFields.cancelledBookings,
                type: 'between',
                values: [dateFilterValue],
              },
              {
                field: TableSectionFields.bookingStatus,
                type: 'in',
                values: [BookingStatus.cancelled],
              },
            ],
          },
          ...counterQuery,
          time_zone: this.timezone,
        }),
      );
    }

    const [
      data,
      newBookingsData,
      enquiriesData,
      bookingRequestsData,
      pendingBookingsData,
      cancelledBookingsData,
    ] = (await Promise.all(promises)) as [
      OccasionModel[],
      IOccasionRequestMeta,
      IOccasionRequestMeta,
      IOccasionRequestMeta,
      IOccasionRequestMeta,
      IOccasionRequestMeta,
    ];

    this.isFetchingActivity = false;
    const occasionActivity = data.slice();

    if (fetchMeta) {
      return {
        newBookingsCount: this.formatSectionCount(newBookingsData.meta),
        enquiriesCount: this.formatSectionCount(enquiriesData.meta),
        bookingRequestCount: this.formatSectionCount(bookingRequestsData.meta),
        pendingBookingCount: this.formatSectionCount(pendingBookingsData.meta),
        cancelledBookingsCount: this.formatSectionCount(
          cancelledBookingsData.meta,
        ),
        occasionActivity,
      };
    }

    return {
      occasionActivity,
    };
  }

  /**
   * @description fetch records for the activity table
   *
   * @param {String} dateFilterValue date ranges for start and end dates
   * @param {SectionIds} section active selected activity table section
   * @param {Boolean} fetchMeta defines wether meta data(counters) should be fetched from the BE
   *
   * @returns {Promise<IDashboardActivityData | IActivityData>} activity data | activity data & meta
   */
  public async fetchActions(
    dateFilterValue: string,
    section: SectionIds,
    fetchMeta: boolean,
    sort?: string,
  ): Promise<IDashboardActionData | IActionData> {
    const promises: Promise<any>[] = [];

    this.isFetchingAction = true;

    const occasionType = this.getOccasionType(section);
    const occasionFields = this.getOccasionFields(section);

    const occasionFilters = this.buildOccasionFilters(section, dateFilterValue);

    promises.push(
      this.query({
        filter: {
          occasion_type: occasionType,
          occasion_filters: occasionFilters,
        },
        fields: {
          occasions: occasionFields,
        },
        sort: sort || undefined,
        time_zone: this.timezone,
      }),
    );

    if (fetchMeta) {
      promises.push(
        this.query({
          filter: {
            occasion_type: OccasionType.booking,
            occasion_filters: [
              {
                field: sectionFields[section],
                type: 'between',
                values: [dateFilterValue],
              },
              {
                field: TableSectionFields.paid,
                type: 'in',
                values: ['false'],
              },
              bookingStatusFilter,
            ],
          },
          ...counterQuery,
          time_zone: this.timezone,
        }),
      );

      promises.push(
        this.query({
          filter: {
            occasion_type: OccasionType.booking,
            occasion_filters: [
              {
                field: sectionFields[section],
                type: 'between',
                values: [dateFilterValue],
              },
              {
                field: TableSectionFields.depositStatus,
                type: 'in',
                values: [
                  SecurityDepositStatus.needsPaymentInformation,
                  SecurityDepositStatus.failing,
                  SecurityDepositStatus.failed,
                ],
              },
              bookingStatusFilter,
            ],
          },
          ...counterQuery,
          time_zone: this.timezone,
        }),
      );

      promises.push(
        this.query({
          filter: {
            occasion_type: OccasionType.booking,
            occasion_filters: [
              {
                field: sectionFields[section],
                type: 'between',
                values: [dateFilterValue],
              },
              {
                field:
                  TableSectionFields.bookingGuestIdentityVerificationStatus,
                type: 'in',
                values: [
                  GuestIdentityVerificationStatus.processing,
                  GuestIdentityVerificationStatus.requiresAction,
                  GuestIdentityVerificationStatus.requiresInput,
                ],
              },
              bookingStatusFilter,
            ],
          },
          ...counterQuery,
          time_zone: this.timezone,
        }),
      );

      promises.push(
        this.query({
          filter: {
            occasion_type: OccasionType.booking,
            occasion_filters: [
              {
                field: sectionFields[section],
                type: 'between',
                values: [dateFilterValue],
              },
              {
                field: TableSectionFields.bookingRentalAgreementStatus,
                type: 'in',
                values: [BookingRentalAgreementStatus.pending],
              },
              bookingStatusFilter,
            ],
          },
          ...counterQuery,
          time_zone: this.timezone,
        }),
      );
    }

    const [
      data,
      bookingPaymentsData,
      securityDepositsData,
      guestIdentityVerificationsData,
      bookingRentalAgreementsData,
    ] = (await Promise.all(promises)) as [
      OccasionModel[],
      IOccasionRequestMeta,
      IOccasionRequestMeta,
      IOccasionRequestMeta,
      IOccasionRequestMeta,
    ];

    this.isFetchingAction = false;
    const occasionAction = data.slice();

    if (fetchMeta) {
      return {
        bookingPaymentsCount: this.formatSectionCount(bookingPaymentsData.meta),
        securityDepositsCount: this.formatSectionCount(
          securityDepositsData.meta,
        ),
        bookingGuestIdentityVerificationsCount: this.formatSectionCount(
          guestIdentityVerificationsData.meta,
        ),
        bookingRentalAgreementsCount: this.formatSectionCount(
          bookingRentalAgreementsData.meta,
        ),
        occasionAction,
      };
    }

    return {
      occasionAction,
    };
  }

  /**
   * @description format meta from the BE response into internal DTO structure
   *
   * @param {IOccasionSectionMeta} meta meta response from the BE
   *
   * @returns {ISectionCount} formatted meta
   */
  private formatSectionCount(meta: IOccasionSectionMeta): ISectionCount {
    return {
      total: meta?.total,
      count: meta?.filtered_count,
    };
  }

  get defaultCheckInData(): OccasionAttribute[] {
    return [
      OccasionAttribute.guestName,
      OccasionAttribute.propertyNickname,
      OccasionAttribute.checkIn,
      OccasionAttribute.arrivalTime,
      OccasionAttribute.numberOfGuests,
      OccasionAttribute.duration,
      OccasionAttribute.channel,
      OccasionAttribute.status,
    ];
  }

  get defaultCheckOutData(): OccasionAttribute[] {
    return [
      OccasionAttribute.guestName,
      OccasionAttribute.propertyNickname,
      OccasionAttribute.checkOut,
      OccasionAttribute.departureTime,
      OccasionAttribute.numberOfGuests,
      OccasionAttribute.duration,
      OccasionAttribute.channel,
      OccasionAttribute.status,
    ];
  }

  get defaultStaysData(): OccasionAttribute[] {
    return [
      OccasionAttribute.guestName,
      OccasionAttribute.propertyNickname,
      OccasionAttribute.checkOut,
      OccasionAttribute.numberOfGuests,
      OccasionAttribute.duration,
      OccasionAttribute.channel,
    ];
  }

  get defaultNewBookingsData(): OccasionAttribute[] {
    return [
      OccasionAttribute.guestName,
      OccasionAttribute.propertyNickname,
      OccasionAttribute.checkIn,
      OccasionAttribute.totalPayout,
      OccasionAttribute.duration,
      OccasionAttribute.numberOfGuests,
      OccasionAttribute.channel,
    ];
  }

  get defaultEnquiriesData(): OccasionAttribute[] {
    return [
      OccasionAttribute.guestName,
      OccasionAttribute.propertyNickname,
      OccasionAttribute.checkIn,
      OccasionAttribute.duration,
      OccasionAttribute.numberOfGuests,
      OccasionAttribute.status,
    ];
  }

  get defaultBookingRequestsData(): OccasionAttribute[] {
    return [
      OccasionAttribute.guestName,
      OccasionAttribute.propertyNickname,
      OccasionAttribute.checkIn,
      OccasionAttribute.totalPayout,
      OccasionAttribute.duration,
      OccasionAttribute.numberOfGuests,
      OccasionAttribute.status,
    ];
  }

  get defaultPendingBookingsData(): OccasionAttribute[] {
    return [
      OccasionAttribute.guestName,
      OccasionAttribute.propertyNickname,
      OccasionAttribute.checkIn,
      OccasionAttribute.totalPayout,
      OccasionAttribute.duration,
      OccasionAttribute.numberOfGuests,
    ];
  }

  get defaultCancelledBookingsData(): OccasionAttribute[] {
    return [
      OccasionAttribute.guestName,
      OccasionAttribute.propertyNickname,
      OccasionAttribute.checkIn,
      OccasionAttribute.totalPayout,
      OccasionAttribute.duration,
      OccasionAttribute.numberOfGuests,
      OccasionAttribute.status,
    ];
  }

  get defaultBookingPaymentsData(): OccasionAttribute[] {
    return [
      OccasionAttribute.guestName,
      OccasionAttribute.propertyNickname,
      OccasionAttribute.checkIn,
      OccasionAttribute.paymentDueDate,
      OccasionAttribute.outstandingAmount,
      OccasionAttribute.totalPayout,
      OccasionAttribute.status,
      OccasionAttribute.duration,
      OccasionAttribute.numberOfGuests,
      OccasionAttribute.channel,
    ];
  }

  get defaultSecurityDepositsData(): OccasionAttribute[] {
    return [
      OccasionAttribute.guestName,
      OccasionAttribute.propertyNickname,
      OccasionAttribute.checkIn,
      OccasionAttribute.securityDepositAmount,
      OccasionAttribute.securityDepositDueDate,
      OccasionAttribute.securityDepositStatus,
      OccasionAttribute.numberOfGuests,
    ];
  }

  get defaultBookingGuestIdentityVerificationsData(): OccasionAttribute[] {
    return [
      OccasionAttribute.guestName,
      OccasionAttribute.propertyNickname,
      OccasionAttribute.checkIn,
      OccasionAttribute.guestIdentityVerificationStatus,
      OccasionAttribute.duration,
      OccasionAttribute.numberOfGuests,
    ];
  }

  get defaultBookingRentalAgreementsData(): OccasionAttribute[] {
    return [
      OccasionAttribute.guestName,
      OccasionAttribute.propertyNickname,
      OccasionAttribute.checkIn,
      OccasionAttribute.rentalAgreementStatus,
      OccasionAttribute.duration,
      OccasionAttribute.numberOfGuests,
    ];
  }

  /**
   * @description store default section columns data, if no data is already present under provided key
   *
   * @param {StorageKey} key - storage key, under which to store data
   * @param {string[]} columns - column names to be stored under the key as default value
   */
  private setDefaultSectionData(
    key: StorageKey,
    columns: OccasionAttribute[],
  ): void {
    const data = storage.local.get<ITableColumnSelector>(key);

    if (!data) {
      storage.local.set<ITableColumnSelector>(key, {
        shownColumns: columns,
      });
    }
  }

  /**
   * @description set, store and define which are default shown columns for different tables and different sections
   */
  private setupDefaultSelectedColumns(): void {
    this.setDefaultSectionData(
      StorageKey.DASHBOARD_CHECK_IN,
      this.defaultCheckInData,
    );
    this.setDefaultSectionData(
      StorageKey.DASHBOARD_CHECK_OUT,
      this.defaultCheckOutData,
    );
    this.setDefaultSectionData(
      StorageKey.DASHBOARD_STAYS,
      this.defaultStaysData,
    );
    this.setDefaultSectionData(
      StorageKey.DASHBOARD_NEW_BOOKINGS,
      this.defaultNewBookingsData,
    );
    this.setDefaultSectionData(
      StorageKey.DASHBOARD_ENQUIRIES,
      this.defaultEnquiriesData,
    );
    this.setDefaultSectionData(
      StorageKey.DASHBOARD_BOOKING_REQUESTS,
      this.defaultBookingRequestsData,
    );
    this.setDefaultSectionData(
      StorageKey.DASHBOARD_PENDING,
      this.defaultPendingBookingsData,
    );
    this.setDefaultSectionData(
      StorageKey.DASHBOARD_CANCELLED,
      this.defaultCancelledBookingsData,
    );
    this.setDefaultSectionData(
      StorageKey.DASHBOARD_BOOKING_PAYMENT,
      this.defaultBookingPaymentsData,
    );
    this.setDefaultSectionData(
      StorageKey.DASHBOARD_SECURITY_DEPOSITS,
      this.defaultSecurityDepositsData,
    );
    this.setDefaultSectionData(
      StorageKey.DASHBOARD_BOOKING_GUEST_IDENTITY_VERIFICATION,
      this.defaultBookingGuestIdentityVerificationsData,
    );
    this.setDefaultSectionData(
      StorageKey.DASHBOARD_BOOKING_RENTAL_AGREEMENT,
      this.defaultBookingRentalAgreementsData,
    );
  }

  /**
   * @description get occasion model type, based on the section selected/passed
   *
   * @param {SectionIds} section selected/provided active table section
   *
   * @returns {OccasionType}
   */
  private getOccasionType(section: SectionIds): OccasionType {
    switch (section) {
      case SectionIds.enquiries:
        return OccasionType.enquiry;
      case SectionIds.bookingRequests:
        return OccasionType.bookingRequest;
      case SectionIds.checkIn:
      case SectionIds.checkOut:
      case SectionIds.stays:
      case SectionIds.newBookings:
      case SectionIds.pendingBookings:
      case SectionIds.cancelledBookings:
      case SectionIds.bookingPayment:
      case SectionIds.securityDeposits:
      case SectionIds.bookingGuestIdentityVerifications:
      case SectionIds.bookingRentalAgreements:
        return OccasionType.booking;
    }
  }

  /**
   * @description build occasion filters to be passed to the BE depending on the selected section
   *
   * @param {SectionIds} section selected/provided active table section
   * @param {string} dateFilterValue date ranges for start and end dates
   *
   * @returns occasion filters
   */
  private buildOccasionFilters(section: SectionIds, dateFilterValue: string) {
    const base: IOccasionFilter[] = [
      {
        field: sectionFields[section],
        type: OccasionFilterType.between,
        values: [dateFilterValue],
      },
    ];

    const sectionFilter = this.getOccasionSectionFilter(section);

    if (sectionFilter) {
      base.push(sectionFilter);
    }

    const statusFilter = this.getStatusFilter(section);

    if (statusFilter) {
      base.push(statusFilter);
    }

    return base;
  }

  private getOccasionSectionFilter(
    section: SectionIds,
  ): IOccasionFilter | undefined {
    switch (section) {
      case SectionIds.pendingBookings:
        return {
          field: TableSectionFields.bookingStatus,
          type: OccasionFilterType.in,
          values: [BookingStatus.pending],
        };

      case SectionIds.bookingPayment:
        return {
          field: TableSectionFields.paid,
          type: OccasionFilterType.in,
          values: ['false'],
        };

      case SectionIds.securityDeposits:
        return {
          field: TableSectionFields.depositStatus,
          type: OccasionFilterType.in,
          values: [
            SecurityDepositStatus.needsPaymentInformation,
            SecurityDepositStatus.failing,
            SecurityDepositStatus.failed,
          ],
        };

      case SectionIds.bookingGuestIdentityVerifications:
        return {
          field: TableSectionFields.bookingGuestIdentityVerificationStatus,
          type: OccasionFilterType.in,
          values: [
            GuestIdentityVerificationStatus.processing,
            GuestIdentityVerificationStatus.requiresAction,
            GuestIdentityVerificationStatus.requiresInput,
          ],
        };

      case SectionIds.bookingRentalAgreements:
        return {
          field: TableSectionFields.bookingRentalAgreementStatus,
          type: OccasionFilterType.in,
          values: [BookingRentalAgreementStatus.pending],
        };
    }
  }

  private getStatusFilter(section: SectionIds): IOccasionFilter | undefined {
    switch (section) {
      case SectionIds.bookingPayment:
      case SectionIds.securityDeposits:
      case SectionIds.bookingGuestIdentityVerifications:
      case SectionIds.bookingRentalAgreements:
      case SectionIds.newBookings:
        return bookingStatusFilter;

      case SectionIds.enquiries:
        return {
          field: TableSectionFields.enquiryStatus,
          type: OccasionFilterType.in,
          values: [
            BookingStatus.pending,
            BookingStatus.expired,
            BookingStatus.accepted,
            BookingStatus.denied,
            BookingStatus.booked,
            BookingStatus.notPossible,
            BookingStatus.declined,
            BookingStatus.active,
            BookingStatus.externallyRemoved,
          ],
        };

      case SectionIds.bookingRequests:
        return {
          field: TableSectionFields.bookingRequestStatus,
          type: OccasionFilterType.in,
          values: [
            BookingStatus.pending,
            BookingStatus.rejected,
            BookingStatus.approved,
          ],
        };

      case SectionIds.cancelledBookings:
        return {
          field: TableSectionFields.bookingStatus,
          type: OccasionFilterType.in,
          values: [BookingStatus.cancelled],
        };
    }
  }

  /**
   * @description get occasion model fields to request from the BE, depending on the selected section
   *
   * @param {SectionIds} section selected/provided active table section
   *
   * @returns {String} occasion fields to be requested as a string, separated by comma
   */
  private getOccasionFields(section: SectionIds): string {
    switch (section) {
      case SectionIds.enquiries:
        return this.enquiryFields;
      case SectionIds.bookingRequests:
        return this.bookingRequestsFields;
      case SectionIds.checkIn:
      case SectionIds.checkOut:
      case SectionIds.stays:
      case SectionIds.newBookings:
      case SectionIds.pendingBookings:
      case SectionIds.cancelledBookings:
      case SectionIds.bookingPayment:
      case SectionIds.securityDeposits:
      case SectionIds.bookingGuestIdentityVerifications:
      case SectionIds.bookingRentalAgreements:
        return this.bookingFields;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'repositories/occasion': OccasionRepositoryService;
  }
}
