import templateOnly from '@ember/component/template-only';

interface IArgs {
  inPlace?: boolean;
}

interface LoadingStateSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

const UiLoadingStateComponent = templateOnly<LoadingStateSignature>();

export default UiLoadingStateComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::LoadingState': typeof UiLoadingStateComponent;
  }
}
