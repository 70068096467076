import Model, { attr } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import {
  type INetRevenueRuleSchema,
  type NetRevenueRuleAdditions,
  type NetRevenueRuleDeductions,
} from 'uplisting-frontend/models/schemas';

export default class NetRevenueRuleModel
  extends Model
  implements INetRevenueRuleSchema
{
  @attr('array') additions!: NetRevenueRuleAdditions[];
  @attr('array') deductions!: NetRevenueRuleDeductions[];

  @cached
  get cleaningFee(): boolean {
    return this.additions.includes('cleaning_fee');
  }

  @cached
  get extraCharges(): boolean {
    return this.additions.includes('extra_charges_sum');
  }

  @cached
  get extraGuestCharge(): boolean {
    return this.additions.includes('extra_guest_charge');
  }

  @cached
  get cancellationFee(): boolean {
    return this.additions.includes('cancellation_payout');
  }

  @cached
  get discounts(): boolean {
    return this.deductions.includes('discounts_sum');
  }

  @cached
  get bookingTaxes(): boolean {
    return this.deductions.includes('booking_taxes_sum');
  }

  @cached
  get channelHostFeeVat(): boolean {
    return this.deductions.includes('channel_host_fee_vat');
  }

  @cached
  get channelHostFee(): boolean {
    return this.deductions.includes('channel_host_fee');
  }

  @cached
  get processingFee(): boolean {
    return this.deductions.includes('payment_processing_fee_sum');
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'net-revenue-rule': NetRevenueRuleModel;
  }
}
