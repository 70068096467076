import Model, { attr, belongsTo } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import BookingModel from 'uplisting-frontend/models/booking';
import { type IMessageSchema } from 'uplisting-frontend/models/schemas';
import { formatDate } from 'uplisting-frontend/helpers/date-format';

export default class MessageModel extends Model implements IMessageSchema {
  @attr('string') name!: string;
  @attr('string') content!: string;
  @attr('string') image?: string | null;
  @attr('string') type!: string;
  @attr('string') thumbnailUrl!: string;
  @attr('boolean') inbound!: boolean;
  @attr('date') createdAt!: Date;

  @cached
  get createdAtFormatted(): string {
    return formatDate(this.createdAt, 'MMMM do yyyy @ h:mm a');
  }

  @belongsTo('booking', { inverse: 'messages', async: false })
  booking!: BookingModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    message: MessageModel;
  }
}
