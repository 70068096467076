import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import PromotionModel from 'uplisting-frontend/models/promotion';

interface IArgs {
  promotion: PromotionModel;
}

interface DirectPromotionRowSignature {
  Element: HTMLTableRowElement;

  Args: IArgs;
}

export default class UiDirectPromotionRowComponent extends Component<DirectPromotionRowSignature> {
  nameColSize = 5;
  codeColSize = 4;
  enabledColSize = 2;
  editColSize = 2;

  formColSize =
    this.nameColSize +
    this.codeColSize +
    this.enabledColSize +
    this.editColSize;

  @cached @tracked isEditing = false;

  @action
  handleSetPromotionEditing(): void {
    this.isEditing = true;
  }

  @cached
  get promotion(): PromotionModel {
    return this.args.promotion;
  }

  @action
  handleCloseEditPromotion(): void {
    this.isEditing = false;

    if (!this.promotion.isPersisted) {
      this.promotion.unloadRecord();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Direct::Promotion::Row': typeof UiDirectPromotionRowComponent;
  }
}
