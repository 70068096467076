import { type Registry as Services, inject as service } from '@ember/service';
import { UiAutomateEditableRowComponent } from 'uplisting-frontend/pods/components/ui/automate/editable-row/component';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import PaymentRuleModel from 'uplisting-frontend/models/payment-rule';
import { type IAutomatePaymentRuleTableColSizes } from 'uplisting-frontend/pods/automate/payment-rules/controller';

export default class UiAutomatePaymentRuleRowComponent extends UiAutomateEditableRowComponent<
  PaymentRuleModel,
  IAutomatePaymentRuleTableColSizes
> {
  @service('repositories/payment-rule')
  repository!: Services['repositories/payment-rule'];

  eventOnUpdate = AnalyticsEvents.paymentRuleEdit;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Automate::PaymentRule::Row': typeof UiAutomatePaymentRuleRowComponent;
  }
}
