import ManagementFeeRuleModel from 'uplisting-frontend/models/management-fee-rule';
import PropertyTagModel from 'uplisting-frontend/models/property-tag';
import MultiUnitModel from 'uplisting-frontend/models/multi-unit';
import UpsellModel from 'uplisting-frontend/models/upsell';
import StripeConnectionModel from 'uplisting-frontend/models/stripe-connection';
import PropertyManagementFeeSettingModel from 'uplisting-frontend/models/property-management-fee-setting';
import ChangeoverSettingModel from 'uplisting-frontend/models/changeover-setting';
import AirbnbOfficialLinkModel from 'uplisting-frontend/models/airbnb-official-link';
import AddressModel from 'uplisting-frontend/models/address';

export enum DirectBookingTypes {
  enquiry = 'enquiry',
  requestToBook = 'request_to_book',
  instantBook = 'instant_book',
}

export interface IPropertySchema {
  nickname: string;
  currency: string;
  timeZone: string;
  directBookingType: DirectBookingTypes;
  showOnDirectBookingEngine: boolean;
  checkOutTime: number;
  checkInTime: number;
  order: number;
  bedrooms: number;
  bathrooms: number;
  beds: number;

  managementFeeRule: ManagementFeeRuleModel;
  stripeConnection: StripeConnectionModel;
  propertyManagementFeeSetting: PropertyManagementFeeSettingModel;
  changeoverSetting: ChangeoverSettingModel;
  airbnbOfficialLink?: AirbnbOfficialLinkModel;
  address: AddressModel;

  tags: PropertyTagModel[];
  multiUnits: MultiUnitModel[];
  upsells: UpsellModel[];
}
