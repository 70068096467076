import { type Registry as Services, inject as service } from '@ember/service';
import { hash } from 'rsvp';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import ClientStatementsIndexController from 'uplisting-frontend/pods/clients/statements/index/controller';

interface IParams {
  clientIds: string[];
  propertyIds: string[];
  period: string;
  status: string;
}

export default class ClientStatementsRoute extends PermittedRoute {
  @service features!: Services['features'];
  @service('repositories/client')
  clientRepository!: Services['repositories/client'];

  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  permission = 'client_statements.index';

  async model(params: IParams) {
    if (!this.features.isClientStatementsEnabledOnAccount) {
      return;
    }

    const controller = this.controllerFor(
      'clients/statements/index',
    ) as ClientStatementsIndexController;

    Object.assign(controller, params);

    return hash({
      clients: this.clientRepository.findAll(),
      properties: this.propertyRepository.findAll(),
      statements: controller.handleFetchStatements(),
    });
  }

  resetController(controller: ClientStatementsIndexController) {
    controller.handleResetFilters();
  }
}
