import Model, { attr, belongsTo } from '@ember-data/model';
import { type IClientStatementExpenseSchema } from 'uplisting-frontend/models/schemas';
import ClientStatementModel from 'uplisting-frontend/models/client-statement';
import TaxModel from 'uplisting-frontend/models/tax';

export default class ClientStatementExpenseModel
  extends Model
  implements IClientStatementExpenseSchema
{
  @attr('string') description!: string;
  @attr('number') quantity!: number;
  @attr('number') unitPrice!: number;
  @attr('number') amountWithTax!: number;
  @attr('number') amountWithoutTax!: number;
  @attr('number') unitPriceWithoutTax!: number;

  @belongsTo('client-statement', {
    inverse: 'expenses',
    async: false,
  })
  clientStatement!: ClientStatementModel;

  @belongsTo('tax', { inverse: null, async: false })
  tax?: TaxModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'client-statement-expense': ClientStatementExpenseModel;
  }
}
