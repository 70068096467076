import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import { runTask } from 'ember-lifeline';
import { modifier } from 'ember-modifier';
import { UploadFile } from 'ember-file-upload';
import { guidFor } from '@ember/object/internals';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import ContextualReplyModel from 'uplisting-frontend/models/contextual-reply';
import MessageModel from 'uplisting-frontend/models/message';
import BookingModel from 'uplisting-frontend/models/booking';
import { ONE_MB } from 'uplisting-frontend/utils';

interface IArgs {
  booking: BookingModel;
  reply?: ContextualReplyModel;
  saveReplyRoute: string;
  editDetailsRoute: string;
  savedRepliesRoute: string;
}

export interface BookingMessagesSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiBookingMessagesComponent extends Component<BookingMessagesSignature> {
  @service intl!: Services['intl'];
  @service store!: Services['store'];
  @service analytics!: Services['analytics'];
  @service fileHandler!: Services['file-handler'];
  @service notifications!: Services['notifications'];

  @service('repositories/message')
  messageRepository!: Services['repositories/message'];

  @cached @tracked isSendingMessage = false;
  @cached @tracked isUploadingImage = false;
  @cached @tracked uploadedImage?: string | null = '';

  MAX_FILE_SIZE = 20 * ONE_MB;

  @cached
  get changeset(): GenericChangeset<MessageModel> {
    return this.messageRepository.buildChangeset({
      content: this.args.reply?.content ?? '',
    });
  }

  @cached
  get booking(): BookingModel {
    return this.args.booking;
  }

  @cached
  get canUploadImage(): boolean {
    return this.booking.isAirbnbOfficial;
  }

  @cached
  get imageUploadId(): string {
    return guidFor(this);
  }

  @cached
  get messagePlaceholder(): string {
    const { intl } = this;

    const name =
      this.booking.guestName ??
      intl.t('action_bookings_messages.messages.default_guest_name');

    return intl.t('action_bookings_messages.messages.placeholder', { name });
  }

  @cached
  get isReadOnlyAirbnb(): boolean {
    return !!(
      this.booking.property.airbnbOfficialLink?.readOnly &&
      this.booking.isAirbnbOfficial
    );
  }

  @cached
  get messageNotSendable(): boolean {
    if (this.isReadOnlyAirbnb) {
      return true;
    }

    const contentMissing = !this.changeset.content && !this.changeset.image;
    const contentInvalid = this.changeset.isInvalid;
    const channelNotSupported = !this.booking.canSendMessage;

    return contentMissing || contentInvalid || channelNotSupported;
  }

  @action
  async handleUploadImage(file: UploadFile): Promise<void> {
    if (file.size > this.MAX_FILE_SIZE) {
      this.notifications.error('The photo cannot be bigger than 20Mb');
      return;
    }

    this.changeset.image = null;
    this.uploadedImage = null;
    this.isUploadingImage = true;

    try {
      const result = await this.fileHandler.client.upload(file.file);
      this.changeset.image = result.url;
      this.uploadedImage = result.url;
    } catch {
      this.notifications.error();
    } finally {
      this.isUploadingImage = false;
    }
  }

  @action
  handleRemoveImage() {
    this.changeset.image = null;
    this.uploadedImage = null;
  }

  @action
  async handleSubmitMessage(): Promise<void> {
    const message = this.messageRepository.createRecord({
      content: this.changeset.content,
      image: this.changeset.image,
      inbound: false,
      type: this.booking.channel,
      createdAt: new Date(),
      booking: this.booking,
    });

    this.isSendingMessage = true;
    this.changeset.content = '';

    try {
      await message.save();

      this.analytics.trackEvent(AnalyticsEvents.messageSent);

      this.store.findAll('inbox-message', { reload: true });
    } catch {
      this.notifications.error();
    } finally {
      this.isSendingMessage = false;

      this._scrollToBottom(
        document.getElementById('booking-messages') as HTMLDivElement,
      );
    }
  }

  scrollToBottom = modifier((messagesParent: HTMLDivElement): void => {
    this._scrollToBottom(messagesParent);
  });

  private _scrollToBottom(messagesParent: HTMLDivElement): void {
    runTask(
      this,
      () => {
        (messagesParent.parentElement as HTMLDivElement).scrollTop =
          messagesParent.scrollHeight;
      },
      0,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Messages': typeof UiBookingMessagesComponent;
  }
}
