import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import GuestIdentityImageModel from 'uplisting-frontend/models/guest-identity-image';

interface IArgs {
  image: GuestIdentityImageModel;
}

export interface BookingIdentityImageSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiBookingIdentityImageComponent extends Component<BookingIdentityImageSignature> {
  @service store!: Services['store'];
  @service notifications!: Services['notifications'];

  @service('repositories/guest-identity-image')
  guestIdentityImageRepository!: Services['repositories/guest-identity-image'];

  @cached @tracked isDownloading = false;

  @cached
  get image(): GuestIdentityImageModel {
    return this.args.image;
  }

  @action
  async handleDownloadImage(): Promise<void> {
    this.isDownloading = true;

    const { image } = this;

    try {
      await this.guestIdentityImageRepository.getRecords(
        image.guestIdentityVerificationId,
      );

      window.open(image.url, '_blank');
    } catch {
      this.notifications.error();
    } finally {
      this.isDownloading = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Identity::Image': typeof UiBookingIdentityImageComponent;
  }
}
