import Service, {
  type Registry as Services,
  inject as service,
} from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import config from 'ember-get-config';
import uniq from 'lodash-es/uniq';
import { action } from '@ember/object';
import CustomerModel from 'uplisting-frontend/models/customer';
import PropertyModel from 'uplisting-frontend/models/property';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import { SecurityDepositPer } from 'uplisting-frontend/models/schemas';

export default class PricingService extends Service {
  @service intl!: Services['intl'];
  @service session!: Services['session'];
  @service features!: Services['features'];
  @service analytics!: Services['analytics'];
  @service notifications!: Services['notifications'];

  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/account-configuration')
  accountConfigurationRepository!: Services['repositories/account-configuration'];

  @service('repositories/guest-identity-verification-configuration')
  guestIdentityVerificationConfigurationRepository!: Services['repositories/guest-identity-verification-configuration'];

  @cached @tracked isUpgradingPlan = false;

  private ANNUAL_DISCOUNT = config.annualDiscount / 100;
  private NUMBER_OF_PROPERTIES_FOR_DISCOUNTED_PRICE = 10;

  private clientManagementPrices = {
    GBP: 4,
    EUR: 5,
    USD: 6,
    AUD: 8,
    CAD: 7.5,
    NZD: 8.5,
  };

  private clientStatementPrices = {
    GBP: 4,
    EUR: 5,
    USD: 6,
    AUD: 8,
    CAD: 7.5,
    NZD: 8.5,
  };

  private seamPrices = {
    GBP: 4,
    EUR: 5,
    USD: 6,
    AUD: 8,
    CAD: 7.5,
    NZD: 8.5,
  };

  private securityDepositPrices = {
    booking: {
      GBP: 1.5,
      EUR: 1.75,
      USD: 2,
      AUD: 3,
      CAD: 2.5,
      NZD: 3,
    },

    property: {
      GBP: 4,
      EUR: 5,
      USD: 6,
      AUD: 8,
      CAD: 7.5,
      NZD: 8.5,
    },
  };

  private guestIdentityPrices = {
    full: {
      GBP: 1.25,
      EUR: 1.5,
      USD: 1.65,
      AUD: 2.2,
      CAD: 2.1,
      NZD: 2.4,
    },

    discounted: {
      GBP: 1,
      EUR: 1.2,
      USD: 1.3,
      AUD: 1.75,
      CAD: 1.7,
      NZD: 1.9,
    },
  };

  private v1Prices = {
    GBP: 8,
    EUR: 9,
    USD: 10,
    AUD: 14,
    NZD: 15,
    CAD: 14,
  };

  private v2Prices = {
    flat: {
      GBP: 80,
      EUR: 90,
      USD: 100,
      AUD: 144,
      NZD: 150,
      CAD: 132,
    },

    tier1: {
      GBP: 16,
      EUR: 18,
      USD: 20,
      AUD: 29,
      NZD: 30,
      CAD: 26,
    },

    tier2: {
      GBP: 8,
      EUR: 9,
      USD: 10,
      AUD: 14,
      NZD: 15,
      CAD: 13,
    },

    tier3: {
      GBP: 4,
      EUR: 4.5,
      USD: 5,
      AUD: 7,
      NZD: 8,
      CAD: 7,
    },
  };

  private v3Prices = {
    GBP: 16,
    EUR: 18,
    USD: 20,
    AUD: 29,
    NZD: 30,
    CAD: 26,
  };

  private v4Prices = this.v3Prices;

  @cached
  get supportedCurrencies(): string[] {
    return ['GBP', 'EUR', 'USD', 'AUD', 'CAD', 'NZD'];
  }

  @cached
  get customer(): CustomerModel | undefined {
    return this.session.currentUser.customer;
  }

  @cached
  get currency(): string {
    if (this.customer?.currency) {
      return this.customer.currency.toUpperCase();
    }

    const { properties, supportedCurrencies } = this;

    const currencies = uniq(properties.map((property) => property.currency));

    const relevant = currencies.find((currency) =>
      supportedCurrencies.includes(currency),
    );

    if (relevant) {
      return relevant.toUpperCase();
    }

    return 'USD';
  }

  @cached
  get planVersion(): number | undefined {
    return this.customer?.planVersion;
  }

  @cached
  get properties(): PropertyModel[] {
    return this.propertyRepository.peekAll();
  }

  @cached
  get numberOfProperties(): number {
    return this.properties
      .map((property) =>
        property.multiUnits.length ? property.multiUnits : property,
      )
      .flat().length;
  }

  @cached
  get annualAmount(): number {
    return 12 * (1 - this.ANNUAL_DISCOUNT);
  }

  @cached
  get annualPrice(): number {
    return this.annualAmount * this.v3Price;
  }

  @cached
  get planDuration(): string {
    return this.intl.t(`duration.${this.customer?.annual ? 'year' : 'month'}`);
  }

  @cached
  get totalSubscriptionCost(): number {
    if (this.customer?.annual) {
      return this.annualPrice;
    }

    return this.subscriptionAmount();
  }

  @cached
  get annualSaving(): number {
    return this.v3Price * 12 - this.annualPrice;
  }

  @cached
  get annualPercentageSaving(): number {
    return this.ANNUAL_DISCOUNT * 100;
  }

  @cached
  get clientManagementPrice(): number {
    return this.clientManagementPrices[this.currency] as number;
  }

  @cached
  get clientStatementsPrice(): number {
    const price = this.clientStatementPrices[this.currency] as number;

    if (this.features.isClientManagementEnabledOnAccount) {
      return price;
    }

    return this.clientManagementPrice + price;
  }

  @cached
  get seamPrice(): number {
    return this.seamPrices[this.currency] as number;
  }

  @cached
  get guestIdentityPrice(): number {
    const { currentConfiguration } =
      this.guestIdentityVerificationConfigurationRepository;

    const key = currentConfiguration.discounted ? 'discounted' : 'full';

    return this.guestIdentityPrices[key][this.currency];
  }

  @cached
  get securityDepositPer(): SecurityDepositPer {
    return this.accountConfigurationRepository.currentAccountConfiguration
      .securityDepositPer;
  }

  @cached
  get securityDepositPerTranslationKey(): string {
    return `security_deposit_per.${this.securityDepositPer}`;
  }

  @cached
  get securityDepositPrice(): number {
    return this.securityDepositPrices[this.securityDepositPer][this.currency];
  }

  @cached
  get v1Price(): number {
    const pricePerProperty = this.v1Prices[this.currency];
    const { NUMBER_OF_PROPERTIES_FOR_DISCOUNTED_PRICE } = this;

    if (this.numberOfProperties < NUMBER_OF_PROPERTIES_FOR_DISCOUNTED_PRICE) {
      return Math.max(this.numberOfProperties, 5) * pricePerProperty;
    }

    const discountedPricePerProperty = pricePerProperty * 0.5;

    const subscriptionAmount =
      NUMBER_OF_PROPERTIES_FOR_DISCOUNTED_PRICE * pricePerProperty;

    return (
      subscriptionAmount +
      (this.numberOfProperties - NUMBER_OF_PROPERTIES_FOR_DISCOUNTED_PRICE) *
        discountedPricePerProperty
    );
  }

  @cached
  get v2FlatPrice(): number {
    return this.v2Prices.flat[this.currency];
  }

  @cached
  get v2Tier1Price(): number {
    return (
      Math.max(0, Math.min(this.numberOfProperties - 5, 15)) *
      this.v2Prices.tier1[this.currency]
    );
  }

  @cached
  get v2Tier2Price(): number {
    return (
      Math.max(0, Math.min(this.numberOfProperties - 20, 80)) *
      this.v2Prices.tier2[this.currency]
    );
  }

  @cached
  get v2Tier3Price(): number {
    return (
      Math.max(0, Math.min(this.numberOfProperties - 100, 1000)) *
      this.v2Prices.tier3[this.currency]
    );
  }

  @cached
  get v2Price(): number {
    return (
      this.v2FlatPrice +
      this.v2Tier1Price +
      this.v2Tier2Price +
      this.v2Tier3Price
    );
  }

  @cached
  get v3Price(): number {
    return Math.max(5, this.numberOfProperties) * this.v3Prices[this.currency];
  }

  @cached
  get v4Price(): number {
    return Math.max(1, this.numberOfProperties) * this.v4Prices[this.currency];
  }

  @action
  async handleUpgradePlan(): Promise<void> {
    const { customer } = this;

    if (!customer) {
      return;
    }

    const prevPlanVersion = customer.planVersion;

    try {
      this.isUpgradingPlan = true;
      customer.planVersion = 0;

      await customer.save();

      this.analytics.trackEvent(AnalyticsEvents.legacyPlanUpgrade);

      this.notifications.info('notifications.plan_upgraded');
    } catch {
      customer.planVersion = prevPlanVersion;
      this.notifications.error();
    } finally {
      this.isUpgradingPlan = false;
    }
  }

  public subscriptionAmount(planVersion?: number): number {
    const version = planVersion ?? this.planVersion;

    switch (version) {
      case 1:
        return this.v1Price;
      case 2:
        return this.v2Price;
      case 4:
        return this.v4Price;
      default:
        return this.v3Price;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    pricing: PricingService;
  }
}
