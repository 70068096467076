import { type Registry as Services, inject as service } from '@ember/service';
import DS from 'ember-data';
import { hash } from 'rsvp';
import type Transition from '@ember/routing/transition';
import ClientsIndexController from 'uplisting-frontend/pods/clients/index/controller';
import ClientModel from 'uplisting-frontend/models/client';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';

interface IClientsModel {
  clients: DS.PromiseArray<ClientModel>;
}

export default class ClientsIndexRoute extends PermittedRoute {
  @service features!: Services['features'];
  @service('repositories/client')
  clientRepository!: Services['repositories/client'];

  permission = 'clients.index';

  async model(): Promise<IClientsModel | undefined> {
    if (!this.features.isClientManagementEnabledOnAccount) {
      return;
    }

    return hash<IClientsModel>({
      clients: this.clientRepository.findAll({
        include: 'client_properties,invitations,permissions,tax,users',
      }),
    });
  }

  setupController(
    controller: ClientsIndexController,
    model: IClientsModel,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, model);
  }

  resetController(controller: ClientsIndexController) {
    controller.inputValue = '';

    controller.showCreateClientModal = false;
    controller.showArchiveClientModal = false;
    controller.clientToArchive = undefined;
  }
}
