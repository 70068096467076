import Model, { attr } from '@ember-data/model';
import { type IAirbnbOfficialUpgradeSchema } from 'uplisting-frontend/models/schemas';

export default class AirbnbOfficialUpgradeModel
  extends Model
  implements IAirbnbOfficialUpgradeSchema
{
  @attr('string') code!: string;
  @attr('string') accessLevel!: string;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'airbnb-official-upgrade': AirbnbOfficialUpgradeModel;
  }
}
