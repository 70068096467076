import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import PriceModel from 'uplisting-frontend/models/price';

interface IArgs {
  price: PriceModel;
}

export interface BookingPriceSummarySignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiBookingPriceSummaryComponent extends Component<BookingPriceSummarySignature> {
  @cached
  get price(): PriceModel {
    return this.args.price;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Price::Summary': typeof UiBookingPriceSummaryComponent;
  }
}
