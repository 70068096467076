import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import Litepicker from 'litepicker';
import { ref } from 'ember-ref-bucket';
import { eachDayOfInterval } from 'date-fns/eachDayOfInterval';
import { startOfDay } from 'date-fns/startOfDay';
import { endOfDay } from 'date-fns/endOfDay';
import { isWithinInterval } from 'date-fns/isWithinInterval';
import { isFirstDayOfMonth } from 'date-fns/isFirstDayOfMonth';
import { isSameDay } from 'date-fns/isSameDay';
import { isLastDayOfMonth } from 'date-fns/isLastDayOfMonth';
import { DateTime } from 'litepicker/dist/types/datetime';
// @ts-expect-error - can't resolve styling file
// eslint-disable-next-line import-x/no-unresolved
import styles from 'uplisting-frontend/pods/components/ui/compare-date-range/styles';
import { type IDropdown } from 'ember-bootstrap/components/bs-dropdown';
import { type ActiveSelection } from 'uplisting-frontend/pods/components/ui/compare-date-range/component';
import { getMinDate, utcDate, dateToQuery } from 'uplisting-frontend/utils';

interface IArgs {
  startDate: Date;
  endDate: Date | '-';

  compareToStartDate?: Date;
  compareToEndDate?: Date | '-';

  activeSelection: ActiveSelection;
  isFirstDateRangeInvalid: boolean;

  multiselectEnabled: boolean;
  dateFormat?: string;
  onDateSelect(date: DateTime): void;
  onDestroy(): void;

  // eslint-disable-next-line no-use-before-define
  onPickerDateRangeInit(picker: UiPickerDateRangeComponent);
}

interface PickerDateRangeSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    default: [IDropdown];
  };
}

export default class UiPickerDateRangeComponent extends Component<PickerDateRangeSignature> {
  @service intl!: Services['intl'];
  @service screen!: Services['screen'];

  @ref('sidebar') sidebar!: HTMLDivElement;

  sidebarId = 'picker-sidebar';
  originalUI?: HTMLDivElement;

  @cached @tracked picker!: Litepicker;
  // public property so other components can control the state of the date range picker(close, open)
  @cached @tracked dropdown?: IDropdown;

  @cached
  get startDate(): Date {
    return this.args.startDate;
  }

  @cached
  get endDate(): Date | '-' {
    return this.args.endDate;
  }

  @cached
  get compareToStartDate(): Date | undefined {
    return this.args.compareToStartDate;
  }

  @cached
  get compareToEndDate(): Date | undefined | '-' {
    return this.args.compareToEndDate;
  }

  @cached
  get dateFormat(): string {
    return this.args.dateFormat ?? 'd MMM yyyy';
  }

  @cached
  get multiselectEnabled(): boolean {
    return this.args.multiselectEnabled;
  }

  @action
  async loadDatePicker(): Promise<void> {
    await import('litepickerAddon');

    if (this.screen.isLargeOrBelow) {
      await import('mobileFriendlyLitepicker');
    }
  }

  @action
  handleSetupPicker(dd: IDropdown, element: HTMLDivElement): void {
    this.dropdown = dd;

    this.listenEvents();

    const parent = this.screen.isLargeOrBelow
      ? (element.querySelector('.modal-content') as HTMLDivElement)
      : element;

    this.picker = new Litepicker({
      element,
      showTooltip: false,
      startDate: this.startDate,
      endDate: this.endDate,
      parentEl: parent,
      autoApply: false,
      allowRepick: true,
      numberOfMonths: this.screen.isLargeOrBelow ? 1 : 3,
      lang: this.intl.primaryLocale,
      position: 'top left',
      singleMode: true,
      highlightedDaysFormat: 'YYYY-MM-DD',
      // mark all dates as `locked`, because dates are enabled to select only after start/end date input focus
      lockDaysFilter() {
        return true;
      },
      // same `z-index` as in the `$z-index` scss variable for the dropdown menu
      zIndex: 6,

      plugins: ['mobilefriendly'],
      mobileFriendly: true,
      // TODO: link to the PR with the fix: https://github.com/wakirin/Litepicker/pull/319
      // @ts-expect-error - PR is not merged yet
      mobilefriendly: {
        breakpoint: 480,
      },
    });

    this.picker.on('render', (ui) => {
      ui.classList.remove('mobilefriendly');
      this.setSidebarPosition(ui);
    });

    this.picker.on('render:day', (day, date) => {
      const { compareToStartDate, compareToEndDate } = this;

      if (!compareToStartDate || !compareToEndDate) {
        return;
      }

      const interval = {
        start: startOfDay(compareToStartDate),
        end: endOfDay(compareToEndDate as Date),
      };

      if (compareToEndDate === '-') {
        if (isSameDay(compareToStartDate, date.toJSDate())) {
          day.classList.add('is-compare-to-date');
        }
      } else if (isWithinInterval(date.toJSDate(), interval)) {
        day.classList.add('is-compare-to-date');
      }
    });

    this.picker.on('before:click', (target) => {
      if (!target.classList.contains('day-item')) {
        return;
      }

      if (!this.args.activeSelection) {
        this.picker.preventClick = true;
      }
    });

    this.picker.on('preselect', (date) => {
      this.args.onDateSelect(date);
    });

    // @ts-expect-error - overriding default behavior
    this.picker.ui.addEventListener(
      'mouseenter',
      () => {
        this.setPickerUi();
      },
      true,
    );

    this.setHighlightedDays();
    this.picker.show();

    // @ts-expect-error - ignoring accessing private property error
    this.originalUI = this.picker.ui as HTMLDivElement;

    this.setSidebarPosition(this.originalUI);

    this.args.onPickerDateRangeInit(this);
  }

  public setHighlightedDays(goToDate?: boolean): void {
    if (this.args.isFirstDateRangeInvalid) {
      return;
    }

    const { startDate, endDate, compareToStartDate, compareToEndDate } = this;

    const dates = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });

    if (compareToStartDate && compareToEndDate === '-') {
      dates.push(compareToStartDate);
    } else if (compareToStartDate && compareToEndDate) {
      const compareToDates = eachDayOfInterval({
        start: compareToStartDate,
        end: compareToEndDate as Date,
      });

      dates.push(...compareToDates);
    }

    if (goToDate) {
      this.setPickerUi();

      const minDate = getMinDate(dates);

      this.picker.gotoDate(minDate);
    }

    const datesFormatted = dates.map((date) => dateToQuery(utcDate(date)));

    this.picker.setHighlightedDays(datesFormatted);
  }

  public setLockDates(): void {
    const { activeSelection } = this.args;
    // @ts-expect-error - overriding default behavior
    this.picker.options.lockDaysFilter = (dateTime: DateTime) => {
      const date = dateTime.toJSDate();

      // only first days of months are available for the `start date` selection
      const isSelectingStart = ['startDate', 'compareToStartDate'].includes(
        activeSelection,
      );

      // only last days of months are available for the `end date` selection
      const isSelectingEnd = ['endDate', 'compareToEndDate'].includes(
        activeSelection,
      );

      if (isSelectingStart) {
        return !isFirstDayOfMonth(date);
      } else if (isSelectingEnd) {
        return !isLastDayOfMonth(date);
      }

      return false;
    };

    this.setPickerUi();
    // @ts-expect-error - accessing private method
    this.picker.render();
  }

  @action
  handleWillDestroy(): void {
    this.picker.destroy();
    this.args.onDestroy();
  }

  public clearSelection(): void {
    this.picker.clearSelection();
  }

  private setSidebarPosition(element: HTMLDivElement): void {
    if (!this.screen.isLargeOrBelow) {
      this.sidebar.style.height = `${element.clientHeight + 1.5}px`;
      this.sidebar.style.marginLeft = `${element.clientWidth}px`;
    }
  }

  private setPickerUi(unset = false): void {
    const ui = unset ? undefined : this.originalUI;

    // @ts-expect-error - overriding default behavior
    this.picker.ui = ui;
  }

  /**
   * @description we want to ignore the default behaviour of the date range picker
   * when click happens outside, so it wan't close the picker element.
   * here we are ignoring the click on the sidebar elements
   */
  private listenEvents(): void {
    document.addEventListener(
      'click',
      (e) => {
        const target = e.target as HTMLElement;
        const element = this.screen.isLargeOrBelow
          ? target.closest(`.${styles['row-item']}`)
          : target.closest(`#${this.sidebarId}`);

        if (element) {
          this.setPickerUi(true);
        } else if (this.originalUI) {
          this.setPickerUi();
        }
      },
      true,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Picker::DateRange': typeof UiPickerDateRangeComponent;
  }
}
