import type Transition from '@ember/routing/transition';
import BaseRoute from 'uplisting-frontend/pods/base/route';
import ActionBookingsRepliesNewController from 'uplisting-frontend/pods/action/bookings/replies/new/controller';

export default class ActionBookingsRepliesNewRoute extends BaseRoute {
  model() {
    return this.modelFor('action.bookings');
  }

  setupController(
    controller: ActionBookingsRepliesNewController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { booking: model });
  }
}
