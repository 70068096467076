import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';
import { type IPriceSchema } from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';
import ExtraPriceModel from 'uplisting-frontend/models/extra-price';

export default class PriceModel extends Model implements IPriceSchema {
  @attr('number') hostEarnings!: number | null;
  @attr('number') guestPrice!: number | null;
  @attr('number') numberOfGuests!: number | null;
  @attr('string') currency!: string;
  @attr('number') cancellationPayout!: number | null;
  @attr('number') extraGuestCharge!: number | null;
  @attr('number') subtotal!: number | null;
  @attr('number') rentTax!: number | null;
  @attr('number') reservationTax!: number;
  @attr('number') personTax!: number | null;
  @attr('number') nightlyTax!: number | null;
  @attr('number') balance!: number;
  @attr('number') grossRevenue!: number;
  @attr('number') managementFee!: number | null;
  @attr('number') averagePricePerNight!: number | null;
  @attr('number') accommodation!: number | null;
  @attr('number') cleaningFee!: number | null;
  @attr('boolean') hostEdited!: boolean | null;
  @attr('number') netRevenue!: number;

  @belongsTo('booking', { async: false, inverse: 'price' })
  booking!: BookingModel;

  @hasMany('extra-price', { async: false, inverse: null })
  discounts!: ExtraPriceModel[];

  @hasMany('extra-price', { async: false, inverse: null })
  charges!: ExtraPriceModel[];

  @hasMany('extra-price', { async: false, inverse: null })
  fees!: ExtraPriceModel[];

  @hasMany('extra-price', { async: false, inverse: null })
  taxes!: ExtraPriceModel[];

  @hasMany('extra-price', { async: false, inverse: null })
  channelFees!: ExtraPriceModel[];

  @cached
  get visible(): boolean {
    return this.hasHostEarnings || this.hasGuestPrice;
  }

  @cached
  get ownerNetRevenue(): number | undefined {
    if (this.hasNetRevenue && this.hasManagementFee) {
      return (this.netRevenue as number) - (this.managementFee as number);
    }
  }

  @cached
  get hasGuestPrice(): boolean {
    return (this.guestPrice ?? 0) > 0;
  }

  @cached
  get hasCancellationPayout(): boolean {
    return isPresent(this.cancellationPayout);
  }

  @cached
  get hasHostEarnings(): boolean {
    return isPresent(this.hostEarnings);
  }

  @cached
  get hasBalance(): boolean {
    return isPresent(this.balance);
  }

  @cached
  get hasNetRevenue(): boolean {
    return isPresent(this.netRevenue);
  }

  @cached
  get hasManagementFee(): boolean {
    return isPresent(this.managementFee);
  }

  @cached
  get feesWithAmount(): ExtraPriceModel[] {
    return this.getDataFor('fees');
  }

  @cached
  get taxesWithAmount(): ExtraPriceModel[] {
    return this.getDataFor('taxes');
  }

  @cached
  get chargesWithAmount(): ExtraPriceModel[] {
    return this.getDataFor('charges');
  }

  @cached
  get discountsWithAmount(): ExtraPriceModel[] {
    return this.getDataFor('discounts');
  }

  @cached
  get channelFeesWithAmount(): ExtraPriceModel[] {
    return this.getDataFor('channelFees');
  }

  private getDataFor(
    field: 'discounts' | 'charges' | 'fees' | 'taxes' | 'channelFees',
  ): ExtraPriceModel[] {
    const items = this[field] ?? [];

    return items.filter((item) => isPresent(item.amount));
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    price: PriceModel;
  }
}
