import { cached } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import UiBookingPriceTablesIndexComponent from 'uplisting-frontend/pods/components/ui/booking/price/tables';

export default class UiBookingPriceTablesDefaultComponent extends UiBookingPriceTablesIndexComponent {
  @service router!: Services['router'];
  @service session!: Services['session'];

  @cached
  get editablePriceRoute(): string {
    switch (this.router.currentRouteName) {
      case 'action.bookings.price.index':
        return 'action.bookings.price.edit';
      default:
        return '';
    }
  }

  @cached
  get canEditPrices(): boolean {
    if (!this.session.currentUser.hasAccessTo('bookings.price.edit')) {
      return false;
    }

    return this.booking.isUplisting || this.booking.isVrbo;
  }

  @cached
  get percentageForTitle(): string {
    const rentTax = this.price.rentTax as number;
    const subtotal = this.price.subtotal as number;

    return ((rentTax / subtotal) * 100).toFixed(2);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Price::Tables::Default': typeof UiBookingPriceTablesDefaultComponent;
  }
}
