import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import AutomatedMessageRuleModel from 'uplisting-frontend/models/automated-message-rule';
import PropertyModel from 'uplisting-frontend/models/property';

interface IArgs {
  rule: AutomatedMessageRuleModel;
}

export interface AutomatedMessageRuleRowSignature {
  Element: HTMLTableRowElement;

  Args: IArgs;
}

export default class UiAutomatedMessageRuleRowComponent extends Component<AutomatedMessageRuleRowSignature> {
  @service intl!: Services['intl'];

  @cached
  get rule(): AutomatedMessageRuleModel {
    return this.args.rule;
  }

  @cached
  get propertiesLength(): number {
    return this.rule.properties.length;
  }

  @cached
  get propertiesText(): string {
    const { properties } = this.rule;
    const { propertiesLength: length } = this;
    const nickname1 = (properties[0] as PropertyModel)?.nickname;
    const nickname2 = (properties[1] as PropertyModel)?.nickname;

    switch (length) {
      case 0:
        return '-';
      case 1:
        return nickname1;
      case 2:
        return `${nickname1}, ${nickname2}`;
      default:
        return `${nickname1}, ${nickname2} +${length - 2} ${this.intl.t(
          'general.more',
        )}`;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::AutomatedMessageRule::Row': typeof UiAutomatedMessageRuleRowComponent;
  }
}
