import Model, { attr, belongsTo } from '@ember-data/model';
import {
  type ILockCodeSchema,
  LockCodeStatus,
} from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';

export default class LockCodeModel extends Model implements ILockCodeSchema {
  @attr('string') pin!: string;
  @attr('string') status!: LockCodeStatus;

  @belongsTo('booking', {
    inverse: 'lockCode',
    async: false,
  })
  booking!: BookingModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'lock-code': LockCodeModel;
  }
}
