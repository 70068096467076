import Model, { attr, belongsTo } from '@ember-data/model';
import { type IBookingManagementFeeSettingSchema } from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';

export default class BookingManagementFeeSettingModel
  extends Model
  implements IBookingManagementFeeSettingSchema
{
  @attr('number') cleaningFeePercentage!: number;
  @attr('number') accommodationPercentage!: number;

  @belongsTo('booking', {
    inverse: 'bookingManagementFeeSetting',
    async: false,
  })
  booking!: BookingModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'booking-management-fee-setting': BookingManagementFeeSettingModel;
  }
}
