import Model, { attr, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import sumBy from 'lodash-es/sumBy';
import numeral from 'numeral';
import ReviewStatisticCategoryModel from 'uplisting-frontend/models/review-statistic-category';
import {
  type IReviewStatisticSchema,
  AirbnbNegativeTag,
  AirbnbPositiveTag,
  ReviewStatisticCategory,
} from 'uplisting-frontend/models/schemas';

export default class ReviewStatisticModel
  extends Model
  implements IReviewStatisticSchema
{
  @attr('string') mostCommonPositiveTag?: AirbnbPositiveTag;
  @attr('string') mostCommonNegativeTag?: AirbnbNegativeTag;

  @hasMany('review-statistic-category', { inverse: null, async: false })
  categories!: ReviewStatisticCategoryModel[];

  @cached
  get accuracy(): string {
    return this.calculateAverageFor(ReviewStatisticCategory.accuracy);
  }

  @cached
  get overallRating(): string {
    return this.calculateAverageFor(ReviewStatisticCategory.overallRating);
  }

  @cached
  get value(): string {
    return this.calculateAverageFor(ReviewStatisticCategory.value);
  }

  @cached
  get cleanliness(): string {
    return this.calculateAverageFor(ReviewStatisticCategory.cleanliness);
  }

  @cached
  get checkIn(): string {
    return this.calculateAverageFor(ReviewStatisticCategory.checkIn);
  }

  @cached
  get communication(): string {
    return this.calculateAverageFor(ReviewStatisticCategory.communication);
  }

  @cached
  get location(): string {
    return this.calculateAverageFor(ReviewStatisticCategory.location);
  }

  private calculateAverageFor(category: ReviewStatisticCategory): string {
    const relevant = this.categories.filter(
      (item) => item.category === category && item.rating !== 0,
    );

    const overall = sumBy(relevant, 'rating') / relevant.length;

    return numeral(overall).format('0.00');
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'review-statistic': ReviewStatisticModel;
  }
}
