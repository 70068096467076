import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import { ref } from 'ember-ref-bucket';
import orderBy from 'lodash-es/orderBy';
import BaseController from 'uplisting-frontend/pods/base/controller';
import PropertyModel from 'uplisting-frontend/models/property';
import ManagementFeeRuleModel, {
  type IFeeRuleVariable,
  FEE_RULE_VARIABLES,
} from 'uplisting-frontend/models/management-fee-rule';
import { type ICalculateSampleResult } from 'uplisting-frontend/services/repositories/management-fee-rule';
import { type IErrorResult } from 'uplisting-frontend/services/http-client';
import { insertAtCursor, toggleHasManyValue } from 'uplisting-frontend/utils';

export default class ClientsManagementFeeRulesIndexController extends BaseController {
  @service('repositories/management-fee-rule')
  managementFeeRuleRepository!: Services['repositories/management-fee-rule'];

  @cached @tracked properties!: PropertyModel[];
  @cached @tracked feeRule!: ManagementFeeRuleModel;
  @cached @tracked sampleError?: IErrorResult;
  @cached @tracked sampleResult?: ICalculateSampleResult;
  @cached @tracked isCalculatingSampleResult = false;

  @ref('formulaInput') formulaInput!: HTMLInputElement;

  variables = FEE_RULE_VARIABLES;

  mathOperatorsGrouped: IFeeRuleVariable[][] = [
    [
      {
        id: '+',
        name: '+',
      },
      {
        id: '-',
        name: '-',
      },
      {
        id: '*',
        name: '*',
      },
      {
        id: '/',
        name: '/',
      },
    ],
    [
      {
        id: '(',
        name: '(',
      },
      {
        id: ')',
        name: ')',
      },
    ],
  ];

  @cached
  get showDatePicker(): boolean {
    return !!this.changeset.recalculationDate;
  }

  @cached
  get sampleResultValue(): string {
    const sampleResult = this.sampleResult as ICalculateSampleResult;

    let { formula } = sampleResult;

    Object.entries(sampleResult.variables).forEach((variable) => {
      formula = formula.replaceAll(variable[0], `${variable[1]}`);
    });

    return `${formula.trim()} = ${sampleResult.amount}`;
  }

  @cached
  get changeset() {
    return this.managementFeeRuleRepository.buildChangeset(this.feeRule);
  }

  @cached
  get propertiesTriggerTextKey(): string {
    if (this.changeset.properties.length === 0) {
      return 'clients_management_fee_rules_edit.form.properties.empty_text';
    }

    return 'clients_management_fee_rules_edit.form.properties.text';
  }

  @cached
  get propertiesSorted(): PropertyModel[] {
    return orderBy(
      this.properties.slice(),
      [(item) => item.managementFeeRule === this.feeRule, 'hasFeeRule', 'name'],
      ['desc', 'asc', 'asc'],
    );
  }

  @action
  handleFormulaButtonClick(
    variable: IFeeRuleVariable,
    isVariable: boolean,
  ): void {
    let value = variable.name;

    if (isVariable) {
      value = `{{${value}}}`;
    }

    const content = this.changeset.prettifiedValue;
    const position = this.formulaInput.selectionStart;

    value = insertAtCursor(position, value, content);

    this.changeset.prettifiedValue = value;
    this.changeset.formula = this.changeset.prettifiedValue;
  }

  @action
  handlePropertySelect(property: PropertyModel): void {
    if (this.computeIsDisabled(property)) {
      return;
    }

    toggleHasManyValue(this.changeset, 'properties', property);
  }

  @action
  buildSelection(item: PropertyModel): PropertyModel {
    return item;
  }

  @action
  async handleFormSubmit(): Promise<void> {
    this.changeset.formula = this.changeset.prettifiedValue;
    this.changeset.formula = this.changeset.fromPrettified();

    await this.changeset.validate();

    if (this.changeset.isInvalid) {
      return;
    }

    const successMessage = this.changeset.get('isNew')
      ? 'success_message_created'
      : 'success_message_updated';

    try {
      await this.changeset.save();

      this.notifications.info(
        `clients_management_fee_rules_edit.form.${successMessage}`,
      );
      this.feeRule.recalculationDate = undefined;
      await this.router.transitionTo('clients.management-fee-rules');
    } catch {
      this.notifications.error();
    }
  }

  @action
  async handleEditCancel(): Promise<void> {
    await this.router.transitionTo('clients.management-fee-rules');
  }

  @action
  async handleFormulaUpdate(event: Event): Promise<void> {
    const value = (event.target as HTMLInputElement).value;

    this.changeset.prettifiedValue = value;
    this.changeset.formula = this.changeset.prettifiedValue;

    await this.changeset.validate();
  }

  @action
  async handleCalculateSample(): Promise<void> {
    this.isCalculatingSampleResult = true;

    const data = await this.managementFeeRuleRepository.calculateSample({
      formula: this.changeset.fromPrettified(),
    });

    if ((data as IErrorResult).isError) {
      this.sampleResult = undefined;
      this.sampleError = data as IErrorResult;
    } else {
      this.sampleError = undefined;
      this.sampleResult = data as ICalculateSampleResult;
    }

    this.isCalculatingSampleResult = false;
  }

  @action
  computeVariableToReadable(id: string): string {
    const variable = FEE_RULE_VARIABLES.find(
      (item) => item.id === id,
    ) as IFeeRuleVariable;

    return variable.name;
  }

  @action
  computeDisabledText(property: PropertyModel): string {
    const text = this.intl.t(
      'clients_management_fee_rules_edit.property_linked',
    );

    return `(${text} <b>${property.managementFeeRule?.name}</b>)`;
  }

  @action
  computeIsDisabled(property: PropertyModel): boolean {
    return (
      !!property.hasFeeRule &&
      property.managementFeeRule?.id !== this.feeRule.id
    );
  }

  @action
  handleRecalculateDateClick(): void {
    const newDate = this.showDatePicker ? undefined : new Date();

    this.handleDateSelect(newDate);
  }

  @action
  handleDateSelect(date: Date | undefined): void {
    this.changeset.recalculationDate = date;
  }
}
