import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import {
  type IClientSchema,
  ClientStatus,
} from 'uplisting-frontend/models/schemas';
import TaxModel from 'uplisting-frontend/models/tax';
import UserModel from 'uplisting-frontend/models/user';
import InvitationModel from 'uplisting-frontend/models/invitation';
import PermissionModel from 'uplisting-frontend/models/permission';
import ClientPropertyModel from 'uplisting-frontend/models/client-property';
import TeammateModel from 'uplisting-frontend/models/teammate';

export default class ClientModel extends Model implements IClientSchema {
  @attr('string') name!: string;
  @attr('string') email!: string;
  @attr('string') status!: ClientStatus;

  // Leaving those commented out for now, as they are needed in terms of the future UI stuff, but not yet implemented on the BE
  // @attr('string') companyName!: string;
  // @attr('string') avatar!: string;
  // @attr('string') phoneNumber!: string;

  @attr('string') addressStreet!: string;
  @attr('string') addressSuite!: string;
  @attr('string') addressCity!: string;
  @attr('string') addressCountry!: string;
  @attr('string') addressZipCode!: string;
  @attr('string') taxNumber!: string;

  @hasMany('user', { inverse: 'client', async: false }) users!: UserModel[];
  @hasMany('invitation', { inverse: 'client', async: false })
  invitations!: InvitationModel[];

  @hasMany('permission', { inverse: 'client', async: false })
  permissions!: PermissionModel[];

  @hasMany('client-property', { inverse: 'client', async: false })
  clientProperties!: ClientPropertyModel[];

  @hasMany('teammate', { inverse: 'client', async: false })
  teammates!: TeammateModel[];

  @belongsTo('tax', { inverse: 'client', async: true })
  tax?: TaxModel;

  @cached
  get isActive(): boolean {
    return this.status === ClientStatus.active;
  }

  @cached
  get isArchived(): boolean {
    return this.status === ClientStatus.archived;
  }

  @cached
  get initials(): string {
    if (!this.name) {
      return '';
    }

    const [firstName, lastName] = this.name.split(' ');

    if (firstName && lastName) {
      return firstName.charAt(0) + lastName.charAt(0);
    }

    if (lastName) {
      return lastName.charAt(0);
    }

    if (firstName) {
      return firstName.charAt(0);
    }

    return '';
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    client: ClientModel;
  }
}
