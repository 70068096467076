import Component from '@glimmer/component';
import { action } from '@ember/object';
import { cached } from '@glimmer/tracking';
import { type OccasionFilter } from 'uplisting-frontend/utils/occasion-filters';
import { type IOccasionFilterSectionItem } from 'uplisting-frontend/services/occasions';

interface IArgs {
  filter: OccasionFilter;
  editableFilter?: OccasionFilter;
  isFilterDeletable: boolean;
  sections?: IOccasionFilterSectionItem[];
  onEditFilter(filter: OccasionFilter): void;
  onDeleteFilter(filter: OccasionFilter): void;
  onAddFilter(filter: OccasionFilter): void;
}

interface ReportFiltersSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiReportFiltersItemComponent extends Component<ReportFiltersSignature> {
  @cached
  get filter(): OccasionFilter {
    return this.args.filter;
  }

  @action
  handleDeleteFilter(event: MouseEvent): void {
    event.stopPropagation();

    this.args.onDeleteFilter(this.filter);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::ReportFilters::Item': typeof UiReportFiltersItemComponent;
  }
}
