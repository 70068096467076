import Model, { attr } from '@ember-data/model';
import {
  type IChannelCommissionSchema,
  ChannelCommissionChannel,
} from 'uplisting-frontend/models/schemas';

export default class ChannelCommissionModel
  extends Model
  implements IChannelCommissionSchema
{
  @attr('number') amount!: number;
  @attr('string') channel!: ChannelCommissionChannel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'channel-commission': ChannelCommissionModel;
  }
}
