import DS from 'ember-data';
import type ModelRegistry from 'ember-data/types/registries/model';
import ApplicationAdapter from 'uplisting-frontend/adapters/application';

const REQUEST_V1_ENDPOINT_METHODS = ['findRecord', 'updateRecord'];

export default class PropertyAdapter extends ApplicationAdapter {
  buildURL<K extends keyof ModelRegistry>(
    modelName?: K,
    id?: string | any[] | object | null,
    snapshot?: DS.Snapshot<K> | any[] | null,
    requestType?: string,
    query?: object,
  ): string {
    const useV1Namespace = REQUEST_V1_ENDPOINT_METHODS.includes(
      requestType as string,
    );

    this.namespace = useV1Namespace ? '' : 'v2';

    return super.buildURL(modelName, id, snapshot, requestType, query);
  }
}

declare module 'ember-data/types/registries/adapter' {
  interface AdapterRegistry {
    property: PropertyAdapter;
  }
}
