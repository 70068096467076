import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import BaseController from 'uplisting-frontend/pods/base/controller';
import ChangeoverModel from 'uplisting-frontend/models/changeover';

export default class ActionChangeoversHostController extends BaseController {
  @cached @tracked changeover!: ChangeoverModel;

  @action
  async handleSaveChangeover(): Promise<void> {
    try {
      await this.changeover.save();

      this.notifications.info('action_changeovers_host.notifications.save');
    } catch {
      this.notifications.error();
    }
  }
}
