import templateOnly from '@ember/component/template-only';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface IArgs {}

interface OccasionSearchBarPlaceholderComponentSignature {
  Element: null;

  Args: IArgs;

  Blocks: {
    default: [];
  };
}

const UiOccasionSearchBarPlaceholderComponent =
  templateOnly<OccasionSearchBarPlaceholderComponentSignature>();

export default UiOccasionSearchBarPlaceholderComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::OccasionSearchBarPlaceholder': typeof UiOccasionSearchBarPlaceholderComponent;
    'ui/occasion-search-bar-placeholder': typeof UiOccasionSearchBarPlaceholderComponent;
  }
}
