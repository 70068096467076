import Model, { attr, belongsTo } from '@ember-data/model';
import { type IBookingRentalAgreementActionSchema } from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';

export default class BookingRentalAgreementActionModel
  extends Model
  implements IBookingRentalAgreementActionSchema
{
  @attr('boolean') reset!: boolean;
  @attr('boolean') markedAsSigned!: boolean;

  @belongsTo('booking', {
    async: false,
    inverse: 'bookingRentalAgreementAction',
  })
  booking!: BookingModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'booking-rental-agreement-action': BookingRentalAgreementActionModel;
  }
}
