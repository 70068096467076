import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import AccountConfigurationModel from 'uplisting-frontend/models/account-configuration';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface IArgs {}

interface ConnectLockCodeSettingsSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

const MINUTES_IN_HOUR = 60;

export default class UiConnectLockCodeSettingsComponent extends Component<ConnectLockCodeSettingsSignature> {
  @service notifications!: Services['notifications'];
  @service('repositories/account-configuration')
  accountConfigurationRepository!: Services['repositories/account-configuration'];

  lockCodeOptions = [4, 5, 6, 7, 8, 9];

  lockCodeMinOption = this.lockCodeOptions[0];
  lockCodeMaxOption = this.lockCodeOptions[this.lockCodeOptions.length - 1];

  lockHourOffsetOptions = [0, 1, 2, 3, 4];
  lockMinuteOffsetOptions = [0, 15, 30, 45];

  @cached
  get accountConfiguration(): AccountConfigurationModel {
    return this.accountConfigurationRepository.currentAccountConfiguration;
  }

  @cached
  get arrivalHourOffset(): number {
    return this.getHoursFromMinutes(
      this.accountConfiguration.smartLockCheckInBuffer,
    );
  }

  @cached
  get arrivalMinuteOffset(): number {
    return this.accountConfiguration.smartLockCheckInBuffer % MINUTES_IN_HOUR;
  }

  @cached
  get departureHourOffset(): number {
    return this.getHoursFromMinutes(
      this.accountConfiguration.smartLockCheckOutBuffer,
    );
  }

  @cached
  get departureMinuteOffset(): number {
    return this.accountConfiguration.smartLockCheckOutBuffer % MINUTES_IN_HOUR;
  }

  @action
  handleLockCodeChange(code: number): void {
    this.accountConfiguration.lockCodeLength = code;
  }

  @action
  handleChangeArrivalHourOffset(hours: number): void {
    const hoursToMinutes = hours * MINUTES_IN_HOUR;

    this.accountConfiguration.smartLockCheckInBuffer =
      hoursToMinutes + this.arrivalMinuteOffset;
  }

  @action
  handleChangeArrivalMinuteOffset(minutes: number): void {
    const currentMinutes =
      this.getHoursFromMinutes(
        this.accountConfiguration.smartLockCheckInBuffer,
      ) * MINUTES_IN_HOUR;

    this.accountConfiguration.smartLockCheckInBuffer = minutes + currentMinutes;
  }

  @action
  handleChangeDepartureHourOffset(hours: number): void {
    const hoursToMinutes = hours * MINUTES_IN_HOUR;

    this.accountConfiguration.smartLockCheckOutBuffer =
      hoursToMinutes + this.departureMinuteOffset;
  }

  @action
  handleChangeDepartureMinuteOffset(minutes: number): void {
    const currentMinutes =
      this.getHoursFromMinutes(
        this.accountConfiguration.smartLockCheckOutBuffer,
      ) * MINUTES_IN_HOUR;

    this.accountConfiguration.smartLockCheckOutBuffer =
      minutes + currentMinutes;
  }

  @action
  async handleSave(): Promise<void> {
    try {
      await this.accountConfiguration.save();

      this.notifications.info(
        'connect_lock_code_settings.lock_settings_updated',
      );
    } catch {
      this.notifications.error();
    }
  }

  private getHoursFromMinutes(minutes: number): number {
    return Math.floor(minutes / MINUTES_IN_HOUR);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Connect::LockCodeSettings': typeof UiConnectLockCodeSettingsComponent;
  }
}
