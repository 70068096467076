import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import PropertyModel from 'uplisting-frontend/models/property';
import BookingModel from 'uplisting-frontend/models/booking';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';

interface IArgs {
  booking: BookingModel;
}

export interface BookingPropertySelectorSignature {
  Element: null;

  Args: IArgs;
}

export default class UiBookingPropertySelectorComponent extends Component<BookingPropertySelectorSignature> {
  @service analytics!: Services['analytics'];
  @service notifications!: Services['notifications'];

  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @cached @tracked showConfirmModal = false;

  @cached
  get booking(): BookingModel {
    return this.args.booking;
  }

  @cached
  get properties(): PropertyModel[] {
    return this.propertyRepository.peekAll();
  }

  @action
  handleChange(property: PropertyModel): void {
    this.booking.newProperty = property;
    this.showConfirmModal = true;
  }

  @action
  handleCancel(): void {
    if (!this.booking.isSaving) {
      this.booking.newProperty = undefined;
    }

    this.showConfirmModal = false;
  }

  @action
  async handleSubmit(): Promise<void> {
    try {
      await this.booking.save();

      this.analytics.trackEvent(AnalyticsEvents.bookingMoved);
      this.notifications.info(
        'action_bookings_summary.change_property.notifications.applied',
      );
    } catch {
      this.notifications.error();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::PropertySelector': typeof UiBookingPropertySelectorComponent;
  }
}
