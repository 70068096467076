import { type Registry as Services, inject as service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import DS from 'ember-data';
import { hash } from 'rsvp';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import CalendarController from 'uplisting-frontend/pods/xcalendar/controller';
import PropertyModel from 'uplisting-frontend/models/property';
import CalendarModel from 'uplisting-frontend/models/calendar';

interface ICalendarModel {
  calendar: DS.PromiseObject<CalendarModel>;
  properties: DS.PromiseArray<PropertyModel>;
}

interface ICalendarModelResolved {
  calendar: CalendarModel;
  properties: DS.PromiseArray<PropertyModel>;
}

export default class CalendarRoute extends PermittedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/calendar')
  calendarRepository!: Services['repositories/calendar'];

  permission = 'calendar.index';

  model(): Promise<ICalendarModel> {
    return hash<ICalendarModel>({
      calendar: this.calendarRepository.loadInitialData(),
      properties: this.propertyRepository.findAll(),
    });
  }

  setupController(
    controller: CalendarController,
    model: ICalendarModelResolved,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, {
      properties: model.properties.slice(),
    });

    controller.setBookings(model.calendar);
  }
}
