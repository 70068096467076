import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import CustomMessageTagModel from 'uplisting-frontend/models/custom-message-tag';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { toggleValue } from 'uplisting-frontend/utils';
import { CustomMessageTagChannel } from 'uplisting-frontend/models/schemas';

interface IChannel {
  id: CustomMessageTagChannel;
  name: string;
}

interface IArgs {
  changeset: GenericChangeset<CustomMessageTagModel>;
  onSave(): Promise<void>;
  onClose(): void;
  onDelete(): void;
}

interface AutomateCustomMessageTagFormSignature {
  Element: HTMLFormElement;

  Args: IArgs;
}

export default class UiAutomateCustomMessageTagFormComponent extends Component<AutomateCustomMessageTagFormSignature> {
  @service intl!: Services['intl'];

  @cached
  get changeset(): GenericChangeset<CustomMessageTagModel> {
    return this.args.changeset;
  }

  @cached
  get allChannels(): IChannel[] {
    const { intl } = this;

    return [
      {
        id: CustomMessageTagChannel.airbnb,
        name: intl.t('automate_message_tags.channels.airbnb'),
      },
      {
        id: CustomMessageTagChannel.bookingDotCom,
        name: intl.t('automate_message_tags.channels.booking_dot_com'),
      },
      {
        id: CustomMessageTagChannel.google,
        name: intl.t('automate_message_tags.channels.google'),
      },
      {
        id: CustomMessageTagChannel.homeAway,
        name: intl.t('automate_message_tags.channels.home_away'),
      },
      {
        id: CustomMessageTagChannel.uplisting,
        name: intl.t('automate_message_tags.channels.uplisting'),
      },
    ];
  }

  @cached
  get selectedChannels(): IChannel[] {
    return this.allChannels.filter((channel) =>
      this.changeset.channels.includes(channel.id),
    );
  }

  @cached
  get isAllSelected(): boolean {
    return this.allChannels.every((channel) =>
      this.changeset.channels.includes(channel.id),
    );
  }

  @action
  handleToggleChannel(channel: IChannel): void {
    toggleValue(this.changeset, 'channels', channel.id);
  }

  @action
  handelSelectAllClick(): void {
    this.changeset.channels = this.isAllSelected
      ? []
      : this.allChannels.map((channel) => channel.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Automate::CustomMessageTag::Form': typeof UiAutomateCustomMessageTagFormComponent;
  }
}
