import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { timeout } from 'ember-concurrency';
import config from 'ember-get-config';
import BaseController from 'uplisting-frontend/pods/base/controller';
import ClientStatementsShowRoute from 'uplisting-frontend/pods/clients/statements/show/route';
import ClientStatementModel from 'uplisting-frontend/models/client-statement';
import { ClientStatementStatus } from 'uplisting-frontend/models/schemas';

export enum StatementView {
  edit,
  preview,
  finalize,
  generating,
  finalized,
}

export default class ClientStatementShowController extends BaseController {
  @cached @tracked statement!: ClientStatementModel;
  @cached @tracked statementView!: StatementView;

  updateInterval = 1000 * 10; // 10 seconds

  views = StatementView;
  route!: ClientStatementsShowRoute;

  @cached
  get isEditView(): boolean {
    return this.statementView === StatementView.edit;
  }

  @cached
  get isPreviewView(): boolean {
    return this.statementView === StatementView.preview;
  }

  @cached
  get isFinalizeView(): boolean {
    return this.statementView === StatementView.finalize;
  }

  @cached
  get isGeneratingView(): boolean {
    return this.statementView === StatementView.generating;
  }

  @cached
  get isFinalizedView(): boolean {
    return this.statementView === StatementView.finalized;
  }

  @cached
  get statementStatus(): ClientStatementStatus {
    return this.statement.status;
  }

  @action
  handleChangeView(view: StatementView): void {
    this.statementView = view;
  }

  @action
  async handleGoToAll(): Promise<void> {
    await this.router.transitionTo('clients.statements.index');
  }

  @action
  async handleRefresh(): Promise<void> {
    await this.route.refresh();

    this.setActiveView();
  }

  @action
  async handleFinalizeStatement(): Promise<void> {
    this.handleChangeView(StatementView.generating);

    this.statement.status = ClientStatementStatus.processing;

    await this.statement.save();

    await this.fetchFinalizedStatement();
  }

  public setActiveView(): void {
    this.handleChangeView(this.getStatementView());
  }

  private getStatementView(): StatementView {
    switch (this.statementStatus) {
      case ClientStatementStatus.draft:
        return StatementView.edit;
      case ClientStatementStatus.processing:
        return StatementView.generating;
      default:
        return StatementView.finalized;
    }
  }

  private async fetchFinalizedStatement(): Promise<void> {
    if (config.IS_TEST) {
      return;
    }

    await timeout(this.updateInterval);

    await this.statement.reload();

    if (this.statement.status === ClientStatementStatus.finalised) {
      this.handleChangeView(StatementView.finalized);
    } else {
      await this.fetchFinalizedStatement();
    }
  }
}
