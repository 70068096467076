import { type Registry as Services, inject as service } from '@ember/service';
import BaseRoute from 'uplisting-frontend/pods/base/route';

export default class ConnectDirectRoute extends BaseRoute {
  @service('repositories/direct-booking-configuration')
  directBookingConfigurationRepository!: Services['repositories/direct-booking-configuration'];

  model() {
    return this.directBookingConfigurationRepository.getDefaultRecord();
  }
}
