import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import orderBy from 'lodash-es/orderBy';
import { timezones, isSaveDisabled } from 'uplisting-frontend/utils';
import PropertyModel from 'uplisting-frontend/models/property';
import BaseController from 'uplisting-frontend/pods/base/controller';

type Sorting = '' | 'asc' | 'desc';

export default class AccountSettingsController extends BaseController {
  @service httpClient!: Services['http-client'];

  timezones = timezones;

  @cached @tracked properties!: PropertyModel[];
  @cached @tracked isSavingPropertiesOrder = false;
  @cached @tracked orderBy: Sorting = '';

  @cached
  get changeset() {
    return this.accountConfigurationRepository.buildChangeset(
      this.currentAccountConfiguration,
    );
  }

  @cached
  get sortingIcon(): string {
    switch (this.orderBy) {
      case '':
        return 'sort';
      case 'asc':
        return 'sort-down';
      case 'desc':
        return 'sort-up';
    }
  }

  @cached
  get sortingIconLabelKey(): string {
    switch (this.orderBy) {
      case '':
        return 'account_settings.sort.default';
      case 'asc':
        return 'account_settings.sort.asc';
      case 'desc':
        return 'account_settings.sort.desc';
    }
  }

  @action
  handleChangeOrder(): void {
    let newSorting: Sorting = 'asc';

    if (this.orderBy === 'asc') {
      newSorting = 'desc';
    } else if (this.orderBy === 'desc') {
      newSorting = '';
    }

    this.orderBy = newSorting;

    if (this.orderBy === '') {
      this.properties = orderBy(this.properties, ['order'], ['asc']);
    } else {
      this.properties = orderBy(this.properties, ['nickname'], [this.orderBy]);
    }
  }

  @action
  async handleSavePropertiesOrder(): Promise<void> {
    this.isSavingPropertiesOrder = true;

    try {
      const data = this.properties.map((property, index) => ({
        id: property.id,
        order: index + 1,
      }));

      await this.httpClient.postJSONApi('properties/sort', {
        properties: data,
      });

      this.notifications.info();
    } finally {
      this.isSavingPropertiesOrder = false;
    }
  }

  @action
  async handleFormSubmit(): Promise<void> {
    const saveDisabled = await isSaveDisabled(this.changeset);

    if (saveDisabled) {
      return;
    }

    try {
      await this.changeset.save();

      this.notifications.info('account_settings.notifications.saved');
    } catch {
      this.notifications.error();
    }
  }
}
