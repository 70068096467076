import { type Filter } from 'uplisting-frontend/utils/filters/types';
import { FilterId } from 'uplisting-frontend/utils/filters/abstract';

export function getFilterQueryParamName(filter: Filter): string {
  switch (filter.id) {
    case FilterId.upsellOrderStatus:
    case FilterId.clientStatementStatus:
      return 'status';
    case FilterId.property:
      return 'propertyIds';
    case FilterId.clientName:
      return 'clientIds';
    case FilterId.period:
      return 'period';
  }
}
