import { cached } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import UiBookingPriceTablesIndexComponent from 'uplisting-frontend/pods/components/ui/booking/price/tables';

export default class UiBookingPriceTablesManagementFeeComponent extends UiBookingPriceTablesIndexComponent {
  @service intl!: Services['intl'];

  @cached
  get hasManagementFee(): boolean {
    const { managementFeeRule, bookingManagementFeeSetting } = this.booking;

    return !!(managementFeeRule?.id || bookingManagementFeeSetting?.id);
  }

  @cached
  get managementFeeText(): string {
    const { intl } = this;
    const { managementFeeRule } = this.booking;

    if (managementFeeRule?.name) {
      return intl.t(
        'action_bookings_price.management_fee_table.custom_formula',
        { name: managementFeeRule.name },
      );
    }

    const { bookingManagementFeeSetting } = this.booking;

    const feeParts: string[] = [];

    if (bookingManagementFeeSetting?.accommodationPercentage) {
      feeParts.push(
        intl.t(
          'action_bookings_price.management_fee_table.accommodation_total',
          { percentage: bookingManagementFeeSetting.accommodationPercentage },
        ),
      );
    }

    if (bookingManagementFeeSetting?.cleaningFeePercentage) {
      feeParts.push(
        intl.t('action_bookings_price.management_fee_table.cleaning_fee', {
          percentage: bookingManagementFeeSetting.cleaningFeePercentage,
        }),
      );
    }

    const plus = intl.t('action_bookings_price.management_fee_table.plus');

    return feeParts.join(` ${plus} `);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Price::Tables::ManagementFee': typeof UiBookingPriceTablesManagementFeeComponent;
  }
}
