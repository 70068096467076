import { hash } from 'rsvp';
import { type Registry as Services, inject as service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import ConnectStripeController from 'uplisting-frontend/pods/connect/stripe/controller';
import BaseRoute from 'uplisting-frontend/pods/base/route';

export default class ConnectStripeRoute extends BaseRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  model() {
    return hash({
      properties: this.propertyRepository.findAll(),
      stripeConnections: this.store.findAll('stripe-connection'),
    });
  }

  setupController(
    controller: ConnectStripeController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, model);

    controller.handleCallbackRedirect();
  }
}
