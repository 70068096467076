import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import BalanceAdjustmentModel from 'uplisting-frontend/models/balance-adjustment';
import { BalanceAdjustmentValidation } from 'uplisting-frontend/validations';
import { BalanceAdjustmentType } from 'uplisting-frontend/models/schemas';

export default class BalanceAdjustmentRepositoryService extends BaseRepositoryService<BalanceAdjustmentModel> {
  recordName = 'balance-adjustment';
  implementMethods = ['buildChangeset', 'createRecord'];

  validation = BalanceAdjustmentValidation;

  get defaultParams() {
    return {
      type: BalanceAdjustmentType.credit,
    };
  }
}

declare module '@ember/service' {
  interface Registry {
    'repositories/balance-adjustment': BalanceAdjustmentRepositoryService;
  }
}
