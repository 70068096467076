import Model, { attr, belongsTo } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { type IPromotionRedemptionSchema } from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';
import ExtraPriceModel from 'uplisting-frontend/models/extra-price';

export default class PromotionRedemptionModel
  extends Model
  implements IPromotionRedemptionSchema
{
  @attr('date') createdAt!: Date;

  @belongsTo('booking', { inverse: null, async: false }) booking!: BookingModel;

  @cached
  get discountAmount(): number | undefined {
    const { price } = this.booking;

    if (!price) {
      return;
    }

    const discount = price.discounts.find(
      (discount) => discount.type === 'promotion',
    ) as ExtraPriceModel;

    return discount.amount;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'promotion-redemption': PromotionRedemptionModel;
  }
}
