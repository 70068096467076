import BookingModel from 'uplisting-frontend/models/booking';

export enum BalanceAdjustmentType {
  credit = 'credit',
  debit = 'debit',
}

export interface IBalanceAdjustmentSchema {
  amount: number;
  note: string;
  type: BalanceAdjustmentType;
  createdAt: Date;

  booking: BookingModel;
}
