import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import AdditionalBookingChargeModel from 'uplisting-frontend/models/additional-booking-charge';
import { AdditionalBookingChargeValidation } from 'uplisting-frontend/validations';

export default class AdditionalBookingChargeRepositoryService extends BaseRepositoryService<AdditionalBookingChargeModel> {
  recordName = 'additional-booking-charge';
  implementMethods = ['buildChangeset', 'createRecord'];

  validation = AdditionalBookingChargeValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/additional-booking-charge': AdditionalBookingChargeRepositoryService;
  }
}
