import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import {
  type ITeammateSchema,
  type InvitationRole,
} from 'uplisting-frontend/models/schemas';
import ClientModel from 'uplisting-frontend/models/client';
import PropertyModel from 'uplisting-frontend/models/property';
import MultiUnitModel from 'uplisting-frontend/models/multi-unit';

export default class TeammateModel extends Model implements ITeammateSchema {
  @attr('string') name!: string;
  @attr('string') email!: string;
  @attr('string') role!: InvitationRole;
  @attr('date') createdAt!: Date;

  @belongsTo('client', { inverse: 'teammates', async: false })
  client?: ClientModel;

  @hasMany('property', { async: false, inverse: null })
  properties!: PropertyModel[];

  @hasMany('multi-unit', { async: false, inverse: null })
  multiUnits!: MultiUnitModel[];

  @cached
  get hasClient(): boolean {
    return !!this.client?.id;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    teammate: TeammateModel;
  }
}
