import templateOnly from '@ember/component/template-only';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface IArgs {}

interface CreateTaxSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    default: [];
  };
}

const UiCreateTaxComponent = templateOnly<CreateTaxSignature>();

export default UiCreateTaxComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ui/create-tax': typeof UiCreateTaxComponent;
  }
}
