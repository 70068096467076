import DS, { type ModelSchema } from 'ember-data';
import ApplicationSerializer from 'uplisting-frontend/serializers/application';
import {
  CustomSubdomainOptions,
  CUSTOM_SUBDOMAIN_OPTIONS,
} from 'uplisting-frontend/models/direct-booking-configuration';

/**
 * @description on the FE side we are creating a temporary `customSubdomainOption` model attribute,
 * that will contain a subdomain of a custom domain(when present). When normalizing record, that FE receive from the BE
 * we want to cut it from `custom_domain`, and move it to `custom_subdomain_option`. And when serializing record,
 * we want to serialize it, to be a part of `custom_domain` attribute.
 */
export default class DirectBookingConfigurationSerializer extends ApplicationSerializer {
  serialize(snapshot: DS.Snapshot, options: object) {
    const data = super.serialize(snapshot, options);

    const { custom_domain, custom_subdomain_option } =
      // @ts-expect-error - we have no typings for the json api
      data.data.attributes;

    if (
      custom_domain &&
      custom_subdomain_option &&
      custom_subdomain_option !== CustomSubdomainOptions.custom
    ) {
      // @ts-expect-error - we have no typings for the json api
      data.data.attributes.custom_domain = `${custom_subdomain_option}${custom_domain}`;
    }

    // @ts-expect-error - we have no typings for the json api
    delete data.data.attributes.custom_subdomain_option;

    return data;
  }

  normalize(typeClass: ModelSchema, hash: object) {
    const data = super.normalize(typeClass, hash);

    // @ts-expect-error - we have no typings for the json api
    const customDomain = data.data.attributes.customDomain;

    let customSubdomainOption = CUSTOM_SUBDOMAIN_OPTIONS[0];

    if (customDomain) {
      const subUrl = CUSTOM_SUBDOMAIN_OPTIONS.find((option) =>
        customDomain?.startsWith(option),
      );

      if (subUrl) {
        // @ts-expect-error - we have no typings for the json api
        data.data.attributes.customDomain = customDomain.replace(subUrl, '');

        customSubdomainOption = subUrl;
      } else {
        customSubdomainOption = CustomSubdomainOptions.custom;
      }
    }

    // @ts-expect-error - we have no typings for the json api
    data.data.attributes.customSubdomainOption = customSubdomainOption;

    return data;
  }
}

declare module 'ember-data/types/registries/serializer' {
  interface SerializerRegistry {
    'direct-booking-configuration': DirectBookingConfigurationSerializer;
  }
}
