import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import sortBy from 'lodash-es/sortBy';
import BaseController from 'uplisting-frontend/pods/base/controller';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import BookingWindowRuleModel, {
  DEFAULT_BOOKING_WINDOW_DAYS,
} from 'uplisting-frontend/models/booking-window-rule';
import { isSaveDisabled } from 'uplisting-frontend/utils';

interface IErr {
  code?: string;
}

export default class AutomateBookingWindowsController extends BaseController {
  @service analytics!: Services['analytics'];
  @service('repositories/booking-window-rule')
  bookingWindowRuleRepository!: Services['repositories/booking-window-rule'];

  @cached @tracked windowRules!: BookingWindowRuleModel[];

  @cached
  get windowRulesOrdered(): BookingWindowRuleModel[] {
    return sortBy(this.windowRules, (rule) =>
      rule.property.nickname.toLowerCase(),
    );
  }

  @cached
  get changesets(): GenericChangeset<BookingWindowRuleModel>[] {
    const { windowRulesOrdered, bookingWindowRuleRepository: repository } =
      this;

    return windowRulesOrdered.map((rule) => repository.buildChangeset(rule));
  }

  @action
  async handleToggleBookingWindowRuleStatus(
    changeset: GenericChangeset<BookingWindowRuleModel>,
  ): Promise<void> {
    changeset.windowDays = changeset.isDisabled
      ? DEFAULT_BOOKING_WINDOW_DAYS
      : null;

    await this.handleSave(changeset);
  }

  @action
  async handleSave(
    changeset: GenericChangeset<BookingWindowRuleModel>,
  ): Promise<void> {
    // @ts-expect-error - when input was cleared and blurred - value becomes empty string
    // in that case we want to reset the value to the default one
    if (changeset.windowDays === '') {
      changeset.windowDays = DEFAULT_BOOKING_WINDOW_DAYS;
    }

    const saveDisabled = await isSaveDisabled(changeset);

    if (saveDisabled) {
      return;
    }

    try {
      await changeset.save();

      this.notifySaved();
    } catch (e) {
      if ((e as IErr).code) {
        this.notifications.error();
      } else {
        this.notifySaved();
      }
    }
  }

  private notifySaved() {
    this.notifications.info('automate_booking_windows.notifications.saved');
    this.analytics.trackEvent(AnalyticsEvents.bookingWindowRuleUpdated);
  }
}
