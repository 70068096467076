import { cached, tracked } from '@glimmer/tracking';
import { formatDateQuery } from 'uplisting-frontend/utils';
import {
  BaseAbstractFilterParams,
  type IBaseFilterParams,
  BaseAbstractFilter,
  FilterComponent,
} from 'uplisting-frontend/utils/filters/abstract/base';

class DateFilterParams
  extends BaseAbstractFilterParams<Date>
  implements IBaseFilterParams<Date, Date>
{
  @cached @tracked value?: Date;

  public updateValue(value: Date): void {
    this.value = value;
  }

  public toQuery(): string | undefined {
    return this.value ? formatDateQuery(this.value) : undefined;
  }

  public reset(): void {
    this.value = undefined;
  }
}

export abstract class MonthFilter extends BaseAbstractFilter<Date, Date> {
  component = FilterComponent.month;

  constructor(value?: string) {
    const date = value ? new Date(value) : undefined;

    super(new DateFilterParams(date));
  }
}
