import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { type Filter } from 'uplisting-frontend/utils/filters/types';
import { FilterId } from 'uplisting-frontend/utils/filters/abstract';

interface IArgs {
  filter: Filter;
  onFilterUpdate(filter: Filter): void;
}

interface FiltersFilterSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiFiltersFilterComponent extends Component<FiltersFilterSignature> {
  @service intl!: Services['intl'];

  @cached
  get filter(): Filter {
    return this.args.filter;
  }

  @cached
  get filterComponent():
    | 'ui/filters/filter/month'
    | 'ui/filters/filter/single-select'
    | 'ui/filters/filter/multi-select' {
    return `ui/filters/filter/${this.filter.component}`;
  }

  @cached
  get filterKey(): string {
    switch (this.filter.id) {
      case FilterId.clientName:
        return 'client_name';
      case FilterId.property:
        return 'property';
      case FilterId.clientStatementStatus:
        return 'client_statement_status';
      case FilterId.period:
        return 'period';
      case FilterId.upsellOrderStatus:
        return 'upsell_order_status';
    }
  }

  @cached
  get label(): string {
    return this.intl.t(`filters.${this.filterKey}.label`);
  }

  @cached
  get placeholder(): string {
    return this.intl.t(`filters.${this.filterKey}.placeholder`);
  }

  @cached
  get noMatchesMessage(): string {
    return this.intl.t(`filters.${this.filterKey}.no_data`);
  }
}
