import { hash } from 'rsvp';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import OutboundMessagesController from 'uplisting-frontend/pods/outbound/messages/controller';

export default class OutboundMessagesRoute extends PermittedRoute {
  permission = 'outbound.messages';

  model() {
    const properties = this.store.findAll('property');
    const rules = this.store.findAll('automated-message-rule');

    return hash({ rules, properties });
  }

  resetController(controller: OutboundMessagesController) {
    controller.event = '';
    controller.preposition = '';
  }
}
