import Model, { attr, belongsTo } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { camelize } from '@ember/string';
import { type IAirbnbOfficialOpportunityInputFields } from 'uplisting-frontend/models/schemas';
import AirbnbOfficialOpportunityValueConstraintModel from 'uplisting-frontend/models/airbnb-official-opportunity-value-constraint';

export default class AirbnbOfficialOpportunityInputFieldModel
  extends Model
  implements IAirbnbOfficialOpportunityInputFields
{
  @attr('string') key!: string;
  @attr('string') keyLabel!: string | null;

  @attr('string') value?: string;

  @cached
  get storageKey(): string {
    return camelize(this.key);
  }

  @belongsTo('airbnb-official-opportunity-value-constraint', {
    inverse: null,
    async: false,
  })
  airbnbOfficialOpportunityValueConstraint!: AirbnbOfficialOpportunityValueConstraintModel;

  @cached
  get constraint(): AirbnbOfficialOpportunityValueConstraintModel {
    return this.airbnbOfficialOpportunityValueConstraint;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'airbnb-official-opportunity-input-field': AirbnbOfficialOpportunityInputFieldModel;
  }
}
