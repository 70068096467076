import type Transition from '@ember/routing/transition';
import { hash } from 'rsvp';
import { type Registry as Services, inject as service } from '@ember/service';
import BaseRoute from 'uplisting-frontend/pods/base/route';
import ActionBookingsRepliesReplyController from 'uplisting-frontend/pods/action/bookings/replies/reply/controller';

interface IParams {
  reply_id: string;
}

export default class ActionBookingsRepliesReplyRoute extends BaseRoute {
  @service('repositories/saved-reply')
  savedReplyRepository!: Services['repositories/saved-reply'];

  model(params: IParams) {
    return hash({
      savedReply: this.savedReplyRepository.findRecord(params.reply_id),
      booking: this.modelFor('action.bookings'),
    });
  }

  setupController(
    controller: ActionBookingsRepliesReplyController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, model);
  }
}
