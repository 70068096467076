import BookingModel from 'uplisting-frontend/models/booking';

export enum LockCodeStatus {
  awaiting = 'awaiting',
  pending = 'pending',
  confirmed = 'confirmed',
}

export interface ILockCodeSchema {
  pin: string;
  status: LockCodeStatus;

  booking: BookingModel;
}
