import { type Registry as Services, inject as service } from '@ember/service';
import { UiAutomateEditableRowComponent } from 'uplisting-frontend/pods/components/ui/automate/editable-row/component';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import CustomPropertyAttributeModel from 'uplisting-frontend/models/custom-property-attribute';
import { type IAutomatePropertyAttributesTableColSizes } from 'uplisting-frontend/pods/automate/property-attributes/controller';

export default class UiAutomateCustomPropertyAttributeRowComponent extends UiAutomateEditableRowComponent<
  CustomPropertyAttributeModel,
  IAutomatePropertyAttributesTableColSizes
> {
  @service('repositories/custom-property-attribute')
  repository!: Services['repositories/custom-property-attribute'];

  eventOnUpdate = AnalyticsEvents.customPropertyAttributeEdit;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Automate::CustomPropertyAttribute::Row': typeof UiAutomateCustomPropertyAttributeRowComponent;
  }
}
