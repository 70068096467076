import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';
import { SafeString } from 'handlebars';
import numeral from 'numeral';

export function priceFormat([price, currencyClass, raw = false]: [
  number | string,
  string,
  boolean,
]): SafeString | string {
  const value = typeof price === 'string' ? Number(price) : price;
  const formattedPrice = numeral(value).format('0,0.00');

  const output = `<span class="currency-badge ${currencyClass}">${formattedPrice}</span>`;

  if (raw) {
    return output;
  }

  return htmlSafe(output);
}

export default helper(priceFormat);
