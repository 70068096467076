import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import PriceModel from 'uplisting-frontend/models/price';
import BookingModel from 'uplisting-frontend/models/booking';

interface IArgs {
  price: PriceModel;
}

interface BookingPriceTablesIndexSignature {
  Element: null;

  Args: IArgs;
}

export default class UiBookingPriceTablesIndexComponent extends Component<BookingPriceTablesIndexSignature> {
  @cached
  get price(): PriceModel {
    return this.args.price;
  }

  @cached
  get currency(): string {
    return this.price.currency;
  }

  @cached
  get booking(): BookingModel {
    return this.price.booking;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Price::Tables::Index': typeof UiBookingPriceTablesIndexComponent;
  }
}
