import Model, { attr } from '@ember-data/model';
import { type IClientStatementAccountDetailSchema } from 'uplisting-frontend/models/schemas';

export default class ClientStatementAccountDetailModel
  extends Model
  implements IClientStatementAccountDetailSchema
{
  @attr('string') additionalLines!: string;
  @attr('string') addressCity!: string;
  @attr('string') addressCountry!: string;
  @attr('string') addressStreet!: string;
  @attr('string') addressSuite!: string;
  @attr('string') addressZipCode!: string;
  @attr('string') businessName!: string;
  @attr('string') logoUrl!: string;
  @attr('string') taxNumber!: string;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'client-statement-account-detail': ClientStatementAccountDetailModel;
  }
}
