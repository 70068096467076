import Model, { attr, belongsTo } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import {
  type IBookingRentalAgreementSchema,
  BookingRentalAgreementStatus,
} from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';

const SUCCESSFUL_STATUSES = [
  BookingRentalAgreementStatus.processing,
  BookingRentalAgreementStatus.processed,
  BookingRentalAgreementStatus.markedAsSigned,
];

export default class BookingRentalAgreementModel
  extends Model
  implements IBookingRentalAgreementSchema
{
  @attr('string') fileLink!: string;
  @attr('string') status!: BookingRentalAgreementStatus;

  @belongsTo('booking', {
    inverse: 'bookingRentalAgreement',
    async: false,
  })
  booking!: BookingModel;

  @cached
  get isSigned(): boolean {
    return SUCCESSFUL_STATUSES.includes(this.status);
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'booking-rental-agreement': BookingRentalAgreementModel;
  }
}
