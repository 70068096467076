import Model, { attr } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import {
  type ICustomMessageTagSchema,
  CustomMessageTagChannel,
} from 'uplisting-frontend/models/schemas';

export default class CustomMessageTagModel
  extends Model
  implements ICustomMessageTagSchema
{
  @attr('string') name!: string;
  @attr('string') description!: string;
  @attr('string', { defaultValue: '' }) content!: string;
  @attr('array', {
    defaultValue() {
      return [];
    },
  })
  channels!: CustomMessageTagChannel[];

  @cached
  get tagCode(): string {
    return this.name ? `{{${this.name}}}` : '';
  }

  @cached
  get isPersisted(): boolean {
    return !!this.id;
  }

  @cached
  get order(): number {
    if (!this.isPersisted) {
      return 0;
    }

    return Number(this.id);
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'custom-message-tag': CustomMessageTagModel;
  }
}
