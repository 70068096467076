import Model, { attr } from '@ember-data/model';
import { type IAdvancedReportConfigurationSchema } from 'uplisting-frontend/models/schemas';
import { AdvancedReportCriteria } from 'uplisting-frontend/transforms/advanced-report-criteria';

export default class AdvancedReportConfigurationModel
  extends Model
  implements IAdvancedReportConfigurationSchema
{
  @attr('string') name!: string;
  @attr('advanced-report-criteria') criteria!: AdvancedReportCriteria;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'advanced-report-configuration': AdvancedReportConfigurationModel;
  }
}
