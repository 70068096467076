import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import { timeout } from 'ember-concurrency';
import BaseController from 'uplisting-frontend/pods/base/controller';
import PropertyModel from 'uplisting-frontend/models/property';
import SeamConnectionModel from 'uplisting-frontend/models/seam-connection';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import { storage, StorageKey } from 'uplisting-frontend/utils/storage';

export default class ConnectSeamController extends BaseController {
  @service pricing!: Services['pricing'];
  @service analytics!: Services['analytics'];

  @service('repositories/seam-connection')
  seamConnectionRepositoryService!: Services['repositories/seam-connection'];

  @cached @tracked properties!: PropertyModel[];
  @cached @tracked seamConnections!: SeamConnectionModel[];
  @cached @tracked newRecord?: SeamConnectionModel;
  @cached @tracked newlyCreatedRecord?: SeamConnectionModel;

  @cached @tracked showCreateConnectionConfirmationModal = false;

  messageListener!: EventListener;

  @cached
  get connectedSeamConnections(): SeamConnectionModel[] {
    return this.seamConnections.filter(
      (connection) => connection.seamLockables.length,
    );
  }

  @action
  async handleConfirmCreateConnection(): Promise<void> {
    const data = storage.local.get<boolean>(
      StorageKey.SMART_LOCKS_PRICING_CONFIRMATION,
    );

    if (data) {
      await this.handleCreateConnection();
    } else {
      this.showCreateConnectionConfirmationModal = true;
    }
  }

  @action
  async handleCreateConnection(): Promise<void> {
    this.removeMessageListener();

    storage.local.set(StorageKey.SMART_LOCKS_PRICING_CONFIRMATION, true);

    const record = this.seamConnectionRepositoryService.createRecord();

    this.newRecord = await record.save();

    this.analytics.trackEvent(AnalyticsEvents.seamAccountConnected);

    const messageListener = (event) => {
      const eventType = event.data?.event?.event_type;

      if (eventType === 'webview.login_successful') {
        this.handleConnectionCreated();
      } else if (eventType === 'webview.login_failed') {
        this.notifications.error();
      }
    };

    this.messageListener = messageListener.bind(this);

    window.addEventListener('message', this.messageListener, false);
  }

  @action
  handleRemoveConnection(connection: SeamConnectionModel): void {
    if (this.newRecord !== connection) {
      return;
    }

    this.newRecord?.unloadRecord();

    this.newlyCreatedRecord = undefined;
    this.newRecord = undefined;
  }

  @action
  async handleCancelCreateConnection(): Promise<void> {
    await this.newRecord?.destroyRecord();

    this.newRecord = undefined;
  }

  @action
  handleHideCreateConnectionModal(): void {
    this.showCreateConnectionConfirmationModal = false;
  }

  private handleConnectionCreated(): void {
    this.newlyCreatedRecord = this.newRecord;
    this.newRecord = undefined;

    this.fetchUpdatedConnection();

    this.notifications.info();
  }

  private async fetchUpdatedConnection(): Promise<void> {
    if (!this.newlyCreatedRecord) {
      return;
    }

    await timeout(1000);

    await this.newlyCreatedRecord.reload({ adapterOptions: { refresh: true } });

    if (this.newlyCreatedRecord?.seamLockables.length) {
      this.newlyCreatedRecord = undefined;
    } else {
      await this.fetchUpdatedConnection();
    }
  }

  private removeMessageListener(): void {
    window.removeEventListener('message', this.messageListener, false);
  }
}
