import {
  validatePresence,
  validateNumber,
} from 'ember-changeset-validations/validators';

export const BalanceAdjustmentValidation = {
  amount: [validateNumber({ gt: 0 })],
  note: [validatePresence(true)],
  type: [validatePresence(true)],
};
