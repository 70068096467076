import type Transition from '@ember/routing/transition';
import BaseRoute from 'uplisting-frontend/pods/base/route';
import ConnectCallbackOfficialController from 'uplisting-frontend/pods/connect/callback/official/controller';

export default class ConnectCallbackOfficialRoute extends BaseRoute {
  async setupController(
    controller: ConnectCallbackOfficialController,
    model,
    transition: Transition,
  ): Promise<void> {
    super.setupController(controller, model, transition);

    await controller.handleCallbackResponse();
  }
}
