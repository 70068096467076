import Model, { attr, belongsTo } from '@ember-data/model';
import { isNone } from '@ember/utils';
import { cached } from '@glimmer/tracking';
import PropertyModel from 'uplisting-frontend/models/property';
import { type IBookingWindowRuleSchema } from 'uplisting-frontend/models/schemas';

export const DEFAULT_BOOKING_WINDOW_DAYS = 365;

export default class BookingWindowRuleModel
  extends Model
  implements IBookingWindowRuleSchema
{
  @attr('number') windowDays!: number | null;

  @belongsTo('property', { async: false, inverse: null })
  property!: PropertyModel;

  @cached
  get isDisabled(): boolean {
    return isNone(this.windowDays);
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'booking-window-rule': BookingWindowRuleModel;
  }
}
