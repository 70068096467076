import Model, { attr, belongsTo } from '@ember-data/model';
import { type IHomeawayActionSchema } from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';

export default class HomeawayActionModel
  extends Model
  implements IHomeawayActionSchema
{
  @attr('boolean') cancelledByOwner!: boolean;
  @attr('boolean') cancelledByTraveller!: boolean;

  @belongsTo('booking', { inverse: 'homeawayAction', async: false })
  booking!: BookingModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'homeaway-action': HomeawayActionModel;
  }
}
