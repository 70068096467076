import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import BookingModel from 'uplisting-frontend/models/booking';

interface IArgs {
  booking: BookingModel;
}

export interface BookingStatusPaymentSignature {
  Element: null;

  Args: IArgs;
}

export default class UiBookingStatusPaymentComponent extends Component<BookingStatusPaymentSignature> {
  @cached
  get booking(): BookingModel {
    return this.args.booking;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Status::Payment': typeof UiBookingStatusPaymentComponent;
  }
}
