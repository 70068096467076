import { action } from '@ember/object';
import { cached, tracked } from '@glimmer/tracking';
import orderBy from 'lodash-es/orderBy';
import DS from 'ember-data';
import BaseController from 'uplisting-frontend/pods/base/controller';
import TeammateModel from 'uplisting-frontend/models/teammate';
import InvitationModel from 'uplisting-frontend/models/invitation';
import {
  UserRoles,
  type InvitationRole,
} from 'uplisting-frontend/models/schemas';
import AccountTeamRoute from 'uplisting-frontend/pods/account/team/route';

export default class AccountTeamController extends BaseController {
  @cached @tracked teammates!: DS.PromiseArray<TeammateModel>;
  @cached @tracked invitations!: DS.PromiseArray<InvitationModel>;

  @cached @tracked invitationToRevoke?: InvitationModel;
  @cached @tracked showRevokeInvitationModal = false;

  @cached @tracked teammateToRemove?: TeammateModel;
  @cached @tracked showRemoveTeammateModal = false;

  @cached @tracked newTeammateRole?: InvitationRole;
  @cached @tracked teammateToChangeRole?: TeammateModel;
  @cached @tracked showChangeTeammateRoleModal = false;

  route!: AccountTeamRoute;

  roles = UserRoles;

  @cached
  get pendingHosts(): InvitationModel[] {
    return this.filterInvitationsByType(UserRoles.host);
  }

  @cached
  get pendingHousekeepers(): InvitationModel[] {
    return this.filterInvitationsByType(UserRoles.housekeeper);
  }

  @cached
  get pendingOwners(): InvitationModel[] {
    return this.filterInvitationsByType(UserRoles.owner);
  }

  @cached
  get existingHosts(): TeammateModel[] {
    return this.filterTeammatesByType(UserRoles.host);
  }

  @cached
  get existingHousekeepers(): TeammateModel[] {
    return this.filterTeammatesByType(UserRoles.housekeeper);
  }

  @cached
  get existingOwners(): TeammateModel[] {
    return this.filterTeammatesByType(UserRoles.owner);
  }

  @action
  handleShowRevokeInvitationModal(invitation: InvitationModel): void {
    this.invitationToRevoke = invitation;
    this.showRevokeInvitationModal = true;
  }

  @action
  handleShowRemoveTeammateModal(teammate: TeammateModel): void {
    this.teammateToRemove = teammate;
    this.showRemoveTeammateModal = true;
  }

  @action
  handleShowChangeTeammateRoleModal(
    role: InvitationRole,
    teammate: TeammateModel,
  ): void {
    this.newTeammateRole = role;
    this.teammateToChangeRole = teammate;
    this.showChangeTeammateRoleModal = true;
  }

  @action
  handleCloseRevokeInvitationModal(): void {
    this.invitationToRevoke = undefined;
    this.showRevokeInvitationModal = false;
  }

  @action
  handleCloseRemoveTeammateModal(): void {
    this.teammateToRemove = undefined;
    this.showRemoveTeammateModal = false;
  }

  @action
  handleCloseChangeTeammateRoleModal(): void {
    this.newTeammateRole = undefined;
    this.teammateToChangeRole = undefined;
    this.showChangeTeammateRoleModal = false;
  }

  @action
  async handleRevokeInvitation(): Promise<void> {
    try {
      await (this.invitationToRevoke as InvitationModel).destroyRecord();

      this.notifications.info('account_team.notifications.invitation_revoked');
    } catch {
      this.notifications.error();
    }
  }

  @action
  async handleRemoveTeammate(): Promise<void> {
    try {
      await (this.teammateToRemove as TeammateModel).destroyRecord();

      this.notifications.info('account_team.notifications.teammate_removed');
    } catch {
      this.notifications.error();
    }
  }

  @action
  async handleChangeTeammateRole(): Promise<void> {
    try {
      const teammate = this.teammateToChangeRole as TeammateModel;

      teammate.role = this.newTeammateRole as InvitationRole;

      await teammate.save();

      await this.route.refresh();

      this.notifications.info(
        'account_team.notifications.teammate_access_updated',
      );
    } catch {
      this.notifications.error();
    }
  }

  @action
  async handleUpdateTeammate(teammate: TeammateModel): Promise<void> {
    try {
      await teammate.save();

      await this.route.refresh();

      this.notifications.info(
        'account_team.notifications.teammate_access_updated',
      );
    } catch {
      this.notifications.error();
    }
  }

  private filterInvitationsByType(type: InvitationRole): InvitationModel[] {
    const filtered = this.invitations.filter((item) => {
      return item.id && item.role === type && item.status !== 'accepted';
    });

    return orderBy(filtered, 'createdAt', ['desc']);
  }

  private filterTeammatesByType(type: InvitationRole): TeammateModel[] {
    return orderBy(
      this.teammates.filter((item) => item.role === type),
      'createdAt',
      ['desc'],
    );
  }
}
