import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import FilterComponent from 'uplisting-frontend/pods/components/ui/filters/filter';
import { type SingleSelectFilter } from 'uplisting-frontend/utils/filters/types';

export default class UiFiltersFilterSingleSelectComponent extends FilterComponent<SingleSelectFilter> {
  @cached
  get title(): string | undefined {
    return this.filter.params.value;
  }

  @action
  handleChange(data: string | null): void {
    if (data) {
      this.filter.updateValue(data);
    } else {
      this.filter.reset();
    }

    this.args.onFilterUpdate();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ui/filters/filter/SingleSelect': typeof UiFiltersFilterSingleSelectComponent;
    'Ui::Filters::Filter::SingleSelect': typeof UiFiltersFilterSingleSelectComponent;
  }
}
