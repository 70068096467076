import BookingModel from 'uplisting-frontend/models/booking';

export enum BookingRentalAgreementStatus {
  pending = 'pending',
  processing = 'processing',
  processed = 'processed',
  markedAsSigned = 'marked_as_signed',
}

export interface IBookingRentalAgreementSchema {
  fileLink: string;
  status: BookingRentalAgreementStatus;

  booking: BookingModel;
}
