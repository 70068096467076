import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import PropertyModel from 'uplisting-frontend/models/property';
import PromotionModel, {
  PromotionType,
} from 'uplisting-frontend/models/promotion';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { isAlreadyConnectedError } from 'uplisting-frontend/utils';
import { FilterId } from 'uplisting-frontend/utils/filters/abstract';
import { PropertyFilter } from 'uplisting-frontend/utils/filters/types';

interface IArgs {
  promotion: PromotionModel;
  onClose(): void;
}

interface DirectPromotionFormSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiDirectPromotionFormComponent extends Component<DirectPromotionFormSignature> {
  @service analytics!: Services['analytics'];
  @service notifications!: Services['notifications'];
  @service('filters') filtersService!: Services['filters'];
  @service('repositories/promotion')
  promotionRepository!: Services['repositories/promotion'];

  @cached @tracked showArchiveModal = false;
  @cached @tracked filters: PropertyFilter[] = [];

  promotionTypeOptions = [PromotionType.percentage, PromotionType.fixed];

  @cached
  get promotion(): PromotionModel {
    return this.args.promotion;
  }

  @cached
  get changeset(): GenericChangeset<PromotionModel> {
    return this.promotionRepository.buildChangeset(this.promotion);
  }

  @cached
  get isFixedPromotionType(): boolean {
    return this.changeset.promotionType === PromotionType.fixed;
  }

  @action
  handleSetupFilters(): void {
    const ids = this.changeset.properties.map((property) => property.id);

    this.filters = [
      this.filtersService.createFilterFor(
        FilterId.property,
        ids,
      ) as PropertyFilter,
    ];
  }

  @action
  handleCancel() {
    this.changeset.rollback();
    this.args.onClose();
  }

  @action
  handleFilterUpdate(filter: PropertyFilter): void {
    const properties = filter.params.selectedRecords as PropertyModel[];

    this.changeset.properties = properties;
  }

  @action
  handleChangePromotionType(promotionType: PromotionType): void {
    // swap amounts to not lose input value
    if (promotionType === PromotionType.fixed) {
      this.changeset.flatAmount = this.changeset.percentageAmount;
      this.changeset.percentageAmount = 0;
    } else if (promotionType === PromotionType.percentage) {
      this.changeset.percentageAmount = this.changeset.flatAmount;
      this.changeset.flatAmount = 0;
    }

    this.changeset.promotionType = promotionType;
  }

  @action
  async handleArchivePromotion(): Promise<void> {
    this.changeset.enabled = false;

    await this.handleFormSubmit();

    this.args.onClose();
  }

  @action
  async handleFormSubmit() {
    try {
      await this.changeset.save();

      this.analytics.trackEvent(AnalyticsEvents.promotionEdited);
      this.notifications.info();

      this.args.onClose();
    } catch (e) {
      const message = isAlreadyConnectedError(e)
        ? 'direct_promotion_form.notifications.error'
        : undefined;

      this.notifications.error(message);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Direct::Promotion::Form': typeof UiDirectPromotionFormComponent;
  }
}
