import Model, { attr, belongsTo } from '@ember-data/model';
import { type ISecurityDepositActionSchema } from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';

export default class SecurityDepositActionModel
  extends Model
  implements ISecurityDepositActionSchema
{
  @attr('boolean') overridden!: boolean;

  @belongsTo('booking', { inverse: 'securityDepositAction', async: false })
  booking!: BookingModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'security-deposit-action': SecurityDepositActionModel;
  }
}
