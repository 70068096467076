import templateOnly from '@ember/component/template-only';

interface IArgs {
  text?: string;
}

interface EmptyComponentSignature {
  Element: null;

  Args: IArgs;

  Blocks: {
    default: [];
  };
}

const UiEmptyComponent = templateOnly<EmptyComponentSignature>();

export default UiEmptyComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'empty-component': typeof UiEmptyComponent;
  }
}
