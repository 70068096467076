import type Transition from '@ember/routing/transition';
import { hash } from 'rsvp';
import { type Registry as Services, inject as service } from '@ember/service';
import BaseRoute from 'uplisting-frontend/pods/base/route';
import ActionBookingsMessagesController from 'uplisting-frontend/pods/action/bookings/messages/controller';
import BookingModel from 'uplisting-frontend/models/booking';
import ContextualReplyModel from 'uplisting-frontend/models/contextual-reply';

interface IParams {
  replyId?: string;
}

interface IModel {
  booking: BookingModel;
  reply?: Promise<ContextualReplyModel>;
}

export default class ActionBookingsDetailsRoute extends BaseRoute {
  @service('repositories/contextual-reply')
  contextualReplyRepository!: Services['repositories/contextual-reply'];

  model(params: IParams) {
    const data: IModel = {
      booking: this.modelFor('action.bookings') as BookingModel,
    };

    if (params.replyId) {
      data.reply = this.contextualReplyRepository.find(
        params.replyId,
        data.booking.id,
      );
    }

    return hash(data);
  }

  setupController(
    controller: ActionBookingsMessagesController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, model);
  }
}
