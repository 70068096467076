import { type Registry as Services, inject as service } from '@ember/service';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';

export default class ReportsRoute extends PermittedRoute {
  @service features!: Services['features'];

  @service('repositories/advanced-report-configuration')
  advancedReportConfigurationRepository!: Services['repositories/advanced-report-configuration'];

  permission = 'advanced_report_configurations.index';

  model() {
    if (!this.features.isAdvancedReportingEnabledOnAccount) {
      return;
    }

    return this.advancedReportConfigurationRepository.findAll();
  }
}
