import escapeRegExp from 'lodash-es/escapeRegExp';
import { isBlank } from '@ember/utils';
import { get } from '@ember/object';
import I18nHelper from 'uplisting-frontend/helpers/i18n';

export function searchOptions<T>(
  content: T[],
  inputValue: string,
  searchBy?: string | string[],
): T[] {
  if (isBlank(inputValue)) {
    return content;
  }

  const escaped = escapeRegExp(inputValue);
  const regexp = new RegExp(escaped, 'i');

  return content.filter((item) => {
    if (Array.isArray(searchBy)) {
      return searchBy.some((field) => {
        const value = I18nHelper.translator.compute([
          field,
          get(item, field) as string,
        ]);

        return regexp.test(value);
      });
    }

    if (searchBy) {
      const value = I18nHelper.translator.compute([searchBy, item[searchBy]]);

      return regexp.test(value);
    }

    return regexp.test(item as string);
  });
}
