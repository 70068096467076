import Model, { attr, belongsTo } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import BookingPaymentModel from 'uplisting-frontend/models/booking-payment';
import {
  type IPaymentSchema,
  PaymentStatus,
} from 'uplisting-frontend/models/schemas';

const PAID_STATUSES = [PaymentStatus.charged, PaymentStatus.markedAsPaid];

export default class PaymentModel extends Model implements IPaymentSchema {
  @attr('date') dueAt!: Date;
  @attr('string') currency!: string;
  @attr('number') amount!: number;
  @attr('string') status!: PaymentStatus;
  @attr('string') lastErrorMessage!: string;

  @belongsTo('booking-payment', { async: false, inverse: 'payments' })
  bookingPayment!: BookingPaymentModel;

  @cached
  get isPaid(): boolean {
    return PAID_STATUSES.includes(this.status);
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    payment: PaymentModel;
  }
}
