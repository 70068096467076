import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import { debounceTask } from 'ember-lifeline';
import BaseController from 'uplisting-frontend/pods/base/controller';
import UpsellOrderModel from 'uplisting-frontend/models/upsell-order';
import { FilterId } from 'uplisting-frontend/utils/filters/abstract';
import {
  type UpsellOrdersFilter,
  getFilterQueryParamName,
} from 'uplisting-frontend/utils/filters/types';
import { UpsellOrderStatus } from 'uplisting-frontend/models/schemas';

enum UpsellOrderAction {
  approve,
  reject,
  markAsRefunded,
  markAsFulfilled,
  viewDetails,
  vewBookingDetails,
}

interface IUpsellOrderAction {
  id: UpsellOrderAction;
  text: string;
}

export default class UpsellsIndexController extends BaseController {
  @service('filters') filtersService!: Services['filters'];
  @service('repositories/upsell-order')
  upsellOrderRepository!: Services['repositories/upsell-order'];

  queryParams = ['propertyIds', 'nickname', 'guestName', 'status'];

  upsellOrderToChange?: UpsellOrderModel;

  @cached @tracked isLoading = false;
  @cached @tracked willFetchData = false;
  @cached @tracked showChangeOrderStatusModal = false;
  @cached @tracked upsellOrderNewStatus?: UpsellOrderStatus;

  @cached @tracked propertyIds: string[] = [];
  @cached @tracked nickname = '';
  @cached @tracked guestName = '';
  @cached @tracked status?: UpsellOrderStatus;

  @cached @tracked data: UpsellOrderModel[] = [];

  @cached
  get filters(): UpsellOrdersFilter[] {
    const { filtersService } = this;

    return [
      filtersService.createFilterFor(FilterId.property, this.propertyIds),
      filtersService.createFilterFor(FilterId.upsellOrderStatus, this.status),
    ] as UpsellOrdersFilter[];
  }

  @cached
  get requestedUpsellOrderActions(): IUpsellOrderAction[] {
    const { intl } = this;

    return [
      {
        id: UpsellOrderAction.approve,
        text: intl.t('upsell_orders.actions.approve'),
      },
      {
        id: UpsellOrderAction.reject,
        text: intl.t('upsell_orders.actions.reject'),
      },
    ];
  }

  @cached
  get fulfilledUpsellOrderActions(): IUpsellOrderAction[] {
    const { intl } = this;

    return [
      {
        id: UpsellOrderAction.markAsRefunded,
        text: intl.t('upsell_orders.actions.mark_as_refunded'),
      },
    ];
  }

  @cached
  get paidUpsellOrderActions(): IUpsellOrderAction[] {
    const { intl } = this;

    return [
      {
        id: UpsellOrderAction.markAsFulfilled,
        text: intl.t('upsell_orders.actions.mark_as_fulfilled'),
      },
      {
        id: UpsellOrderAction.markAsRefunded,
        text: intl.t('upsell_orders.actions.mark_as_refunded'),
      },
    ];
  }

  @cached
  get upsellOrdersPresent(): boolean {
    return this.data.length !== 0;
  }

  @cached
  get queryParamsEmpty(): boolean {
    return (
      this.propertyIds.length === 0 &&
      !this.nickname &&
      !this.guestName &&
      !this.status
    );
  }

  @cached
  get showEmptyState(): boolean {
    return (
      !this.isLoading &&
      !this.willFetchData &&
      !this.upsellOrdersPresent &&
      this.queryParamsEmpty
    );
  }

  @cached
  get modalTitle(): string {
    const { upsellOrderNewStatus } = this;

    if (!upsellOrderNewStatus) {
      return '';
    }

    const { intl } = this;

    const status = intl.t(
      `upsell_orders.statuses.${this.upsellOrderNewStatus}`,
    );

    return intl.t('upsell_orders.modal.title', { status });
  }

  @action
  handleResetFilters(): void {
    this.filters.forEach((filter) => {
      filter.reset();

      this.handleFilterUpdate(filter);
    });

    this.nickname = '';
    this.guestName = '';
  }

  @action
  handleFilterUpdate(filter: UpsellOrdersFilter): void {
    const queryParamField = getFilterQueryParamName(filter);

    this[queryParamField] = filter.toQuery();
  }

  @action
  handleInput(field: 'nickname' | 'guestName', event: Event): void {
    this[field] = (event.target as HTMLInputElement).value;

    const { length } = this[field];

    const shouldFetchData = length === 0 || length >= 3;

    if (shouldFetchData) {
      this.willFetchData = true;
      debounceTask(this, 'handleFetchData', 500);
    }
  }

  @action
  async handleFetchData(): Promise<void> {
    this.isLoading = true;

    const data = await this.upsellOrderRepository.query({
      property_ids: this.propertyIds,
      upsell_nickname: this.getFieldValueForSearch('nickname'),
      guest_name: this.getFieldValueForSearch('guestName'),
      statuses: this.status ? [this.status] : [],
    });

    this.isLoading = false;
    this.willFetchData = false;

    this.data = data.slice();
  }

  @action
  computeUpsellOrderActions(order: UpsellOrderModel): IUpsellOrderAction[] {
    const { intl } = this;
    const actionsForOrder = this.getActionsForOrderStatus(order.status);

    return [
      ...actionsForOrder,
      {
        id: UpsellOrderAction.viewDetails,
        text: intl.t('upsell_orders.actions.view_upsell_details'),
      },
      {
        id: UpsellOrderAction.vewBookingDetails,
        text: intl.t('upsell_orders.actions.view_booking_details'),
      },
    ];
  }

  @action
  async handleSelectUpsellOrderAction(
    order: UpsellOrderModel,
    action: IUpsellOrderAction,
  ): Promise<void> {
    switch (action.id) {
      case UpsellOrderAction.approve:
        return this.showOrderStatusChangeModalFor(
          order,
          UpsellOrderStatus.paid,
        );
      case UpsellOrderAction.reject:
        return this.showOrderStatusChangeModalFor(
          order,
          UpsellOrderStatus.rejected,
        );
      case UpsellOrderAction.markAsRefunded:
        return this.showOrderStatusChangeModalFor(
          order,
          UpsellOrderStatus.refunded,
        );
      case UpsellOrderAction.markAsFulfilled:
        return this.showOrderStatusChangeModalFor(
          order,
          UpsellOrderStatus.fulfilled,
        );
      case UpsellOrderAction.viewDetails:
        window.open(`/upsells/${order.upsell.id}`, '_blank');
        return;
      case UpsellOrderAction.vewBookingDetails:
        window.open(`/calendar/bookings/${order.booking.id}/summary`, '_blank');
    }
  }

  @action
  async handleChangeOrderStatus(): Promise<void> {
    const order = this.upsellOrderToChange as UpsellOrderModel;
    const status = this.upsellOrderNewStatus as UpsellOrderStatus;
    const prevStatus = order.status;

    order.status = status;

    try {
      await order.save();

      this.notifications.info();
    } catch {
      order.status = prevStatus;
      this.notifications.error();
    } finally {
      this.handleCancelChangeOrderStatus();
    }
  }

  @action
  handleCancelChangeOrderStatus(): void {
    this.upsellOrderToChange = undefined;
    this.upsellOrderNewStatus = undefined;
    this.showChangeOrderStatusModal = false;
  }

  private showOrderStatusChangeModalFor(
    order: UpsellOrderModel,
    status: UpsellOrderStatus,
  ): void {
    this.upsellOrderToChange = order;
    this.upsellOrderNewStatus = status;
    this.showChangeOrderStatusModal = true;
  }

  private getActionsForOrderStatus(
    status: UpsellOrderStatus,
  ): IUpsellOrderAction[] {
    switch (status) {
      case UpsellOrderStatus.requested:
        return this.requestedUpsellOrderActions;
      case UpsellOrderStatus.fulfilled:
        return this.fulfilledUpsellOrderActions;
      case UpsellOrderStatus.paid:
        return this.paidUpsellOrderActions;
      default:
        return [];
    }
  }

  private getFieldValueForSearch(field: 'nickname' | 'guestName'): string {
    if (this[field].length >= 3) {
      return this[field];
    }

    return '';
  }

  public resetState(): void {
    this.propertyIds = [];
    this.nickname = '';
    this.guestName = '';
    this.status = undefined;
  }
}
