import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { isEmpty, isPresent } from '@ember/utils';
import { type Registry as Services, inject as service } from '@ember/service';
import BookingModel from 'uplisting-frontend/models/booking';

interface IArgs {
  booking: BookingModel;
}

export interface BookingGuestCardSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiBookingGuestCardComponent extends Component<BookingGuestCardSignature> {
  @service intl!: Services['intl'];
  @service session!: Services['session'];

  dateFormat = 'EEE, MMM do';

  @cached
  get booking(): BookingModel {
    return this.args.booking;
  }

  @cached
  get guestName(): string {
    return this.booking.guestName;
  }

  @cached
  get canPriceBeFormatted(): boolean {
    return typeof this.price === 'number';
  }

  @cached
  get price(): number | null | undefined {
    const { price } = this.booking;

    if (!price) {
      return;
    }

    const { guestPrice, hostEarnings } = price;

    if (isEmpty(hostEarnings) && (isEmpty(guestPrice) || guestPrice === 0)) {
      return;
    }

    return isPresent(hostEarnings) ? hostEarnings : guestPrice;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Guest::Card': typeof UiBookingGuestCardComponent;
  }
}
