import { cached, tracked } from '@glimmer/tracking';
import BaseRepositoryService, {
  type GenericChangeset,
} from 'uplisting-frontend/services/repositories/base';
import ChannelCommissionModel from 'uplisting-frontend/models/channel-commission';
import { ChannelCommissionValidation } from 'uplisting-frontend/validations';
import { ChannelCommissionChannel } from 'uplisting-frontend/models/schemas';

const amountPerChannel = {
  [ChannelCommissionChannel.bookingDotCom]: 15,
  [ChannelCommissionChannel.airbnbOfficial]: 15,
  [ChannelCommissionChannel.homeAway]: 8,
  [ChannelCommissionChannel.google]: 6,
  [ChannelCommissionChannel.direct]: 0,
};

export default class ChannelCommissionRepositoryService extends BaseRepositoryService<ChannelCommissionModel> {
  recordName = 'channel-commission';
  implementMethods = [
    'findAll',
    'unloadAll',
    'createRecord',
    'buildChangeset',
    'peekAll',
  ];

  validation = ChannelCommissionValidation;

  @cached
  @tracked
  bookingDotComChangeset!: GenericChangeset<ChannelCommissionModel>;

  @cached
  @tracked
  airbnbOfficialChangeset!: GenericChangeset<ChannelCommissionModel>;

  @cached @tracked homeAwayChangeset!: GenericChangeset<ChannelCommissionModel>;
  @cached @tracked googleChangeset!: GenericChangeset<ChannelCommissionModel>;
  @cached @tracked directChangeset!: GenericChangeset<ChannelCommissionModel>;

  public setDefaultRecords(): void {
    const commissions = this.peekAll();

    this.bookingDotComChangeset = this.changesetFor(
      commissions,
      ChannelCommissionChannel.bookingDotCom,
    );

    this.airbnbOfficialChangeset = this.changesetFor(
      commissions,
      ChannelCommissionChannel.airbnbOfficial,
    );

    this.homeAwayChangeset = this.changesetFor(
      commissions,
      ChannelCommissionChannel.homeAway,
    );

    this.googleChangeset = this.changesetFor(
      commissions,
      ChannelCommissionChannel.google,
    );

    this.directChangeset = this.changesetFor(
      commissions,
      ChannelCommissionChannel.direct,
    );
  }

  public changesetFor(
    commissions: ChannelCommissionModel[],
    channel: ChannelCommissionChannel,
  ): GenericChangeset<ChannelCommissionModel> {
    const commission = commissions.find((item) => item.channel === channel);

    return this.buildChangeset(
      commission ||
        this.createRecord({
          channel,
          amount: amountPerChannel[channel],
        }),
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    'repositories/channel-commission': ChannelCommissionRepositoryService;
  }
}
