import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import { InviteUserStatus } from 'uplisting-frontend/pods/clients/client/users/controller';
import UserModel from 'uplisting-frontend/models/user';
import ClientModel from 'uplisting-frontend/models/client';
import InvitationModel from 'uplisting-frontend/models/invitation';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';

interface IArgs {
  open: boolean;
  status: InviteUserStatus;
  user: UserModel | undefined;
  client: ClientModel;
  invitation: GenericChangeset<InvitationModel>;

  onSubmit(): void;
  onCancel(): void;
}

interface ModalInviteUserSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiIModalInviteUserComponent extends Component<ModalInviteUserSignature> {
  @cached
  get status(): InviteUserStatus {
    return this.args.status;
  }

  @cached
  get userIsNew(): boolean {
    return this.status === InviteUserStatus.newUser;
  }

  @cached
  get userHasClient(): boolean {
    return this.status === InviteUserStatus.hasClient;
  }

  @cached
  get userIsPresent(): boolean {
    return this.status === InviteUserStatus.userPresent;
  }

  @cached
  get user(): UserModel | undefined {
    return this.args.user;
  }

  @cached
  get client(): ClientModel {
    return this.args.client;
  }

  @cached
  get invitation(): GenericChangeset<InvitationModel> {
    return this.args.invitation;
  }

  @action
  async handleLinkClient(): Promise<void> {
    this.client.users.push(this.user as UserModel);

    (this.user as UserModel).client = this.client;

    await this.client.save();

    this.args.onSubmit();
  }

  @action
  async handleSendInvitation(): Promise<void> {
    await this.invitation.save();

    this.args.onSubmit();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Modal::InviteUser': typeof UiIModalInviteUserComponent;
  }
}
