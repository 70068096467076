import Component from '@glimmer/component';
import { action } from '@ember/object';
import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import uniq from 'lodash-es/uniq';
import { toggleValue } from 'uplisting-frontend/utils';
import PropertyModel from 'uplisting-frontend/models/property';
import {
  OccasionEvent,
  OccasionPreposition,
  OccasionTermUnit,
} from 'uplisting-frontend/models/schemas';
import UpsellModel from 'uplisting-frontend/models/upsell';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import ImageUploadComponent from 'uplisting-frontend/pods/components/ui/image-upload/component';
import { type IOccasionFilterSectionItem } from 'uplisting-frontend/services/occasions';
import {
  type OccasionFilter,
  OccasionFilterGroup,
} from 'uplisting-frontend/utils/occasion-filters';

interface IArgs {
  changeset: GenericChangeset<UpsellModel>;
  filterGroup: OccasionFilterGroup;
  properties: PropertyModel[];
  isEditable?: boolean;
  submitText: string;
  onCancel(): void;
  onSave(): Promise<void>;
}

interface UpsellFormSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

interface IOption {
  name: string;
  disabled: boolean;
}

function computeOptionDisabled(
  event: OccasionEvent,
  preposition: OccasionPreposition,
): boolean {
  return (
    (event === OccasionEvent.booked &&
      preposition === OccasionPreposition.before) ||
    (event === OccasionEvent.departed &&
      preposition === OccasionPreposition.after)
  );
}

export default class UiUpsellFormComponent extends Component<UpsellFormSignature> {
  @service('occasions') occasionsService!: Services['occasions'];

  @service('repositories/tax') taxRepository!: Services['repositories/tax'];

  imageUploadComponent!: ImageUploadComponent;

  editableFilterIndex?: number;

  @cached @tracked editableFilter?: OccasionFilter;

  @cached
  get changeset(): GenericChangeset<UpsellModel> {
    return this.args.changeset;
  }

  @cached
  get termUnitOptions(): string[] {
    return Object.keys(OccasionTermUnit);
  }

  @cached
  get currencyOptions(): string[] {
    return uniq(this.args.properties.map((property) => property.currency));
  }

  @cached
  get filterGroup(): OccasionFilterGroup {
    return this.args.filterGroup;
  }

  @cached
  get occasionFilterSections(): IOccasionFilterSectionItem[] {
    return this.occasionsService.occasionReportFilters;
  }

  @action
  handleAddFilter(filter: OccasionFilter): void {
    this.filterGroup.values = [...this.filterGroup.values, filter];
  }

  @action
  handleChangeFilter(filter: OccasionFilter): void {
    this.filterGroup.values[this.editableFilterIndex as number] = filter;
    this.filterGroup.values = [...this.filterGroup.values];
  }

  @action
  handleDeleteFilter(filter: OccasionFilter): void {
    toggleValue(this.filterGroup, 'values', filter);
  }

  @action
  handleEditFilter(filter: OccasionFilter): void {
    this.editableFilter = this.occasionsService.clone(filter);
    this.editableFilterIndex = this.filterGroup.values.indexOf(filter);
  }

  @action
  shouldShowFilterOperator(item: OccasionFilter): boolean {
    return this.filterGroup.values[this.filterGroup.values.length - 1] !== item;
  }

  @action
  computePrepositionOptionsFor(field: 'from' | 'to'): IOption[] {
    const { event } = this.changeset[field];

    return (Object.keys(OccasionPreposition) as OccasionPreposition[]).map(
      (item) => ({
        name: item,
        disabled: computeOptionDisabled(event, item),
      }),
    );
  }

  @action
  computeTriggerEventOptionsFor(field: 'from' | 'to'): IOption[] {
    const { preposition } = this.changeset[field];

    return [
      OccasionEvent.booked,
      OccasionEvent.arrived,
      OccasionEvent.departed,
    ].map((item) => ({
      name: item,
      disabled: computeOptionDisabled(item, preposition),
    }));
  }

  @action
  handleOptionSelect(
    option: IOption,
    changeset: GenericChangeset<UpsellModel>,
    key: string,
  ): void {
    if (option.disabled) {
      return;
    }

    changeset[key] = option.name;
  }

  @action
  handleCurrencySelect(currency: string): void {
    if (this.changeset.currency === currency) {
      return;
    }

    const { properties } = this.changeset;

    properties.splice(0, properties.length);

    this.changeset.currency = currency;
  }

  @action
  handleInitImageUpload(component: ImageUploadComponent): void {
    this.imageUploadComponent = component;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Upsell::Form': typeof UiUpsellFormComponent;
  }
}
