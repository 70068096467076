import { type Registry as Services, inject as service } from '@ember/service';
import { hash } from 'rsvp';
import type Transition from '@ember/routing/transition';
import AutomateReviewsController from 'uplisting-frontend/pods/automate/reviews/controller';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';

export default class AutomateReviewsRoute extends PermittedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/custom-message-tag')
  customMessageTagRepository!: Services['repositories/custom-message-tag'];

  @service('repositories/custom-property-attribute')
  customPropertyAttributeRepository!: Services['repositories/custom-property-attribute'];

  @service('repositories/automated-review-template')
  automatedReviewTemplateRepository!: Services['repositories/automated-review-template'];

  permission = 'automate.reviews';

  model() {
    return hash({
      properties: this.propertyRepository.findAll(),
      customMessageTags: this.customMessageTagRepository.findAll(),
      customPropertyAttributes:
        this.customPropertyAttributeRepository.findAll(),
      automatedReviewTemplates:
        this.automatedReviewTemplateRepository.findAll(),
    });
  }

  setupController(
    controller: AutomateReviewsController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, model);
  }
}
