import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import { modifier } from 'ember-modifier';
import { type IDropdown } from 'ember-bootstrap/components/bs-dropdown';
import SeamLockableModel from 'uplisting-frontend/models/seam-lockable';
import { type LockOption } from 'uplisting-frontend/pods/components/ui/connections/index';
import PropertyModel from 'uplisting-frontend/models/property';
import { searchOptions } from 'uplisting-frontend/utils';

interface IArgs {
  lockable: SeamLockableModel;
  lockOptions: LockOption[];
  onLockPropertySelect(lockable: SeamLockableModel): Promise<void>;
}

interface ConnectionsSeamLockableRowSignature {
  Element: null;

  Args: IArgs;
}

export default class UiConnectionsSeamLockableRowComponent extends Component<ConnectionsSeamLockableRowSignature> {
  @service('repositories/seam-lockable')
  seamLockableRepositoryService!: Services['repositories/seam-lockable'];

  @cached @tracked inputValue = '';
  @cached @tracked isSubmitting = false;
  @cached @tracked lockableCopy?: SeamLockableModel;

  @cached
  get lockable(): SeamLockableModel {
    return this.args.lockable;
  }

  @cached
  get selectedItemsText(): string {
    return [
      ...this.lockable.properties.slice(),
      ...this.lockable.multiUnits.slice(),
    ]
      .map((item) => item.searchName)
      .join(', ');
  }

  @cached
  get filteredItems(): LockOption[] {
    return searchOptions<LockOption>(
      this.args.lockOptions,
      this.inputValue,
      'searchName',
    );
  }

  @action
  handleInputSearch(value: string): void {
    this.inputValue = value;
  }

  @action
  isOptionSelected(option: LockOption): boolean {
    if (option instanceof PropertyModel) {
      return !!this.lockableCopy?.properties.includes(option);
    }

    return !!this.lockableCopy?.multiUnits.includes(option);
  }

  @action
  handleLockPropertySelect(option: LockOption): void {
    (this.lockableCopy as SeamLockableModel).toggleOption(option);
  }

  @action
  async handleApplyChanges(dd: IDropdown): Promise<void> {
    this.isSubmitting = true;

    const copy = this.lockableCopy as SeamLockableModel;

    this.lockable.multiUnits = copy.multiUnits;
    this.lockable.properties = copy.properties;

    await this.args.onLockPropertySelect(this.lockable);

    this.isSubmitting = false;

    dd.closeDropdown();
  }

  @action
  handleDropdownHide(): void {
    (this.lockableCopy as SeamLockableModel).unloadRecord();
    this.lockableCopy = undefined;
  }

  computeLockableCopy = modifier(() => {
    this.lockableCopy = this.seamLockableRepositoryService.createRecord();

    this.lockableCopy.multiUnits = this.lockable.multiUnits;
    this.lockableCopy.properties = this.lockable.properties;
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Connections::Seam::LockableRow': typeof UiConnectionsSeamLockableRowComponent;
  }
}
