import Model, { attr, belongsTo } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { toZonedTime } from 'date-fns-tz';
import {
  type IChangeoverSchema,
  ChangeoverStatus,
} from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';
import PropertyModel from 'uplisting-frontend/models/property';
import CalendarModel from 'uplisting-frontend/models/calendar';
import TeammateModel from 'uplisting-frontend/models/teammate';

export default class ChangeoverModel
  extends Model
  implements IChangeoverSchema
{
  @attr('string') persistedTeammateId?: string;
  @attr('string') cleanerName!: string;
  @attr('string') hostNote!: string;
  @attr('string') status!: ChangeoverStatus;
  @attr('date') scheduledAt!: Date;

  @cached
  get scheduledAtInUTC(): Date {
    return toZonedTime(this.scheduledAt, 'Etc/UTC');
  }

  @cached
  get displayName(): string {
    return this.booking?.displayName ?? '';
  }

  @cached
  get isPending(): boolean {
    return this.status === ChangeoverStatus.pending;
  }

  @cached
  get isAccepted(): boolean {
    return this.status === ChangeoverStatus.accepted;
  }

  @cached
  get isStarted(): boolean {
    return this.status === ChangeoverStatus.started;
  }

  @cached
  get isFinished(): boolean {
    return this.status === ChangeoverStatus.finished;
  }

  @belongsTo('booking', { inverse: null, async: false })
  booking!: BookingModel;

  @belongsTo('property', { inverse: null, async: false })
  property!: PropertyModel;

  @belongsTo('calendar', { inverse: 'changeovers', async: false })
  calendar!: CalendarModel;

  @belongsTo('teammate', { inverse: null, async: false })
  teammate?: TeammateModel;

  @belongsTo('booking', { inverse: null, async: false })
  nextBooking?: BookingModel;

  @cached
  get persistedTeammate(): TeammateModel | undefined {
    if (this.persistedTeammateId) {
      return this.store.peekRecord('teammate', this.persistedTeammateId);
    }

    return this.teammate;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    changeover: ChangeoverModel;
  }
}
