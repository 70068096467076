import { type Registry as Services, inject as service } from '@ember/service';
import DS from 'ember-data';
import { cached, tracked } from '@glimmer/tracking';
import DirectBookingConfigurationModel, {
  CUSTOM_SUBDOMAIN_OPTIONS,
} from 'uplisting-frontend/models/direct-booking-configuration';
import BaseRepositoryService, {
  type GenericChangeset,
} from 'uplisting-frontend/services/repositories/base';
import { DirectBookingConfigurationValidation } from 'uplisting-frontend/validations';

export default class DirectBookingConfigurationRepositoryService extends BaseRepositoryService<DirectBookingConfigurationModel> {
  @service('repositories/account-configuration')
  accountConfigurationRepository!: Services['repositories/account-configuration'];

  recordName = 'direct-booking-configuration';
  implementMethods = ['findRecord', 'createRecord', 'buildChangeset'];

  validation = DirectBookingConfigurationValidation;

  @cached @tracked currentConfiguration!: DirectBookingConfigurationModel;

  @cached
  get currentChangeset(): GenericChangeset<DirectBookingConfigurationModel> {
    return this.buildChangeset(this.currentConfiguration);
  }

  public async getDefaultRecord(): Promise<
    DS.PromiseObject<DirectBookingConfigurationModel>
  > {
    let record;

    try {
      record = await this.findRecord('me');
    } catch {
      record = this.createDefaultRecord();
    }

    this.currentConfiguration = record;

    return record;
  }

  public createDefaultRecord(): DirectBookingConfigurationModel {
    const accountConfiguration =
      this.accountConfigurationRepository.currentAccountConfiguration;

    let { displayName } = accountConfiguration;

    if (displayName) {
      displayName = displayName.slice(0, 29);
    }

    const record = this.createRecord({
      heroHeading: displayName,
      customSubdomainOption: CUSTOM_SUBDOMAIN_OPTIONS[0],
    });

    this.currentConfiguration = record;

    return record;
  }
}

declare module '@ember/service' {
  interface Registry {
    'repositories/direct-booking-configuration': DirectBookingConfigurationRepositoryService;
  }
}
