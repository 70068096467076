import Model, { attr } from '@ember-data/model';
import { type IPartnerApiKeySchema } from 'uplisting-frontend/models/schemas';

export default class PartnerApiKeyModel
  extends Model
  implements IPartnerApiKeySchema
{
  @attr('string') apiKey!: string;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'partner-api-key': PartnerApiKeyModel;
  }
}
