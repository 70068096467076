import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import { isAfter } from 'date-fns/isAfter';
import { isBefore } from 'date-fns/isBefore';
import { modifier } from 'ember-modifier';
import { formatDateQuery } from 'uplisting-frontend/utils';
import { type OccasionDateFilter } from 'uplisting-frontend/utils/occasion-filters';

interface IArgs {
  filter: OccasionDateFilter;
}

interface ReportFiltersOptionsSignature {
  Element: HTMLUListElement;

  Args: IArgs;
}

enum DateOption {
  predefined,
  custom,
}

interface IDateOption {
  id: DateOption;
  text: string;
}

export default class UiReportFiltersOptionsDateComponent extends Component<ReportFiltersOptionsSignature> {
  @service intl!: Services['intl'];

  @cached @tracked activeDateOption!: IDateOption;

  @cached @tracked startDate = new Date();
  @cached @tracked endDate: Date | '-' = new Date();

  @cached
  get filter(): OccasionDateFilter {
    return this.args.filter;
  }

  @cached
  get dateTypeOptions(): IDateOption[] {
    const { intl } = this;

    return [
      {
        id: DateOption.predefined,
        text: intl.t('date_options.period'),
      },
      {
        id: DateOption.custom,
        text: intl.t('date_options.range'),
      },
    ];
  }

  @cached
  get isDatePeriodSelected(): boolean {
    return this.activeDateOption === this.dateTypeOptions[0];
  }

  @action
  handleOptionSelect(option: string): void {
    this.filter.updateValues(option);
  }

  @action
  handleDateOptionChange(option: IDateOption): void {
    this.activeDateOption = option;

    if (this.activeDateOption === this.dateTypeOptions[1]) {
      this.setRangeValues();
    } else {
      this.handleOptionSelect(this.filter.optionsList[0] as string);
    }
  }

  @action
  handleDateSelect(type: 'startDate' | 'endDate', date: Date): void {
    if (type === 'startDate') {
      if (this.endDate instanceof Date && isAfter(date, this.endDate)) {
        this.endDate = '-';
      }
    } else if (type === 'endDate') {
      if (isBefore(date, this.startDate)) {
        return;
      }
    }

    this[type] = date;

    this.setRangeValues();
  }

  handleInsert = modifier(() => {
    const values = this.filter.values[0] as string;

    if (this.filter.optionsList.includes(values)) {
      this.activeDateOption = this.dateTypeOptions[0] as IDateOption;
    } else {
      this.activeDateOption = this.dateTypeOptions[1] as IDateOption;

      const [startDate, endDate] = values.split(':') as [string, string];

      this.startDate = new Date(startDate);
      this.endDate = new Date(endDate);
    }
  });

  private setRangeValues(): void {
    const { startDate, endDate } = this;

    if (startDate instanceof Date && endDate instanceof Date) {
      this.handleOptionSelect(
        `${formatDateQuery(startDate)}:${formatDateQuery(endDate)}`,
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::ReportFilters::Options::Date': typeof UiReportFiltersOptionsDateComponent;
  }
}
