import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import ClientStatementAccountDetailModel from 'uplisting-frontend/models/client-statement-account-detail';
import { isSaveDisabled } from 'uplisting-frontend/utils';

interface IArgs {
  changeset: GenericChangeset<ClientStatementAccountDetailModel>;
}

export interface AccountDetailSignature {
  Element: HTMLFormElement;

  Args: IArgs;
}

export default class UiAccountDetailComponent extends Component<AccountDetailSignature> {
  @service notifications!: Services['notifications'];

  @cached
  get changeset(): GenericChangeset<ClientStatementAccountDetailModel> {
    return this.args.changeset;
  }

  @action
  async handleFormSubmit(): Promise<void> {
    const saveDisabled = await isSaveDisabled(this.changeset);

    if (saveDisabled) {
      return;
    }

    try {
      await this.changeset.save();

      this.notifications.info();
    } catch {
      this.notifications.error();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::AccountDetail': typeof UiAccountDetailComponent;
  }
}
