import { cached, tracked } from '@glimmer/tracking';
import Model from '@ember-data/model';
import { dasherize } from '@ember/string';
import { singularize } from 'ember-inflector';

export class HasManyState<T extends Model, K extends Model> {
  @cached @tracked ids!: string[];
  @cached @tracked originalRecords!: K[];

  model!: T;
  hasManyKey!: keyof T;

  constructor(model: T, hasManyKey: keyof T) {
    this.model = model;

    this.hasManyKey = hasManyKey;

    this.setInitialData();
  }

  public hasChanges(data: K[]): boolean {
    const newIds = this.getIds(data);

    return !(
      newIds.length === this.ids.length &&
      this.ids.every((id) => newIds.includes(id))
    );
  }

  public applyChanges(data: K[]): void {
    this.ids = this.getIds(data);
  }

  private setInitialData(): void {
    this.setIds();
    this.setRecords();
  }

  private setIds(): void {
    this.ids = this.getIds(this.model[this.hasManyKey] as K[]);
  }

  private setRecords(): void {
    const normalizedModelName = dasherize(
      singularize(this.hasManyKey as string),
    );
    const { store } = this.model;

    this.originalRecords = this.ids.map(
      (id) => store.peekRecord(normalizedModelName, id) as K,
    );
  }

  private getIds(data: K[]): string[] {
    return data.map((item) => item.id);
  }
}
