import Model, { attr } from '@ember-data/model';
import { type IAutoresponderSchema } from 'uplisting-frontend/models/schemas';

export default class AutoresponderModel
  extends Model
  implements IAutoresponderSchema
{
  @attr('boolean') enabled!: boolean;
  @attr('number') delayInMinutes!: number;
  @attr('boolean') autoAccept!: boolean;
  @attr('string', { defaultValue: '' }) message!: string;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    autoresponder: AutoresponderModel;
  }
}
