import Component from '@glimmer/component';
import { action } from '@ember/object';
import { cached, tracked } from '@glimmer/tracking';
import orderBy from 'lodash-es/orderBy';
import { searchOptions, toggleHasManyValue } from 'uplisting-frontend/utils';
import PropertyModel from 'uplisting-frontend/models/property';
import UpsellModel from 'uplisting-frontend/models/upsell';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';

interface IArgs {
  changeset: GenericChangeset<UpsellModel>;
  properties: PropertyModel[];
  onSave(): Promise<void>;
  onCancel(): void;
}

interface UpsellPropertiesSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    default: [];
  };
}

export default class UiUpsellPropertiesComponent extends Component<UpsellPropertiesSignature> {
  @cached @tracked inputValue = '';
  @cached @tracked isSaving = false;

  @cached
  get changeset(): GenericChangeset<UpsellModel> {
    return this.args.changeset;
  }

  @cached
  get propertiesSorted(): PropertyModel[] {
    const { currency } = this.changeset;

    return orderBy(
      this.args.properties,
      [(item) => item.currency === currency],
      ['desc'],
    );
  }

  @cached
  get filteredProperties(): PropertyModel[] {
    return searchOptions<PropertyModel>(
      this.propertiesSorted,
      this.inputValue,
      'nickname',
    );
  }

  @cached
  get availableFilteredProperties(): PropertyModel[] {
    return this.filteredProperties.filter(
      (property) => !this.computeDisabledProperty(property),
    );
  }

  @cached
  get isAllPropertiesSelected(): boolean {
    const { properties } = this.changeset;

    return this.availableFilteredProperties.every((property) =>
      properties.includes(property),
    );
  }

  @action
  computeDisabledProperty(property: PropertyModel): boolean {
    return property.currency !== this.changeset.currency;
  }

  @action
  handleInputSearch(value: string): void {
    this.inputValue = value;
  }

  @action
  handleToggleProperty(property: PropertyModel): void {
    toggleHasManyValue(this.changeset, 'properties', property);
  }

  @action
  handleToggleAllProperties(): void {
    const { properties } = this.changeset;

    if (this.isAllPropertiesSelected) {
      this.availableFilteredProperties.forEach((property) => {
        this.handleToggleProperty(property);
      });
    } else {
      const filtered = this.availableFilteredProperties.filter(
        (property) => !properties.includes(property),
      );

      properties.push(...filtered);
    }
  }

  @action
  async handleSave(): Promise<void> {
    try {
      this.isSaving = true;

      await this.args.onSave();
    } finally {
      this.isSaving = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Upsell::Properties': typeof UiUpsellPropertiesComponent;
  }
}
