import BookingModel from 'uplisting-frontend/models/booking';

export enum GuestInvoiceStatus {
  open = 'open',
  paymentError = 'payment_error',
  paymentMethodMissing = 'payment_method_missing',
  markedAsPaid = 'marked_as_paid',
  paid = 'paid',
  void = 'void',
}

export interface IGuestInvoiceSchema {
  status: GuestInvoiceStatus;
  externalId: string;

  booking: BookingModel;
}
