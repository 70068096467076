import type Transition from '@ember/routing/transition';
import BaseRoute from 'uplisting-frontend/pods/base/route';

export default class AccountLapsedRoute extends BaseRoute {
  async beforeModel(transition: Transition): Promise<void> {
    await super.beforeModel(transition);

    if (!this.session.currentUser.lapsed) {
      window.open('/', '_self');
    }
  }
}
