import { type Registry as Services, inject as service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import TaxModel from 'uplisting-frontend/models/tax';
import BaseController from 'uplisting-frontend/pods/base/controller';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { TaxRateType } from 'uplisting-frontend/models/schemas';

export default class AccountTaxesController extends BaseController {
  @service('repositories/tax') taxRepository!: Services['repositories/tax'];

  @cached @tracked taxes!: TaxModel[];
  @cached @tracked showEditTaxModal = false;
  @cached @tracked isSubmitting = false;
  @cached @tracked taxToEdit?: TaxModel;

  newTax?: TaxModel;

  @cached
  get taxesWithId(): TaxModel[] {
    return this.taxes.filter((tax) => tax.id);
  }

  @cached
  get hasTaxes(): boolean {
    return !!this.taxesWithId.length;
  }

  @cached
  get changeset(): GenericChangeset<TaxModel> {
    return this.taxRepository.buildChangeset(this.taxToEdit);
  }

  @cached
  get taxRateOptions(): TaxRateType[] {
    return [TaxRateType.exclusive, TaxRateType.inclusive];
  }

  @action
  handleShowTaxEdit(tax?: TaxModel): void {
    this.taxToEdit = tax || this.getTaxToEdit();
    this.showEditTaxModal = true;
  }

  @action
  handleCancelTaxEdit(): void {
    this.showEditTaxModal = false;
    this.taxToEdit = undefined;
  }

  @action
  async handleEditTax(): Promise<void> {
    this.isSubmitting = true;

    await this.changeset.validate();

    if (this.changeset.isInvalid) {
      this.isSubmitting = false;

      return;
    }

    this.showEditTaxModal = false;

    try {
      const isNew = !this.changeset.id;
      await this.changeset.save();

      this.notifications.info(
        `account_taxes.notifications.${isNew ? 'created' : 'updated'}`,
      );
    } catch {
      this.notifications.error();
    } finally {
      this.isSubmitting = false;
    }
  }

  private getTaxToEdit(): TaxModel {
    if (this.newTax && !this.newTax.id) {
      return this.newTax;
    }

    const { taxRepository } = this;

    this.newTax = taxRepository.createRecord(taxRepository.defaultParams);

    return this.newTax;
  }
}
