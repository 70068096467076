import templateOnly from '@ember/component/template-only';

interface IArgs {
  text: string;
}

interface EmptyStateSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    default?: [];
  };
}

const UiEmptyStateComponent = templateOnly<EmptyStateSignature>();

export default UiEmptyStateComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::EmptyState': typeof UiEmptyStateComponent;
  }
}
