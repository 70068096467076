import Model, { attr, belongsTo } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import TaxModel from 'uplisting-frontend/models/tax';
import { type IAdditionalBookingChargeSchema } from 'uplisting-frontend/models/schemas';

export default class AdditionalBookingChargeModel
  extends Model
  implements IAdditionalBookingChargeSchema
{
  @attr('string') name!: string;
  @attr('number') amount!: number;

  @belongsTo('tax', {
    async: false,
    inverse: null,
  })
  tax?: TaxModel;

  @cached
  get amountWithTax(): number {
    const { tax, amount } = this;

    if (!tax || tax.isInclusive) {
      return amount;
    }

    const percentage = tax.rate / 100;
    const taxAmount = amount * percentage;

    return amount + taxAmount;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'additional-booking-charge': AdditionalBookingChargeModel;
  }
}
