import RSVP from 'rsvp';
import type Transition from '@ember/routing/transition';
import { type Registry as Services, inject as service } from '@ember/service';
import BookingModel from 'uplisting-frontend/models/booking';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import ActionBookingsPriceController from 'uplisting-frontend/pods/action/bookings/price/controller';

export default class ActionBookingsPriceRoute extends PermittedRoute {
  @service('repositories/tax') taxRepository!: Services['repositories/tax'];

  permission = 'calendar.bookings.price';

  model() {
    const booking = this.modelFor('action.bookings') as BookingModel;

    return RSVP.hash({
      booking,
      taxes: this.taxRepository.findAll(),
    });
  }

  setupController(
    controller: ActionBookingsPriceController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, model);
  }
}
