import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';
import ChannelUserModel from 'uplisting-frontend/models/channel-user';

interface IArgs {
  channelUser: ChannelUserModel;
}

export interface BookingGuestStatusSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiBookingGuestStatusComponent extends Component<BookingGuestStatusSignature> {
  @cached
  get channelUser(): ChannelUserModel {
    return this.args.channelUser;
  }

  @cached
  get rating(): number {
    return this.channelUser.rating;
  }

  @cached
  get reviews(): number {
    return this.channelUser.reviews;
  }

  @cached
  get verified(): boolean {
    return this.channelUser.verified;
  }

  @cached
  get isAvailable(): boolean {
    return isPresent(this.rating) && isPresent(this.reviews) && this.verified;
  }

  @cached
  get ratingIcon(): string {
    if (this.rating <= 3) {
      return 'exclamation-circle';
    } else if (this.rating <= 4) {
      return 'exclamation-triangle';
    }

    return 'check-circle';
  }

  @cached
  get ratingClass(): string {
    if (this.rating <= 3) {
      return 'bg-danger text-danger';
    } else if (this.rating <= 4) {
      return 'bg-warning text-warning';
    }

    return 'bg-green dark-green';
  }

  @cached
  get reviewsIcon(): string {
    if (this.reviews <= 1) {
      return 'exclamation-circle';
    } else if (this.reviews <= 5) {
      return 'exclamation-triangle';
    }

    return 'check-circle';
  }

  @cached
  get reviewsClass(): string {
    if (this.reviews <= 1) {
      return 'bg-danger text-danger';
    } else if (this.reviews <= 5) {
      return 'bg-warning text-warning';
    }

    return 'bg-green dark-green';
  }

  @cached
  get statusIcon(): string {
    if (this.verified) {
      return 'check-circle';
    }

    return 'exclamation-circle';
  }

  @cached
  get statusClass(): string {
    if (this.verified) {
      return 'bg-green dark-green';
    }

    return 'bg-danger text-danger';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Guest::Status': typeof UiBookingGuestStatusComponent;
  }
}
