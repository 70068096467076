import templateOnly from '@ember/component/template-only';

interface IArgs {
  isReadOnlyAirbnb: boolean;
}

interface BookingReadOnlyMessageHeaderSignature {
  Element?: HTMLDivElement;

  Args: IArgs;
}

const UiReadOnlyMessageHeaderComponent =
  templateOnly<BookingReadOnlyMessageHeaderSignature>();

export default UiReadOnlyMessageHeaderComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::ReadOnlyMessageHeader': typeof UiReadOnlyMessageHeaderComponent;
  }
}
