import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import DirectBaseController from 'uplisting-frontend/pods/direct/base-controller';
import { ONE_MB } from 'uplisting-frontend/utils';
import { isAnyInvalid } from 'uplisting-frontend/validations/helpers';

export default class DirectDesignController extends DirectBaseController {
  logoMaxSize = ONE_MB;
  logoPixelsSize = 720;

  heroImageMaxSize = ONE_MB * 2;
  heroPixelsSize = 2500;

  @cached
  get changeSettingsDisabled(): boolean {
    const { configuration } = this;

    return (
      !configuration.hasSubdomain &&
      (!configuration.customDomain || !configuration.customDomainVerified)
    );
  }

  @action
  async submitLogoHref(): Promise<void> {
    const validations = await this.changeset.validate('logoHref');

    if (isAnyInvalid(validations)) {
      return;
    }

    try {
      await this.changeset.save();

      this.notifications.info('direct_design.logo.link_saved');
    } catch {
      this.notifications.error();
    }
  }

  @action
  validateLogoDimensions(image: HTMLImageElement): string | undefined {
    const { logoPixelsSize } = this;

    if (image.width > logoPixelsSize || image.height > logoPixelsSize) {
      return this.intl.t('direct_design.logo.warning', {
        pixels: logoPixelsSize,
      });
    }
  }

  @action
  validateHeroDimensions(image: HTMLImageElement): string | undefined {
    const { heroPixelsSize } = this;

    if (image.width !== heroPixelsSize) {
      return this.intl.t('direct_design.hero.warning', {
        pixels: heroPixelsSize,
      });
    }
  }

  @action
  async handleImageUpload(): Promise<void> {
    await this.changeset.save();

    this.notifications.info('direct_design.image_uploaded');
  }
}
