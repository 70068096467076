import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { BookingRentalAgreementStatus } from 'uplisting-frontend/models/schemas';
import BookingRentalAgreementModel from 'uplisting-frontend/models/booking-rental-agreement';

interface IArgs {
  rentalAgreement: BookingRentalAgreementModel;
}

export interface BookingStatusRentalAgreementSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiBookingStatusRentalAgreementComponent extends Component<BookingStatusRentalAgreementSignature> {
  @cached
  get rentalAgreement(): BookingRentalAgreementModel {
    return this.args.rentalAgreement;
  }

  @cached
  get status(): BookingRentalAgreementStatus {
    return this.rentalAgreement.status;
  }

  @cached
  get showLink(): boolean {
    return this.status === BookingRentalAgreementStatus.processed;
  }

  @cached
  get alertClass():
    | 'inline-alert-danger'
    | 'inline-alert-processing'
    | 'inline-alert-success' {
    switch (this.status) {
      case BookingRentalAgreementStatus.pending:
        return 'inline-alert-danger';
      case BookingRentalAgreementStatus.processing:
      case BookingRentalAgreementStatus.markedAsSigned:
        return 'inline-alert-processing';
      case BookingRentalAgreementStatus.processed:
        return 'inline-alert-success';
    }
  }

  @cached
  get icon(): 'circle-xmark' | 'circle-check' | 'clock' | undefined {
    switch (this.status) {
      case BookingRentalAgreementStatus.pending:
        return 'circle-xmark';
      case BookingRentalAgreementStatus.processing:
      case BookingRentalAgreementStatus.markedAsSigned:
        return 'clock';
      case BookingRentalAgreementStatus.processed:
        return 'circle-check';
    }
  }

  @cached
  get iconPrefix(): 'far' | undefined {
    if (this.icon === 'clock') {
      return 'far';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Status::RentalAgreement': typeof UiBookingStatusRentalAgreementComponent;
  }
}
