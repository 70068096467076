import { type Registry as Services, inject as service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import ActionChangeoversController from 'uplisting-frontend/pods/action/changeovers/controller';

interface IParams {
  changeover_id: string;
}

export default class ActionChangeoversRoute extends PermittedRoute {
  @service('repositories/changeover')
  changeoverRepository!: Services['repositories/changeover'];

  permission = 'calendar.changeovers';

  model(params: IParams) {
    return this.changeoverRepository.findRecord(params.changeover_id, {
      reload: true,
    });
  }

  setupController(
    controller: ActionChangeoversController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { changeover: model });
  }
}
