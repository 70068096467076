import { type Registry as Services, inject as service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import { hash } from 'rsvp';
import AutomateCleaningController from 'uplisting-frontend/pods/automate/cleaning/controller';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';

export default class AutomateCleaningRoute extends PermittedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  permission = 'automate.cleaning';

  model() {
    return hash({
      properties: this.propertyRepository.findAll(),
      changeoverSettings: this.store.findAll('changeover-setting'),
    });
  }

  setupController(
    controller: AutomateCleaningController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    const bulkChangeoverSetting = this.store.createRecord(
      'bulk-changeover-setting',
      {
        changeoverSettings: model.changeoverSettings,
      },
    );

    Object.assign(controller, {
      bulkChangeoverSetting,
      ...model,
    });

    controller.setDefaultState();
  }

  resetController(): void {
    this.store.unloadAll('changeover-setting');
    this.store.unloadAll('bulk-changeover-setting');
  }
}
