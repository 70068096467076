import {
  BookingStatus,
  SecurityDepositStatus,
  BookingRentalAgreementStatus,
} from 'uplisting-frontend/models/schemas';

export enum OccasionType {
  booking = 'booking',
  enquiry = 'enquiry',
  bookingRequest = 'booking_request',
}

export enum OccasionChannel {
  airbnb = 'airbnb_official',
  bookingDotCom = 'booking_dot_com',
  google = 'google',
  homeAway = 'home_away',
  uplisting = 'uplisting',
}

export enum OccasionPaymentStatus {
  paid = 'paid',
  markedAsPaid = 'marked_as_paid',
  pending = 'pending',
  failing = 'failing',
  failed = 'failed',
  notPaid = 'not_paid',
}

export enum GuestIdentityVerificationStatus {
  overridden = 'overridden',
  processing = 'processing',
  requiresAction = 'requires_action',
  requiresInput = 'requires_input',
  succeeded = 'succeeded',
  verified = 'verified',
}

export enum OccasionEvent {
  booked = 'booked',
  arrived = 'arrived',
  departed = 'departed',
  enquired = 'enquired',
}

export enum OccasionPreposition {
  before = 'before',
  after = 'after',
}

export enum OccasionTermUnit {
  minutes = 'minutes',
  hours = 'hours',
  days = 'days',
}

export interface IOccasionSchema {
  // booking occasion fields
  bookingGuestName: string;
  bookingPropertyNickname: string;
  bookingMultiUnitName: string;
  bookingCheckIn: string;
  bookingCheckOut: string;
  bookingArrivalTime: string;
  bookingDepartureTime: string;
  bookingNumberOfGuests: number;
  bookingDuration: number;
  bookingChannel: string;
  bookingStatus: BookingStatus;
  bookingRemainingNights: number;
  bookingTotalPayout: number;
  bookingCreationDate: Date;
  bookingCancellationDate: Date;

  // enquiry occasion fields
  enquiryPropertyNickname: string;
  enquiryChannel: string;
  enquiryCheckIn: string;
  enquiryCheckOut: string;
  enquiryDuration: number;
  enquiryGuestName: string;
  enquiryNumberOfGuests: number;
  enquiryStatus: BookingStatus;
  enquiryCreationDate: Date;

  // booking requests occasion fields
  bookingRequestPropertyNickname: string;
  bookingRequestMultiUnitName: string;
  bookingRequestGuestName: string;
  bookingRequestCheckIn: string;
  bookingRequestCheckOut: string;
  bookingRequestNumberOfGuests: number;
  bookingRequestDuration: number;
  bookingRequestChannel: string;
  bookingRequestStatus: BookingStatus;
  bookingRequestCreationDate: Date;

  // booking payment occasion fields
  bookingOutstandingAmount: number;
  bookingPaymentDueDate: Date;
  bookingPaymentStatus: OccasionPaymentStatus;

  // security deposits occasion fields
  bookingSecurityDepositAmount: number;
  bookingSecurityDepositDueDate: Date;
  bookingSecurityDepositStatus: SecurityDepositStatus;

  // guest identity verification occasion fields
  bookingGuestIdentityVerificationStatus: GuestIdentityVerificationStatus;

  // booking rental agreement occasion fields
  bookingRentalAgreementStatus: BookingRentalAgreementStatus;

  bookingSource: string | null;
  bookingGuestEmail: string | null;
  bookingGuestPhone: string | null;
  bookingNote: string | null;
  bookingConfirmationCode: string;

  bookingPropertyId: string;
  bookingPropertyTimeZone: string;

  // booking price occasion fields
  bookingPriceAccommodationTotal: number;
  bookingPriceCleaningFee: number;
  bookingPriceExtraGuestCharges: string | null;
  bookingPriceExtraCharges: number;
  bookingPriceDiscounts: number;
  bookingPriceTaxes: number;
  bookingPricePaymentProcessingFee: number;
  bookingPriceCommission: number;
  bookingPriceCommissionTax: number;
  bookingPriceCancellationFee: string | null;
  bookingPriceAccommodationManagementFee: string | null;
  bookingPriceCleaningManagementFee: string | null;
  bookingPriceTotalManagementFee: string | null;
  bookingPriceGrossRevenue: number;
  bookingPriceNetRevenue: number;
  bookingPriceBalance: number;
  bookingPriceUpsellsTotal: number;
}
