import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import BookingModel from 'uplisting-frontend/models/booking';
import BalanceAdjustmentModel from 'uplisting-frontend/models/balance-adjustment';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { BalanceAdjustmentType } from 'uplisting-frontend/models/schemas';
import { isSaveDisabled } from 'uplisting-frontend/utils';

interface IArgs {
  booking: BookingModel;
}

export interface BookingPriceBalanceAdjustmentSignature {
  Element: HTMLTableCellElement;

  Args: IArgs;
}

export default class UiBookingPriceBalanceAdjustmentComponent extends Component<BookingPriceBalanceAdjustmentSignature> {
  @service notifications!: Services['notifications'];

  @service('repositories/balance-adjustment')
  balanceAdjustmentRepository!: Services['repositories/balance-adjustment'];

  isSaving = false;

  @cached @tracked showAdjustModal = false;
  @cached @tracked changeset!: GenericChangeset<BalanceAdjustmentModel>;

  types = Object.values(BalanceAdjustmentType);

  @action
  handleShowAdjustModal(): void {
    const { balanceAdjustmentRepository } = this;

    const record = balanceAdjustmentRepository.createRecord({
      ...balanceAdjustmentRepository.defaultParams,
      booking: this.args.booking,
    });

    this.changeset = balanceAdjustmentRepository.buildChangeset(record);

    this.showAdjustModal = true;
  }

  @action
  handleClose(): void {
    if (!this.isSaving) {
      this.changeset?.data.unloadRecord();
    }
  }

  @action
  async handleSubmit(): Promise<void> {
    this.isSaving = true;

    const saveDisabled = await isSaveDisabled(this.changeset);

    if (saveDisabled) {
      this.isSaving = false;

      return;
    }

    try {
      await this.changeset.save();

      this.notifications.info(
        'action_bookings_price.balance_adjustment.notifications.saved',
      );

      await this.args.booking.reload();
    } catch {
      this.notifications.error();
    } finally {
      this.isSaving = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Price::BalanceAdjustment': typeof UiBookingPriceBalanceAdjustmentComponent;
  }
}
