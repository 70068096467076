import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import BookingModel from 'uplisting-frontend/models/booking';
import MessageModel from 'uplisting-frontend/models/message';

interface IArgs {
  booking: BookingModel;
  message: MessageModel;
  saveReplyRoute: string;
}

export interface BookingMessagesMessageInboundSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiBookingMessagesMessageInboundComponent extends Component<BookingMessagesMessageInboundSignature> {
  @cached
  get message(): MessageModel {
    return this.args.message;
  }

  @cached
  get booking(): BookingModel {
    return this.args.booking;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ui/booking/messages/message/inbound': typeof UiBookingMessagesMessageInboundComponent;
    'Ui::Booking::Messages::Message::Inbound': typeof UiBookingMessagesMessageInboundComponent;
  }
}
