import type Transition from '@ember/routing/transition';
import { hash } from 'rsvp';
import { type Registry as Services, inject as service } from '@ember/service';
import AccountTeamController from 'uplisting-frontend/pods/account/team/controller';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';

export default class AccountTeamRoute extends PermittedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/invitation')
  invitationRepository!: Services['repositories/invitation'];

  permission = 'account.team';

  model() {
    return hash({
      properties: this.propertyRepository.findAll(),
      invitations: this.invitationRepository.findAll(),
      teammates: this.store.findAll('teammate'),
    });
  }

  setupController(
    controller: AccountTeamController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, {
      ...model,
      route: this,
    });
  }
}
