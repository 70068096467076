import Model from '@ember-data/model';
import { type IBaseRateSchema } from 'uplisting-frontend/models/schemas';

export default class BaseRateModel extends Model implements IBaseRateSchema {}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'base-rate': BaseRateModel;
  }
}
