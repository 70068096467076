import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import { OccasionFilterType } from 'uplisting-frontend/models/schemas';
import { type OccasionNumericalFilter } from 'uplisting-frontend/utils/occasion-filters';

interface IArgs {
  filter: OccasionNumericalFilter;
}

interface ReportFiltersOptionsSignature {
  Element: HTMLUListElement;

  Args: IArgs;
}

export default class UiReportFiltersOptionsNumericalComponent extends Component<ReportFiltersOptionsSignature> {
  @cached
  get filter(): OccasionNumericalFilter {
    return this.args.filter;
  }

  @cached
  get value(): string | undefined {
    return this.filter.values[0];
  }

  @action
  handleTypeChange(type: OccasionFilterType): void {
    this.filter.updateType(type);
  }

  @action
  handleInputChange(event: Event): void {
    const { value } = event.target as HTMLInputElement;

    this.filter.updateValues(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::ReportFilters::Options::Numerical': typeof UiReportFiltersOptionsNumericalComponent;
  }
}
