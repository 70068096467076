import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import { type IDropdown } from 'ember-bootstrap/components/bs-dropdown';
import {
  type OccasionFilter,
  OccasionFilterGroup,
} from 'uplisting-frontend/utils/occasion-filters';

interface IArgs {
  filterGroup: OccasionFilterGroup;

  onAddFilter(filter: OccasionFilter, filterGroup: OccasionFilterGroup);
  onFilterChange(
    filter: OccasionFilter,
    filterGroup: OccasionFilterGroup,
    editableFilterIndex: number,
  ): void;
  fetchData(isInitial?: boolean): void;
  onAddGroup(): OccasionFilterGroup;
  onRemoveGroup(filterGroup: OccasionFilterGroup): void;
}

interface ReportFiltersSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiReportFiltersComponent extends Component<ReportFiltersSignature> {
  @service('occasions') occasionsService!: Services['occasions'];

  editableFilterIndex?: number;
  dropdown!: IDropdown;

  @cached @tracked editableFilter?: OccasionFilter;
  @cached @tracked editableFilterGroup?: OccasionFilterGroup;
  @cached @tracked isAddingFilter = false;

  @cached
  get filterGroup(): OccasionFilterGroup {
    return this.args.filterGroup;
  }

  @cached
  get isFilterDeletable(): boolean {
    const isOnlyItem = this.filterGroup.values.length === 1;
    const firstFilter = this.filterGroup.values[0];

    if (isOnlyItem && firstFilter instanceof OccasionFilterGroup) {
      return firstFilter.values.length === 1;
    }

    return isOnlyItem;
  }

  @cached
  get isGroupDeletable(): boolean {
    return this.filterGroup.values.length !== 1;
  }

  @action
  handleInsertDropdownMenu(dd: IDropdown): void {
    this.dropdown = dd;
  }

  @action
  handleFilterTypeChange(): void {
    this.args.fetchData(true);
  }

  @action
  handleAddFilterToGroup(filterGroup: OccasionFilterGroup): void {
    this.resetEditableState(filterGroup);
    this.isAddingFilter = true;
    this.dropdown.openDropdown();
  }

  @action
  handleDropdownHide(): void {
    this.isAddingFilter = false;
  }

  @action
  handleAddFilter(filter: OccasionFilter): void {
    if (!filter || !filter.isValid) {
      return;
    }

    this.args.onAddFilter(
      filter,
      this.editableFilterGroup as OccasionFilterGroup,
    );
  }

  @action
  handleChangeFilter(filter: OccasionFilter): void {
    if (!filter || !filter.isValid) {
      return;
    }

    this.onFilterChange(filter);
  }

  @action
  handleShowAddFilter(): void {
    this.resetEditableState(this.filterGroup);
    this.isAddingFilter = true;
  }

  @action
  handleAddGroup(): void {
    this.isAddingFilter = true;
    this.editableFilterGroup = this.args.onAddGroup();
  }

  @action
  handleEditFilter(group: OccasionFilterGroup, filter: OccasionFilter): void {
    this.editableFilter = this.occasionsService.clone(filter);
    this.editableFilterGroup = group;
    this.editableFilterIndex = group.values.indexOf(filter);
  }

  @action
  handleDeleteFilter(group: OccasionFilterGroup, filter: OccasionFilter): void {
    this.resetEditableState(group);

    this.onFilterChange(filter);
  }

  @action
  shouldShowFilterOperator(
    group: OccasionFilterGroup,
    item: OccasionFilter,
  ): boolean {
    return group.values[group.values.length - 1] !== item;
  }

  private onFilterChange(filter: OccasionFilter): void {
    this.args.onFilterChange(
      filter,
      this.editableFilterGroup as OccasionFilterGroup,
      this.editableFilterIndex as number,
    );
  }

  private resetEditableState(group?: OccasionFilterGroup): void {
    this.editableFilter = undefined;
    this.editableFilterIndex = undefined;
    this.editableFilterGroup = group;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::ReportFilters': typeof UiReportFiltersComponent;
  }
}
