import { cached, tracked } from '@glimmer/tracking';
import DataModel from '@ember-data/model';
import { toggleValue } from 'uplisting-frontend/utils';
import {
  BaseAbstractFilterParams,
  type IBaseFilterParams,
  BaseAbstractFilter,
  FilterComponent,
} from 'uplisting-frontend/utils/filters/abstract/base';

export class MultiSelectFilterParams<Model = DataModel | undefined>
  extends BaseAbstractFilterParams<string[]>
  implements IBaseFilterParams<number[], string | Model>
{
  @cached @tracked value = [];

  @cached
  get selectedRecords(): Model[] {
    const { value, filter } = this;

    return value.map((id) =>
      filter.models.find((model) => model.id === id || model.id === `${id}`),
    );
  }

  public updateValue(value: string | Model): void {
    if (typeof value === 'string') {
      toggleValue(this, 'value', value);
    } else {
      toggleValue(this, 'value', (value as DataModel).id);
    }
  }

  public toQuery(): number[] {
    return this.value.map(Number);
  }

  public reset(): void {
    this.value = [];
  }
}

export abstract class MultiSelectFilter<
  Model = DataModel | undefined,
> extends BaseAbstractFilter<string[], string | Model> {
  component = FilterComponent.multiSelect;

  constructor(value?: string[]) {
    super(new MultiSelectFilterParams<Model>(value));
  }
}
