import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import { getOwner } from '@ember/application';
import ClientsStatementsIndexRoute from 'uplisting-frontend/pods/clients/statements/index/route';
import BaseController from 'uplisting-frontend/pods/base/controller';

export default class ClientsStatementsController extends BaseController {
  @service pricing!: Services['pricing'];
  @service features!: Services['features'];

  @action
  async handleModalSubmit(): Promise<void> {
    await this.accountConfigurationRepository.enableClientStatements();

    const route = getOwner(this)?.lookup(
      'route:clients/statements/index',
    ) as ClientsStatementsIndexRoute;

    await route.refresh();
  }
}
