import { action } from '@ember/object';
import { cached, tracked } from '@glimmer/tracking';
import sortBy from 'lodash-es/sortBy';
import BaseController from 'uplisting-frontend/pods/base/controller';
import ClientModel from 'uplisting-frontend/models/client';
import PermissionModel from 'uplisting-frontend/models/permission';

export default class ClientsClientAccessController extends BaseController {
  @cached @tracked client!: ClientModel;

  @cached
  get parentPermissions(): PermissionModel[] {
    return sortBy(
      this.client.permissions.filter((item) => !item.parent),
      'category',
    );
  }

  @action
  async handleUpdatePermission(item: PermissionModel): Promise<void> {
    try {
      item.permitted = !item.permitted;

      await item.save();

      this.notifications.info();
    } catch {
      this.notifications.error();
    }
  }
}
