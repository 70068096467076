import templateOnly from '@ember/component/template-only';
import CustomPropertyAttributeModel from 'uplisting-frontend/models/custom-property-attribute';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';

interface IArgs {
  changeset: GenericChangeset<CustomPropertyAttributeModel>;
  onSave(): Promise<void>;
  onClose(): void;
  onDelete(): void;
}

interface AutomateCustomPropertyAttributeFormSignature {
  Element: HTMLFormElement;

  Args: IArgs;
}

const UiAutomateCustomPropertyAttributeFormComponent =
  templateOnly<AutomateCustomPropertyAttributeFormSignature>();

export default UiAutomateCustomPropertyAttributeFormComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Automate::CustomPropertyAttribute::Form': typeof UiAutomateCustomPropertyAttributeFormComponent;
  }
}
