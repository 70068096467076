import { action } from '@ember/object';
import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import BaseController from 'uplisting-frontend/pods/base/controller';
import PropertyModel from 'uplisting-frontend/models/property';
import UpsellModel from 'uplisting-frontend/models/upsell';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { OccasionFilterGroup } from 'uplisting-frontend/utils/occasion-filters';
import { isSaveDisabled } from 'uplisting-frontend/utils';

export default class UpsellsEditController extends BaseController {
  @service('occasions') occasionsService!: Services['occasions'];

  @service('repositories/upsell')
  upsellRepository!: Services['repositories/upsell'];

  @cached @tracked upsell!: UpsellModel;
  @cached @tracked properties!: PropertyModel[];

  @cached
  get changeset(): GenericChangeset<UpsellModel> {
    return this.upsellRepository.buildChangeset(this.upsell);
  }

  @cached
  get filterGroup(): OccasionFilterGroup {
    return this.occasionsService.createGroupFromFilters(
      this.upsell.occasionFilters,
    );
  }

  @action
  async handleCancel(): Promise<unknown> {
    return this.router.transitionTo('upsells');
  }

  @action
  async handleSave(): Promise<void> {
    this.changeset.occasionFilters = this.filterGroup.toUnwrappedQuery();

    const saveDisabled = await isSaveDisabled(this.changeset);

    if (saveDisabled) {
      return;
    }

    try {
      await this.changeset.save();

      this.notifications.info();
    } catch {
      this.notifications.error();
    }
  }
}
