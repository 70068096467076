import Service from '@ember/service';
import * as Sentry from '@sentry/ember';
import type { Scope } from '@sentry/types';

export interface ISentryUser {
  id: string;
  email: string;
}

export default class SentryService extends Service {
  public configure(user: ISentryUser): void {
    Sentry.setUser(user);
  }

  public captureException(error: any, context?) {
    Sentry.captureException(error, context);
  }

  public captureMessage(message: string) {
    Sentry.captureMessage(message);
  }

  public getCurrentScope(): Scope {
    return Sentry.getCurrentScope();
  }
}

declare module '@ember/service' {
  interface Registry {
    sentry: SentryService;
  }
}
