import templateOnly from '@ember/component/template-only';

interface IArgs {
  title: string;
  description: string | number;
}

interface CardSectionSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

const UiCardSectionComponent = templateOnly<CardSectionSignature>();

export default UiCardSectionComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::CardSection': typeof UiCardSectionComponent;
  }
}
