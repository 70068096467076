import Model from '@ember-data/model';
import {
  type ValidatorFunction,
  type ValidatorResult,
} from 'ember-changeset-validations';

export function validateUniqueness<T extends Model>(): ValidatorFunction {
  return (
    key: string,
    newValue: unknown,
    _oldValue: unknown,
    _changes: Record<string, unknown>,
    content: Record<string, unknown>,
  ): ValidatorResult => {
    // @ts-expect-error - accessing private property
    const { modelName } = content.constructor;
    const records = (content as T).store.peekAll(modelName);

    return records
      .filter((item) => item !== content)
      .every((item) => item[key] !== newValue) as ValidatorResult;
  };
}
