import { hash } from 'rsvp';
import { type Registry as Services, inject as service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import AutomateRentalAgreementsController from 'uplisting-frontend/pods/automate/rental-agreements/controller';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';

export default class AutomateRentalAgreementsRoute extends PermittedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/custom-message-tag')
  customMessageTagRepository!: Services['repositories/custom-message-tag'];

  @service('repositories/custom-property-attribute')
  customPropertyAttributeRepository!: Services['repositories/custom-property-attribute'];

  @service('repositories/booking-rental-agreement-setting')
  bookingRentalAgreementSettingRepository!: Services['repositories/booking-rental-agreement-setting'];

  permission = 'automate.rental_agreements';

  model() {
    return hash({
      properties: this.propertyRepository.findAll(),
      customMessageTags: this.customMessageTagRepository.findAll(),
      customPropertyAttributes:
        this.customPropertyAttributeRepository.findAll(),
      rentalAgreementSetting:
        this.bookingRentalAgreementSettingRepository.getDefaultRecord(),
    });
  }

  setupController(
    controller: AutomateRentalAgreementsController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, model);
  }

  // don't unload `custom-message-tag` records as they are used on the following routes:
  // - `automate.message-tags`

  // don't unload `custom-property-attribute` records as they are used on the following routes:
  // - `automate.message-tags`
  // - `automate.rental-agreements`
  resetController(_controller, _isExiting, transition: Transition): void {
    const newRouteName = transition.to?.name;

    this.bookingRentalAgreementSettingRepository.unloadAll();

    if (newRouteName !== 'automate.message-tags') {
      this.customMessageTagRepository.unloadAll();

      if (newRouteName !== 'automate.rental-agreements') {
        this.customPropertyAttributeRepository.unloadAll();
      }
    }
  }
}
