import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import BookingModel from 'uplisting-frontend/models/booking';

interface IArgs {
  booking: BookingModel;
}

export interface BookingFooterSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiBookingFooterComponent extends Component<BookingFooterSignature> {
  @cached
  get booking(): BookingModel {
    return this.args.booking;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Footer': typeof UiBookingFooterComponent;
  }
}
