import Model, { attr, belongsTo } from '@ember-data/model';
import {
  UpsellOrderStatus,
  type IUpsellOrderSchema,
} from 'uplisting-frontend/models/schemas';
import UpsellModel from 'uplisting-frontend/models/upsell';
import BookingModel from 'uplisting-frontend/models/booking';

export default class UpsellOrderModel
  extends Model
  implements IUpsellOrderSchema
{
  @attr('date') createdAt!: Date;
  @attr('string') status!: UpsellOrderStatus;

  @belongsTo('upsell', { inverse: null, async: false })
  upsell!: UpsellModel;

  @belongsTo('booking', { inverse: null, async: false })
  booking!: BookingModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'upsell-order': UpsellOrderModel;
  }
}
