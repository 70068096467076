import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type IDropdown } from 'ember-bootstrap/components/bs-dropdown';
import { type DateFilter } from 'uplisting-frontend/utils/filters/types';
import FilterComponent from 'uplisting-frontend/pods/components/ui/filters/filter';

export default class UiFiltersFilterMonthComponent extends FilterComponent<DateFilter> {
  @cached @tracked closeOnMenuClick = false;

  @cached
  get dateFormatted(): string {
    const { value } = this.filter.params;

    if (!value) {
      return '';
    }

    return new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'long',
    }).format(value);
  }

  @action
  handleChange(dd: IDropdown, date: Date): void {
    dd.closeDropdown();

    this.filter.updateValue(date);

    this.args.onFilterUpdate();
  }

  @action
  handleReset(): void {
    this.filter.reset();

    this.args.onFilterUpdate();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ui/filters/filter/month': typeof UiFiltersFilterMonthComponent;
    'Ui::Filters::Filter::Month': typeof UiFiltersFilterMonthComponent;
  }
}
