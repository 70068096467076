import Service from '@ember/service';
import axios, { type AxiosInstance, AxiosHeaders } from 'axios';
import ApplicationAdapter from 'uplisting-frontend/adapters/application';

export interface IErrorResponse {
  title: string;
  detail: string;
}

export interface IErrorResult {
  isError: true;
  errors: IErrorResponse[];
}

export default class HttpClientService extends Service {
  instance!: AxiosInstance;

  constructor(owner) {
    super(owner);

    const adapter = owner.lookup('adapter:application') as ApplicationAdapter;

    this.instance = axios.create({
      baseURL: `${adapter.host}/${adapter.namespace}`,
      headers: adapter.headers as AxiosHeaders,
    });
  }

  async postJSONApi<T>(
    url: string,
    data: object,
  ): Promise<T | IErrorResult | undefined> {
    try {
      const request = await this.instance.post(`/${url}`, {
        data: {
          attributes: data,
        },
      });

      return request.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return {
          isError: true,
          errors: err.response.data.errors,
        } as IErrorResult;
      }
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'http-client': HttpClientService;
  }
}
