import { cached, tracked } from '@glimmer/tracking';
import BaseController from 'uplisting-frontend/pods/base/controller';
import BookingModel from 'uplisting-frontend/models/booking';

export default class ActionBookingsController extends BaseController {
  @cached @tracked booking!: BookingModel;

  @cached
  get canEditPrice(): boolean {
    return (
      this.currentUser.hasAccessTo('bookings.price') &&
      this.booking.isPriceEditable
    );
  }

  @cached
  get canMessageGuest(): boolean {
    return (
      this.currentUser.hasAccessTo('bookings.messages') &&
      this.booking.canSendMessage
    );
  }

  @cached
  get canSeeGuestIdentity(): boolean {
    return !!(
      this.currentUser.hasAccessTo('bookings.identity') &&
      this.booking.guestIdentityVerification?.succeeded
    );
  }

  @cached
  get isViewingReplies(): boolean {
    return this.router.currentRouteName?.includes('bookings.replies');
  }
}
