import { action } from '@ember/object';
import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import BaseController from 'uplisting-frontend/pods/base/controller';
import { StateMachine, isSaveDisabled } from 'uplisting-frontend/utils';
import PropertyModel from 'uplisting-frontend/models/property';
import UpsellModel from 'uplisting-frontend/models/upsell';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { OccasionFilterGroup } from 'uplisting-frontend/utils/occasion-filters';

export default class UpsellsNewController extends BaseController {
  @service('occasions') occasionsService!: Services['occasions'];

  @service('repositories/upsell')
  upsellRepository!: Services['repositories/upsell'];

  @cached @tracked properties!: PropertyModel[];
  @cached @tracked changeset!: GenericChangeset<UpsellModel>;

  upsellDetailsSection = this.intl.t('upsells_new.states.details');
  upsellPropertiesSection = this.intl.t('upsells_new.states.properties');

  stateMachine = new StateMachine([
    this.upsellDetailsSection,
    this.upsellPropertiesSection,
  ]);

  @cached
  get isUpsellDetailsSection(): boolean {
    return this.stateMachine.is(this.upsellDetailsSection);
  }

  @cached
  get isUpsellPropertiesSection(): boolean {
    return this.stateMachine.is(this.upsellPropertiesSection);
  }

  @cached
  get filterGroup(): OccasionFilterGroup {
    return this.occasionsService.createGroupFromFilters([]);
  }

  @action
  async handleCancel(): Promise<void> {
    await this.router.transitionTo('upsells');

    this.changeset.data.unloadRecord();
  }

  @action
  async handleGoToNext(): Promise<void> {
    const saveDisabled = await isSaveDisabled(this.changeset);

    if (saveDisabled) {
      return;
    }

    this.stateMachine.goToNextStep();
  }

  @action
  async handleSave(): Promise<void> {
    this.changeset.occasionFilters = this.filterGroup.toUnwrappedQuery();

    const saveDisabled = await isSaveDisabled(this.changeset);

    if (saveDisabled) {
      return;
    }

    try {
      await this.changeset.save();

      await this.router.transitionTo('upsells');

      this.notifications.info();
    } catch {
      this.notifications.error();
    }
  }

  @action
  handleGoToPrev(): void {
    this.stateMachine.goToPrevStep();
  }

  public buildChangeset(): void {
    const record = this.upsellRepository.createRecord(
      this.upsellRepository.defaultParams,
    );

    this.changeset = this.upsellRepository.buildChangeset(record);
  }
}
