import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import UserModel from 'uplisting-frontend/models/user';
import ChangeoverModel from 'uplisting-frontend/models/changeover';
import { ChangeoverStatus } from 'uplisting-frontend/models/schemas';
import TeammateModel from 'uplisting-frontend/models/teammate';
import PropertyModel from 'uplisting-frontend/models/property';
import BookingModel from 'uplisting-frontend/models/booking';

interface IArgs {
  changeover: ChangeoverModel;
  teammates: TeammateModel[];
}

interface IChangeoverBaseAction {
  translationKey: string;
  onApply(): void;
}

interface IChangeoverAction extends IChangeoverBaseAction {
  enabled: boolean;
  isCompleted: boolean;
  classNames: string;
}

export interface ChangeoverSummarySignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiChangeoverSummaryComponent extends Component<ChangeoverSummarySignature> {
  @service session!: Services['session'];
  @service notifications!: Services['notifications'];

  @cached
  get changeover(): ChangeoverModel {
    return this.args.changeover;
  }

  @cached
  get teammates(): TeammateModel[] {
    return this.args.teammates;
  }

  @cached
  get property(): PropertyModel {
    return this.changeover.property;
  }

  @cached
  get booking(): BookingModel {
    return this.changeover.booking;
  }

  @cached
  get currentUser(): UserModel {
    return this.session.currentUser;
  }

  @cached
  get isAssigneeChanged(): boolean {
    return this.changeover.teammate?.id !== this.changeover.persistedTeammateId;
  }

  @cached
  get canAssignTeammate(): boolean {
    const isManualOrHasTeammate =
      this.property.changeoverSetting.manual || !!this.selectedTeammate;

    const canAssignTeammate = this.currentUser.hasAccessTo(
      'changeovers.assignTeammate',
    );

    return isManualOrHasTeammate && canAssignTeammate;
  }

  @cached
  get selectedTeammate(): TeammateModel | undefined {
    const teammateId = this.changeover.teammate?.id;

    return this.teammates.find((teammate) => teammate.id === teammateId);
  }

  @cached
  get resetAction(): IChangeoverBaseAction | undefined {
    const resetActions = {
      [ChangeoverStatus.accepted]: {
        translationKey: 'action_changeovers_summary.reset_actions.accepted',
        onApply: () => {
          this.updateStatus(ChangeoverStatus.pending);
        },
      },
      [ChangeoverStatus.started]: {
        translationKey: 'action_changeovers_summary.reset_actions.started',
        onApply: () => {
          this.updateStatus(ChangeoverStatus.accepted);
        },
      },
      [ChangeoverStatus.finished]: {
        translationKey: 'action_changeovers_summary.reset_actions.finished',
        onApply: () => {
          this.updateStatus(ChangeoverStatus.started);
        },
      },
    };

    return resetActions[this.changeover.status];
  }

  @cached
  get changeoverActions(): IChangeoverAction[] {
    const { changeover } = this;
    const { isPending, isAccepted, isStarted, isFinished } = changeover;

    const name = this.changeover.teammate?.name;
    const version = name ? 'long' : 'short';

    return [
      {
        enabled: isPending,
        isCompleted: isAccepted || isStarted || isFinished,
        classNames: 'changeover-pending',
        get translationKey(): string {
          return `action_changeovers_summary.actions.accepted.${this.isCompleted ? version : 'before'}`;
        },
        onApply: () => {
          if (this.currentUser.isHousekeeper) {
            changeover.teammate = this.changeover.persistedTeammate;
          }

          this.updateStatus(ChangeoverStatus.accepted);
        },
      },
      {
        enabled: isAccepted,
        isCompleted: isStarted || isFinished,
        classNames: 'changeover-accepted',
        get translationKey(): string {
          return `action_changeovers_summary.actions.begun.${this.isCompleted ? version : 'before'}`;
        },
        onApply: () => {
          this.updateStatus(ChangeoverStatus.started);
        },
      },
      {
        enabled: isStarted,
        isCompleted: isFinished,
        classNames: 'changeover-finished',
        get translationKey(): string {
          return `action_changeovers_summary.actions.finished.${this.isCompleted ? version : 'before'}`;
        },
        onApply: () => {
          this.updateStatus(ChangeoverStatus.finished);
        },
      },
    ];
  }

  @action
  handleAssignTeammate(teammate: TeammateModel): void {
    this.changeover.teammate = teammate;
  }

  @action
  async handleSaveAssignment(): Promise<void> {
    try {
      await this.changeover.save();

      this.notifications.info(
        'action_changeovers_summary.assign_housekeeper.notification',
      );
    } catch {
      this.notifications.error();
    }
  }

  private async updateStatus(status: ChangeoverStatus) {
    this.changeover.status = status;

    try {
      await this.changeover.save();

      this.notifications.info(
        'action_changeovers_summary.cleaner_actions.notification',
      );
    } catch {
      this.notifications.error();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Changeover::Summary': typeof UiChangeoverSummaryComponent;
  }
}
