import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import { endOfMonth } from 'date-fns/endOfMonth';
import { startOfMonth } from 'date-fns/startOfMonth';
import { endOfYear } from 'date-fns/endOfYear';
import { startOfYear } from 'date-fns/startOfYear';
import { subMonths } from 'date-fns/subMonths';
import { addMonths } from 'date-fns/addMonths';
import { subYears } from 'date-fns/subYears';
import { isSameDay } from 'date-fns/isSameDay';
import { isDate } from 'date-fns/isDate';
import { isAfter } from 'date-fns/isAfter';
import { isBefore } from 'date-fns/isBefore';
import { DateTime } from 'litepicker/dist/types/datetime';
import { differenceInMonths } from 'date-fns/differenceInMonths';
import { scheduleTask } from 'ember-lifeline';
import UiPickerDateRangeComponent from 'uplisting-frontend/pods/components/ui/picker/date-range/component';
import { utcDate } from 'uplisting-frontend/utils';

enum DateRangeIds {
  thisMonth,
  nextMonth,
  lastMonth,
  last3Months,
  thisYear,
  lastYear,
  previousPeriod,
  samePeriodLastYear,
}

interface IDateRangeOption {
  id: DateRangeIds;
  title: string;
  startDate: Date;
  endDate: Date;
}

export interface IApplyCompareDateRangeParams {
  start: Date;
  end: Date;
  compareToStart?: Date;
  compareToEnd?: Date;
}

interface IArgs {
  startDate: Date;
  endDate: Date;

  compareToStartDate?: Date;
  compareToEndDate?: Date;

  dateFormat?: string;

  isCompareToDisabled: boolean;

  onDateRangeSelect(obj: IApplyCompareDateRangeParams): void;
}

interface PickerDateRangeSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export type ActiveSelection =
  | 'startDate'
  | 'endDate'
  | 'compareToStartDate'
  | 'compareToEndDate';

export default class UiCompareDateRangeComponent extends Component<PickerDateRangeSignature> {
  @service intl!: Services['intl'];
  @service screen!: Services['screen'];

  dateRangePicker!: UiPickerDateRangeComponent;

  constructor(owner: unknown, args: IArgs) {
    super(owner, args);

    this.setState();
  }

  @cached @tracked _selectedDateRange: IDateRangeOption | undefined;
  @cached @tracked _selectedCompareToDateRange: IDateRangeOption | undefined;

  @cached @tracked activeSelection!: ActiveSelection;

  @cached @tracked showCompareDate = false;

  @cached @tracked _startDate;
  @cached @tracked _endDate;

  @cached @tracked _compareToStartDate;
  @cached @tracked _compareToEndDate;

  @cached
  get selectedDateRange(): IDateRangeOption | undefined {
    if (this._selectedDateRange) {
      return this._selectedDateRange;
    }

    if (this.startDate && this.endDate) {
      return this.findDateRange(
        this.startDate,
        this.endDate,
        'dateRangeOptions',
      );
    }
  }

  set selectedDateRange(range: IDateRangeOption | undefined) {
    this._selectedDateRange = range;
  }

  @cached
  get selectedCompareToDateRange(): IDateRangeOption | undefined {
    if (this._selectedCompareToDateRange) {
      return this._selectedCompareToDateRange;
    }

    if (this.compareToStartDate && this.compareToEndDate) {
      return this.findDateRange(
        this.compareToStartDate,
        this.compareToEndDate,
        'compareToDateRangeOptions',
      );
    }
  }

  set selectedCompareToDateRange(range: IDateRangeOption | undefined) {
    this._selectedCompareToDateRange = range;
  }

  @cached
  get startDate(): Date {
    return this._startDate ?? this.args.startDate;
  }

  set startDate(date: Date) {
    this._startDate = date;
  }

  @cached
  get endDate(): Date | '-' {
    return this._endDate ?? this.args.endDate;
  }

  set endDate(date: Date | '-') {
    this._endDate = date;
  }

  @cached
  get compareToStartDate(): Date | undefined {
    return this._compareToStartDate ?? this.args.compareToStartDate;
  }

  set compareToStartDate(date: Date | undefined) {
    this._compareToStartDate = date;
  }

  @cached
  get compareToEndDate(): Date | undefined | '-' {
    return this._compareToEndDate ?? this.args.compareToEndDate;
  }

  set compareToEndDate(date: Date | undefined | '-') {
    this._compareToEndDate = date;
  }

  @cached
  get isFirstDateRangeInvalid(): boolean {
    return !this.startDate || !this.endDate || this.endDate === '-';
  }

  @cached
  get isApplyDisabled(): boolean {
    if (this.showCompareDate) {
      const isCompareToInvalid =
        !this.compareToStartDate ||
        !this.compareToEndDate ||
        this.compareToEndDate === '-';

      return this.isFirstDateRangeInvalid || isCompareToInvalid;
    }

    return this.isFirstDateRangeInvalid;
  }

  @cached
  get dateRangeOptions(): IDateRangeOption[] {
    const { intl } = this;

    return [
      {
        id: DateRangeIds.thisMonth,
        title: intl.t('date_ranges.this_month'),
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
      },
      {
        id: DateRangeIds.nextMonth,
        title: intl.t('date_ranges.next_month'),
        startDate: startOfMonth(addMonths(new Date(), 1)),
        endDate: endOfMonth(addMonths(new Date(), 1)),
      },
      {
        id: DateRangeIds.lastMonth,
        title: intl.t('date_ranges.last_month'),
        startDate: startOfMonth(subMonths(new Date(), 1)),
        endDate: endOfMonth(subMonths(new Date(), 1)),
      },
      {
        id: DateRangeIds.last3Months,
        title: intl.t('date_ranges.last_3_months'),
        startDate: startOfMonth(subMonths(new Date(), 2)),
        endDate: endOfMonth(new Date()),
      },
      {
        id: DateRangeIds.thisYear,
        title: intl.t('date_ranges.this_year'),
        startDate: startOfYear(new Date()),
        endDate: endOfYear(new Date()),
      },
      {
        id: DateRangeIds.lastYear,
        title: intl.t('date_ranges.last_year'),
        startDate: startOfYear(subYears(new Date(), 1)),
        endDate: endOfYear(subYears(new Date(), 1)),
      },
    ];
  }

  @cached
  get compareToDateRangeOptions(): IDateRangeOption[] {
    const { intl, selectedDateRange: range } = this;

    const start = range ? range.startDate : this.startDate;
    const end = range ? range.endDate : (this.endDate as Date);

    const months = differenceInMonths(end, start) + 1;

    const previousPeriodStart = startOfMonth(subMonths(start, months));
    const previousPeriodEnd = endOfMonth(subMonths(end, months));

    const years = Math.floor(months / 12) + 1;

    const samePeriodLastYearStart = startOfMonth(subYears(start, years));
    const samePeriodLastYearEnd = endOfMonth(subYears(end, years));

    return [
      {
        id: DateRangeIds.previousPeriod,
        title: intl.t('date_ranges.previous_period'),
        startDate: previousPeriodStart,
        endDate: previousPeriodEnd,
      },
      {
        id: DateRangeIds.samePeriodLastYear,
        title: intl.t('date_ranges.same_period_last_year'),
        startDate: samePeriodLastYearStart,
        endDate: samePeriodLastYearEnd,
      },
    ];
  }

  @action
  handleDateRangeChange(dateRange: IDateRangeOption): void {
    this.startDate = dateRange.startDate;
    this.endDate = dateRange.endDate;
    this.selectedDateRange = dateRange;

    this.dateRangePicker.setHighlightedDays(true);
  }

  @action
  handleCompareToDateRangeChange(dateRange: IDateRangeOption): void {
    this.compareToStartDate = dateRange.startDate;
    this.compareToEndDate = dateRange.endDate;
    this.selectedCompareToDateRange = dateRange;

    this.dateRangePicker.setHighlightedDays(true);
  }

  @action
  handleCompareToggle(): void {
    this.showCompareDate = !this.showCompareDate;

    if (this.showCompareDate) {
      this.handleInputFocus('compareToStartDate');
    }
  }

  @action
  handlePickerDateRangeInit(dr: UiPickerDateRangeComponent): void {
    this.dateRangePicker = dr;
  }

  @action
  handleApplySubmit(): void {
    const { startDate, endDate } = this;

    if (!startDate || !endDate) {
      return;
    }

    const params: IApplyCompareDateRangeParams = {
      start: utcDate(startDate),
      end: utcDate(endDate as Date),
      compareToStart: undefined,
      compareToEnd: undefined,
    };

    const { showCompareDate, compareToStartDate, compareToEndDate } = this;

    if (showCompareDate && compareToStartDate && compareToEndDate) {
      params.compareToStart = utcDate(compareToStartDate);
      params.compareToEnd = utcDate(compareToEndDate as Date);
    }

    this.args.onDateRangeSelect(params);

    this.dateRangePicker.dropdown?.closeDropdown();
  }

  @action
  handleClose(): void {
    this.dateRangePicker.clearSelection();
    this.dateRangePicker.dropdown?.closeDropdown();
  }

  @cached
  get selectingStartDate(): boolean {
    return this.activeSelection === 'startDate';
  }

  @cached
  get selectingEndDate(): boolean {
    return this.activeSelection === 'endDate';
  }

  @cached
  get selectingCompareToStartDate(): boolean {
    return this.activeSelection === 'compareToStartDate';
  }

  @cached
  get selectingCompareToEndDate(): boolean {
    return this.activeSelection === 'compareToEndDate';
  }

  @action
  handleDateSelect(date: DateTime): void {
    const newDate = date.toJSDate();

    if (this.selectingStartDate) {
      if (this.shouldResetEndDate(newDate, 'endDate')) {
        this.endDate = '-';
      }
    } else if (this.selectingEndDate) {
      if (this.isBeforeEndDate(newDate, 'startDate')) {
        return;
      }
    } else if (this.selectingCompareToStartDate) {
      if (this.shouldResetEndDate(newDate, 'compareToEndDate')) {
        this.compareToEndDate = '-';
      }
    } else if (this.selectingCompareToEndDate) {
      if (this.isBeforeEndDate(newDate, 'compareToStartDate')) {
        return;
      }
    }

    this[this.activeSelection] = newDate ?? '-';

    if (this.selectingStartDate) {
      this.activeSelection = 'endDate';
    } else if (this.selectingEndDate) {
      this.activeSelection = 'startDate';
    } else if (this.selectingCompareToStartDate) {
      this.activeSelection = 'compareToEndDate';
    } else if (this.selectingCompareToEndDate) {
      this.activeSelection = 'compareToStartDate';
    }
    this.dateRangePicker.setLockDates();

    this.dateRangePicker.setHighlightedDays();

    this.selectedDateRange = undefined;
  }

  @action
  handleInputFocus(selection: ActiveSelection): void {
    this.activeSelection = selection;

    scheduleTask(this, 'render', () => {
      this.dateRangePicker.setLockDates();
    });
  }

  @action
  handleDateRangeDestroy(): void {
    this.startDate = this.args.startDate;
    this.endDate = this.args.endDate;
    this.compareToStartDate = this.args.compareToStartDate;
    this.compareToEndDate = this.args.compareToEndDate;
    this.selectedDateRange = undefined;
    this.selectedCompareToDateRange = undefined;

    this.setState();
  }

  private findDateRange(
    startDate: Date,
    endDate: Date | string,
    field: 'dateRangeOptions' | 'compareToDateRangeOptions',
  ): IDateRangeOption | undefined {
    if (!isDate(endDate)) {
      return;
    }

    return this[field].find(
      (item) =>
        isSameDay(item.startDate, startDate) &&
        isSameDay(item.endDate, endDate),
    );
  }

  private setState(): void {
    this.showCompareDate = !!(this.compareToStartDate && this.compareToEndDate);
  }

  private isBeforeEndDate(
    newDate: Date,
    startField: 'startDate' | 'compareToStartDate',
  ): boolean {
    return (
      isDate(this[startField]) && isBefore(newDate, this[startField] as Date)
    );
  }

  private shouldResetEndDate(
    newDate: Date,
    endField: 'endDate' | 'compareToEndDate',
  ): boolean {
    return isDate(this[endField]) && isAfter(newDate, this[endField] as Date);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::CompareDateRange': typeof UiCompareDateRangeComponent;
  }
}
