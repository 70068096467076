import Component from '@glimmer/component';
import { type Registry as Services, inject as service } from '@ember/service';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import BookingModel from 'uplisting-frontend/models/booking';
import SavedReplyModel from 'uplisting-frontend/models/saved-reply';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { isSaveDisabled } from 'uplisting-frontend/utils';

interface IArgs {
  savedReply?: SavedReplyModel;
  booking: BookingModel;
}

interface SavedReplyFormSignature {
  Element: null;

  Args: IArgs;
}

export default class UiSavedReplyFormComponent extends Component<SavedReplyFormSignature> {
  @service notifications!: Services['notifications'];

  @service('repositories/saved-reply')
  savedReplyRepository!: Services['repositories/saved-reply'];

  @cached
  get booking(): BookingModel {
    return this.args.booking;
  }

  @cached
  get changeset(): GenericChangeset<SavedReplyModel> {
    return this.savedReplyRepository.buildChangeset(this.args.savedReply);
  }

  @action
  handleWillDestroy(): void {
    this.changeset.rollback();

    if (!this.changeset.id && !this.isDestroying) {
      this.changeset.data.unloadRecord();
    }
  }

  @action
  async handleFormSubmit(): Promise<void> {
    const saveDisabled = await isSaveDisabled(this.changeset);

    if (saveDisabled) {
      return;
    }

    try {
      await this.changeset.save();

      this.notifications.info();
    } catch {
      this.notifications.error();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::SavedReply::Form': typeof UiSavedReplyFormComponent;
  }
}
