import Model, { attr, belongsTo } from '@ember-data/model';
import BookingModel from 'uplisting-frontend/models/booking';
import { type IBookingActionSchema } from 'uplisting-frontend/models/schemas';

export default class BookingActionModel
  extends Model
  implements IBookingActionSchema
{
  @attr('boolean') confirmPending!: boolean;

  @belongsTo('booking', { async: false, inverse: null })
  booking!: BookingModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'booking-action': BookingActionModel;
  }
}
