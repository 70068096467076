import Service, { type Registry as Services, service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import UserModel from 'uplisting-frontend/models/user';

interface IError {
  status: '401' | '402' | '403' | '404' | '409';
}

interface IFetchError {
  status?: number;
  errors: IError[];
}

function isErrorOfStatus(err: IFetchError, status: number): boolean {
  return (
    err.errors?.some((error) => error.status === `${status}`) ||
    err?.status === status
  );
}

export default class SessionService extends Service {
  @service router!: Services['router'];
  @service sentry!: Services['sentry'];
  @service('repositories/user') userRepository!: Services['repositories/user'];

  @cached @tracked currentUser!: UserModel;

  public async fetchCurrentUser(): Promise<void> {
    if (this.currentUser) {
      return;
    }

    try {
      const user = await this.userRepository.findRecord('me', {
        include: 'customer,permissions',
      });

      this.currentUser = user;

      this.sentry.configure({
        id: this.currentUser.id,
        email: this.currentUser.email,
      });
    } catch (err) {
      const isLoggedOut = this.isAuthenticationError(err as IFetchError);
      const isConflictError = this.isConflictError(err as IFetchError);

      if (isLoggedOut) {
        this.handleLogout();
      } else if (isConflictError) {
        this.router.transitionTo('account.verify');
      } else {
        this.sentry.captureException(err);
      }
    }
  }

  public handleLogout(): void {
    window.open('/logout', '_self');
  }

  private isAuthenticationError(err: IFetchError): boolean {
    return isErrorOfStatus(err, 401);
  }

  private isConflictError(err: IFetchError): boolean {
    return isErrorOfStatus(err, 409);
  }
}

declare module '@ember/service' {
  interface Registry {
    session: SessionService;
  }
}
