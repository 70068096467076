import Model, { attr, belongsTo } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import {
  type IGuestIdentityVerificationSchema,
  GuestIdentityVerificationStatus,
} from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';

export default class GuestIdentityVerificationModel
  extends Model
  implements IGuestIdentityVerificationSchema
{
  @attr('string') status!: GuestIdentityVerificationStatus;

  @cached
  get succeeded(): boolean {
    return [
      GuestIdentityVerificationStatus.succeeded,
      GuestIdentityVerificationStatus.verified,
    ].includes(this.status);
  }

  @belongsTo('booking', { inverse: 'guestIdentityVerification', async: false })
  booking!: BookingModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'guest-identity-verification': GuestIdentityVerificationModel;
  }
}
