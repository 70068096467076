import Model, { attr, belongsTo } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import {
  type IScheduledMessageSchema,
  ScheduledMessageStatus,
} from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';
import AutomatedMessageRuleModel from 'uplisting-frontend/models/automated-message-rule';

export default class ScheduledMessageModel
  extends Model
  implements IScheduledMessageSchema
{
  @service intl!: Services['intl'];

  @attr('boolean') enabled!: boolean;
  @attr('string') lastError!: string;
  @attr('date') scheduledFor!: Date;
  @attr('date') sentAt!: Date;
  @attr('string') status!: ScheduledMessageStatus;

  @belongsTo('booking', { async: false, inverse: 'scheduledMessages' })
  booking!: BookingModel;

  @belongsTo('automated-message-rule', {
    async: false,
    inverse: null,
  })
  automatedMessageRule!: AutomatedMessageRuleModel;

  @cached
  get sendingStopped(): boolean {
    return [
      ScheduledMessageStatus.sent,
      ScheduledMessageStatus.tooLate,
      ScheduledMessageStatus.failed,
    ].includes(this.status);
  }

  @cached
  get prettyStatus(): string {
    let translationKey = 'action_bookings_logs.scheduled_message_statuses.';

    translationKey += this.enabled === false ? 'disabled' : this.status;

    return this.intl.t(translationKey);
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'scheduled-message': ScheduledMessageModel;
  }
}
