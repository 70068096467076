import Model, { attr, belongsTo } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { type IChangeoverSettingSchema } from 'uplisting-frontend/models/schemas';
import PropertyModel from 'uplisting-frontend/models/property';

export default class ChangeoverSettingModel
  extends Model
  implements IChangeoverSettingSchema
{
  @attr('boolean') enabled!: boolean;
  @attr('boolean') automatic!: boolean;

  @belongsTo('property', { inverse: 'changeoverSetting', async: false })
  property!: PropertyModel;

  @cached
  get manual(): boolean {
    return !this.automatic;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'changeover-setting': ChangeoverSettingModel;
  }
}
