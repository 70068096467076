import Model, { attr, belongsTo } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import {
  type IBalanceAdjustmentSchema,
  BalanceAdjustmentType,
} from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';

export default class BalanceAdjustmentModel
  extends Model
  implements IBalanceAdjustmentSchema
{
  @attr('number') amount!: number;
  @attr('string') note!: string;
  @attr('string') type!: BalanceAdjustmentType;
  @attr('date') createdAt!: Date;

  @belongsTo('booking', {
    inverse: 'balanceAdjustments',
    async: false,
  })
  booking!: BookingModel;

  @cached
  get adjustedAmount(): number {
    return this.amount;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'balance-adjustment': BalanceAdjustmentModel;
  }
}
