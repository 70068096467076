import { type Registry as Services, inject as service } from '@ember/service';
import BaseController from 'uplisting-frontend/pods/base/controller';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';

export default class ConnectCallbackEdgestateController extends BaseController {
  @service analytics!: Services['analytics'];

  queryParams = ['code', 'error'];
  code;
  error;

  public async handleCallbackResponse(): Promise<void> {
    if (this.error) {
      await this.openEdgeState(true);
    } else {
      await this.edgestateResponse();
    }
  }

  private async edgestateResponse(): Promise<void> {
    const connection = this.store.createRecord('edgestate-connection', {
      code: this.code,
    });

    let problem;

    try {
      await connection.save();

      this.analytics.trackEvent(AnalyticsEvents.edgestateAccountConnected);
    } catch {
      problem = true;

      this.store.unloadAll('edgestate-connection');
    } finally {
      await this.openEdgeState(problem);
    }
  }

  private async openEdgeState(problem?: boolean): Promise<void> {
    await this.router.transitionTo('connect.edgestate', {
      queryParams: {
        problem,
      },
    });
  }
}
