import { type Registry as Services, inject as service } from '@ember/service';
import BaseRoute from 'uplisting-frontend/pods/base/route';

export default class ConnectWebhookRoute extends BaseRoute {
  @service('repositories/zapier-api-key')
  zapierApiKeyRepository!: Services['repositories/zapier-api-key'];

  model() {
    return this.zapierApiKeyRepository.getDefaultRecord();
  }
}
