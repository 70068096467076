import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import BaseController from 'uplisting-frontend/pods/base/controller';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import BookingRentalAgreementSettingModel from 'uplisting-frontend/models/booking-rental-agreement-setting';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { isSaveDisabled } from 'uplisting-frontend/utils';

interface IChannel {
  field:
    | 'enabledForUplisting'
    | 'enabledForBookingDotCom'
    | 'enabledForAirbnb'
    | 'enabledForHomeAway';
  translation: string;
}

export default class AutomateRentalAgreementsController extends BaseController {
  @service analytics!: Services['analytics'];

  @service('repositories/booking-rental-agreement-setting')
  bookingRentalAgreementSettingRepository!: Services['repositories/booking-rental-agreement-setting'];

  @cached
  @tracked
  rentalAgreementSetting!: BookingRentalAgreementSettingModel;

  @cached
  get changeset(): GenericChangeset<BookingRentalAgreementSettingModel> {
    return this.bookingRentalAgreementSettingRepository.buildChangeset(
      this.rentalAgreementSetting,
    );
  }

  channels: IChannel[] = [
    {
      field: 'enabledForUplisting',
      translation: 'uplisting',
    },

    {
      field: 'enabledForBookingDotCom',
      translation: 'booking_dot_com',
    },

    {
      field: 'enabledForAirbnb',
      translation: 'airbnb',
    },

    {
      field: 'enabledForHomeAway',
      translation: 'vrbo',
    },
  ];

  @action
  handleCancel(): void {
    this.changeset.rollback();
  }

  @action
  async handleSave(): Promise<void> {
    const saveDisabled = await isSaveDisabled(this.changeset);

    if (saveDisabled) {
      return;
    }

    try {
      await this.changeset.save();

      this.analytics.trackEvent(
        AnalyticsEvents.bookingRentalAgreementSettingEdit,
      );
      this.notifications.info();
    } catch {
      this.notifications.error();
    }
  }

  @action
  async handleToggleRentalAgreementSetting(channel: IChannel): Promise<void> {
    const { rentalAgreementSetting } = this;
    const { field, translation } = channel;
    const prevValue = rentalAgreementSetting[field];
    const isEnabled = !prevValue;

    try {
      rentalAgreementSetting[field] = isEnabled;

      await rentalAgreementSetting.save();

      const event = isEnabled
        ? AnalyticsEvents.bookingRentalAgreementSettingEnabled
        : AnalyticsEvents.bookingRentalAgreementSettingDisabled;

      const { intl } = this;

      this.notifications.info(
        'automate_rental_agreements.notifications.saved',
        {
          status: intl.t(
            `automate_rental_agreements.notifications.status.${isEnabled ? 'enabled' : 'disabled'}`,
          ),
          name: intl.t(
            `automate_rental_agreements.notifications.channels.${translation}`,
          ),
        },
      );

      this.analytics.trackEvent(event);
    } catch {
      rentalAgreementSetting[field] = prevValue;

      this.notifications.error();
    }
  }
}
