import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import sumBy from 'lodash-es/sumBy';
import ClientStatementModel from 'uplisting-frontend/models/client-statement';
import ClientStatementExpenseModel from 'uplisting-frontend/models/client-statement-expense';
import ClientStatementReportModel from 'uplisting-frontend/models/client-statement-report';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { ClientStatementStatus } from 'uplisting-frontend/models/schemas';
import { isSaveDisabled } from 'uplisting-frontend/utils';

interface IArgs {
  statement: ClientStatementModel;
}

interface StatementsEditSignature {
  Element: null;

  Args: IArgs;
}

export default class UiStatementsEditComponent extends Component<StatementsEditSignature> {
  @service('repositories/tax') taxRepository!: Services['repositories/tax'];

  @service('repositories/client-statement-expense')
  clientStatementExpenseRepository!: Services['repositories/client-statement-expense'];

  @cached @tracked changeset?: GenericChangeset<ClientStatementExpenseModel>;

  @cached @tracked showDeleteExpenseModal = false;
  @cached @tracked expenseToDelete?: ClientStatementExpenseModel;

  @cached
  get expenses(): ClientStatementExpenseModel[] {
    return this.statement.expenses.filter((expense) => !expense.isNew);
  }

  @cached
  get isEditable(): boolean {
    return this.statement.status === ClientStatementStatus.draft;
  }

  @cached
  get canAddExpense(): boolean {
    return this.isEditable && !this.changeset;
  }

  @cached
  get statement(): ClientStatementModel {
    return this.args.statement;
  }

  @cached
  get report(): ClientStatementReportModel {
    return this.statement.report;
  }

  @cached
  get expensesSum(): number {
    return sumBy(this.expenses, 'amountWithoutTax');
  }

  @cached
  get expensesWithTaxSum(): number {
    return sumBy(this.expenses, 'amountWithTax');
  }

  @action
  handleShowDeleteExpenseModal(expense: ClientStatementExpenseModel): void {
    this.showDeleteExpenseModal = true;

    this.expenseToDelete = expense;
  }

  @action
  handleCloseDeleteExpenseModal(): void {
    this.showDeleteExpenseModal = false;

    this.expenseToDelete = undefined;
  }

  @action
  handleAddExpense(): void {
    if (!this.canAddExpense) {
      return;
    }

    const expense = this.clientStatementExpenseRepository.createRecord({
      clientStatement: this.statement,
      tax: this.taxRepository.defaultTaxOption,
    });

    this.changeset =
      this.clientStatementExpenseRepository.buildChangeset(expense);
  }

  @action
  handleResetExpense(): void {
    if (!this.changeset?.id) {
      this.changeset?.data.unloadRecord();
    }

    this.changeset = undefined;
  }

  @action
  async handleDeleteExpense(): Promise<void> {
    await (this.expenseToDelete as ClientStatementExpenseModel).destroyRecord();

    await this.statement.reload();

    this.expenseToDelete = undefined;
  }

  @action
  async handleSaveExpense(): Promise<void> {
    const saveDisabled = await isSaveDisabled(this.changeset);

    if (saveDisabled) {
      return;
    }

    await (
      this.changeset as GenericChangeset<ClientStatementExpenseModel>
    ).save();

    this.handleResetExpense();

    await this.statement.reload();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Statements::Edit': typeof UiStatementsEditComponent;
  }
}
