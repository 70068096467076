import type Transition from '@ember/routing/transition';
import { hash } from 'rsvp';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import ActionChangeoversSummaryController from 'uplisting-frontend/pods/action/changeovers/summary/controller';
import ChangeoverModel from 'uplisting-frontend/models/changeover';
import { UserRoles } from 'uplisting-frontend/models/schemas';

export default class ActionChangeoversSummaryRoute extends PermittedRoute {
  permission = 'calendar.changeovers.summary';

  model() {
    const changeover = this.modelFor('action.changeovers') as ChangeoverModel;

    if (
      changeover.property.changeoverSetting.automatic &&
      changeover.teammate
    ) {
      return changeover;
    }

    return hash({
      changeover,
      teammates: this.store.query('teammate', {
        property_id: changeover.property.id,
        role: UserRoles.housekeeper,
      }),
    });
  }

  setupController(
    controller: ActionChangeoversSummaryController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, model);
  }
}
