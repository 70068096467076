import CalendarModel from 'uplisting-frontend/models/calendar';
import OccasionModel from 'uplisting-frontend/models/occasion';
import PropertyModel from 'uplisting-frontend/models/property';
import PriceModel from 'uplisting-frontend/models/price';
import MessageModel from 'uplisting-frontend/models/message';
import LockCodeModel from 'uplisting-frontend/models/lock-code';
import MultiUnitModel from 'uplisting-frontend/models/multi-unit';
import ChannelUserModel from 'uplisting-frontend/models/channel-user';
import GuestInvoiceModel from 'uplisting-frontend/models/guest-invoice';
import BookingPaymentModel from 'uplisting-frontend/models/booking-payment';
import HomeawayActionModel from 'uplisting-frontend/models/homeaway-action';
import SecurityDepositModel from 'uplisting-frontend/models/security-deposit';
import ScheduledMessageModel from 'uplisting-frontend/models/scheduled-message';
import BalanceAdjustmentModel from 'uplisting-frontend/models/balance-adjustment';
import HistoryBookingPriceModel from 'uplisting-frontend/models/history-booking-price';
import BookingDotComActionModel from 'uplisting-frontend/models/booking-dot-com-action';
import BookingPaymentActionModel from 'uplisting-frontend/models/booking-payment-action';
import SecurityDepositActionModel from 'uplisting-frontend/models/security-deposit-action';
import BookingRentalAgreementModel from 'uplisting-frontend/models/booking-rental-agreement';
import AdditionalBookingChargeModel from 'uplisting-frontend/models/additional-booking-charge';
import BookingManagementFeeRuleModel from 'uplisting-frontend/models/booking-management-fee-rule';
import GuestIdentityVerificationModel from 'uplisting-frontend/models/guest-identity-verification';
import BookingManagementFeeSettingModel from 'uplisting-frontend/models/booking-management-fee-setting';
import BookingRentalAgreementActionModel from 'uplisting-frontend/models/booking-rental-agreement-action';
import GuestIdentityVerificationActionModel from 'uplisting-frontend/models/guest-identity-verification-action';

export enum BookingStatus {
  accepted = 'accepted',
  approved = 'approved',
  cancelled = 'cancelled',
  denied = 'denied',
  rejected = 'rejected',
  timedout = 'timedout',
  externallyRemoved = 'externally_removed',
  booked = 'booked',
  notPossible = 'not_possible',
  declined = 'declined',
  active = 'active',
  needsCheckOut = 'needs_check_out',
  needsCheckIn = 'needs_check_in',
  expired = 'expired',
  checkedIn = 'checked_in',
  checkedOut = 'checked_out',
  pending = 'pending',
  confirmed = 'confirmed',
}

export enum BookingSource {
  apartmentsOnline = 'apartments_online',
  bookingSiteConversion = 'booking_site_conversion',
  email = 'email',
  extension = 'extension',
  livinc = 'livinc',
  openRent = 'open_rent',
  phone = 'phone',
  referral = 'referral',
  silverDoor = 'silver_door',
  situ = 'situ',
  vrbo = 'vrbo',
  website = 'website',
}

export enum BookingChannel {
  agoda = 'agoda',
  airbnb = 'airbnb',
  airbnbOfficial = 'airbnb_official',
  arendoo = 'arendoo',
  bookingDotCom = 'booking_dot_com',
  canadaStays = 'canada_stays',
  canopyAndStars = 'canopy_and_stars',
  expedia = 'expedia',
  ferieboligkbh = 'ferieboligkbh',
  google = 'google',
  homeAway = 'home_away',
  lodgify = 'lodgify',
  misterbandb = 'misterbandb',
  nineFlats = 'nine_flats',
  oneDay = 'one_day',
  oneRooftop = 'one_rooftop',
  partnerIcal = 'partner_ical',
  plumGuide = 'plum_guide',
  selectCornwall = 'select_cornwall',
  tripAdvisor = 'trip_advisor',
  uplisting = 'uplisting',
  uplistingIcal = 'uplisting_ical',
  vrbo = 'vrbo',
  wimdu = 'wimdu',
  wpRentals = 'wp_rentals',
}

export interface IBookingSchema {
  arrivalTime: string;
  channel: BookingChannel;
  checkIn: string;
  checkOut: string;
  departureTime: string;
  guestName: string;
  guestPhone: string;
  status: BookingStatus;
  guestEmail: string;
  channelName: string;
  preferredGuestName?: string;
  imported: boolean;
  deletable: boolean;
  createdAt: Date;
  bookedAt: Date;
  note: string;
  externalReservationId: string;
  externalReservationLink: string;
  viaApi: boolean;
  automatedMessagesEnabled: boolean;
  automatedReviewsEnabled: boolean;
  automatedReviewSent: boolean;
  problematic: boolean;
  expiresIn: number;
  bookingConfirmationPageUrl: string;
  lockCodeEditable: boolean;
  customLockCode: string | null;
  direct: boolean;
  source: BookingSource;

  occasion: OccasionModel;
  property: PropertyModel;
  newProperty?: PropertyModel;
  calendar: CalendarModel;
  price: PriceModel;
  multiUnit?: MultiUnitModel;
  guestIdentityVerification?: GuestIdentityVerificationModel;
  channelUser: ChannelUserModel;
  bookingPayment?: BookingPaymentModel;
  lockCode?: LockCodeModel;
  securityDeposit: SecurityDepositModel;
  homeawayAction: HomeawayActionModel;
  bookingDotComAction: BookingDotComActionModel;
  bookingPaymentAction: BookingPaymentActionModel;
  securityDepositAction: SecurityDepositActionModel;
  bookingRentalAgreement: BookingRentalAgreementModel;
  bookingRentalAgreementAction: BookingRentalAgreementActionModel;
  guestIdentityVerificationAction: GuestIdentityVerificationActionModel;
  managementFeeRule: BookingManagementFeeRuleModel;
  bookingManagementFeeSetting?: BookingManagementFeeSettingModel;
  messages: MessageModel[];
  scheduledMessages: ScheduledMessageModel[];
  guestInvoices: GuestInvoiceModel[];
  balanceAdjustments: BalanceAdjustmentModel[];
  priceChanges: HistoryBookingPriceModel[];
  pendingAdditionalCharges: AdditionalBookingChargeModel[];
}
