import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

interface IArgs {
  translationKey: string;
  showYieldedBlock?: boolean;
  type?: 'primary';
  isDisabled?: boolean;
  onApply(): void;
}

export interface BookingConfirmableModalSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    default: [];
  };
}

export default class UiBookingConfirmableModalComponent extends Component<BookingConfirmableModalSignature> {
  @cached @tracked showConfirmModal = false;

  @cached
  get translationKey(): string {
    return this.args.translationKey;
  }

  @cached
  get showYieldedBlock(): boolean {
    return this.args.showYieldedBlock ?? false;
  }

  @cached
  get type(): 'secondary' | 'primary' {
    return this.args.type ?? 'secondary';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::ConfirmableModal': typeof UiBookingConfirmableModalComponent;
  }
}
