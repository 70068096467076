import Component from '@glimmer/component';
import Model from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { guidFor } from '@ember/object/internals';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { debounceTask } from 'ember-lifeline';
import { format } from 'date-fns/format';
import { startOfDay } from 'date-fns/startOfDay';
import { endOfDay } from 'date-fns/endOfDay';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import { addMinutes } from 'date-fns/addMinutes';
import { isAfter } from 'date-fns/isAfter';
import { addDays } from 'date-fns/addDays';
import { dasherize } from '@ember/string';
import { utcDate, isBetweenDays } from 'uplisting-frontend/utils';
import {
  BookingStatus,
  BookingSource,
} from 'uplisting-frontend/models/schemas';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import BookingModel from 'uplisting-frontend/models/booking';
import UserModel from 'uplisting-frontend/models/user';
import LockCodeModel from 'uplisting-frontend/models/lock-code';
import BookingActionModel from 'uplisting-frontend/models/booking-action';
import HomeawayActionModel from 'uplisting-frontend/models/homeaway-action';
import BookingDotComActionModel from 'uplisting-frontend/models/booking-dot-com-action';
import BookingPaymentActionModel from 'uplisting-frontend/models/booking-payment-action';
import SecurityDepositActionModel from 'uplisting-frontend/models/security-deposit-action';
import GuestIdentityVerificationModel from 'uplisting-frontend/models/guest-identity-verification';
import BookingRentalAgreementActionModel from 'uplisting-frontend/models/booking-rental-agreement-action';
import GuestIdentityVerificationActionModel from 'uplisting-frontend/models/guest-identity-verification-action';

interface IBookingAction {
  enabled: boolean;
  isCompleted: boolean;
  translationKey: string;
  classNames: string;
  onApply(): void;
}

interface IArgs {
  booking: BookingModel;
  editDetailsRoute: string;
  sendMessageRoute: string;
}

export interface BookingSummarySignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

function timeOptionToTime(option: string): string {
  const isAm = option.includes('am');
  const [hours, minutes] = option
    .replaceAll(/pm|am/gi, '')
    .split(':')
    .map(Number) as [number, number];

  let newHours = isAm ? hours : hours + 12;

  if (isAm && newHours === 12) {
    newHours = 0;
  } else if (!isAm && newHours === 24) {
    newHours = 12;
  }

  const date = utcDate(new Date());

  date.setHours(newHours, minutes, 0);

  return format(date, 'H:mm:ss');
}

export default class UiBookingSummaryComponent extends Component<BookingSummarySignature> {
  @service store!: Services['store'];
  @service router!: Services['router'];
  @service session!: Services['session'];
  @service analytics!: Services['analytics'];
  @service notifications!: Services['notifications'];
  @service('repositories/account-configuration')
  accountConfigurationRepository!: Services['repositories/account-configuration'];

  timeFormat = 'h:mmaaa';

  @cached
  get booking(): BookingModel {
    return this.args.booking;
  }

  @cached
  get id(): string {
    return guidFor(this);
  }

  @cached
  get currentUser(): UserModel {
    return this.session.currentUser;
  }

  @cached
  get canMessageGuest(): boolean {
    return (
      this.currentUser.hasAccessTo('bookings.messages') &&
      this.booking.canSendMessage
    );
  }

  @cached
  get showBookingPaymentStatus() {
    if (this.booking.isIcal || this.booking.isPending) {
      return false;
    }

    return true;
  }

  @cached
  get showGuestIdentityVerificationStatus(): boolean {
    return !!this.booking.guestIdentityVerification;
  }

  @cached
  get guestIdentityVerification(): GuestIdentityVerificationModel {
    return this.booking
      .guestIdentityVerification as GuestIdentityVerificationModel;
  }

  @cached
  get showLockCodeStatus(): boolean {
    return !!this.booking.lockCode;
  }

  @cached
  get lockCode(): LockCodeModel {
    return this.booking.lockCode as LockCodeModel;
  }

  @cached
  get showSecurityDepositStatus(): boolean {
    return !!this.booking.securityDeposit;
  }

  @cached
  get showRentalAgreementStatus(): boolean {
    return !!this.booking.bookingRentalAgreement;
  }

  @cached
  get timeOptions(): string[] {
    const start = startOfDay(new Date());
    const end = endOfDay(new Date());
    const step = 15; // in minutes
    const diff = differenceInMinutes(end, start);
    const stepsCount = Math.ceil(diff / step);

    return Array(stepsCount)
      .fill(undefined)
      .map((_, i) => format(addMinutes(start, i * step), this.timeFormat));
  }

  @cached
  get bookingActions(): IBookingAction[] {
    const {
      booking: {
        isCheckedIn,
        isCheckedOut,
        needsCheckIn,
        needsCheckOut,
        isCheckoutToday,
      },
    } = this;

    return [
      {
        enabled: needsCheckIn,
        isCompleted: isCheckedIn || isCheckedOut,
        classNames: 'booking-check-in',
        get translationKey(): string {
          return `action_bookings_summary.actions.check_guest_in.${this.isCompleted ? 'after' : 'before'}`;
        },
        onApply: () => {
          this.updateStatus(BookingStatus.checkedIn);
        },
      },
      {
        enabled: (needsCheckOut || isCheckoutToday) && !isCheckedOut,
        isCompleted: isCheckedOut,
        classNames: 'booking-check-out',
        get translationKey(): string {
          return `action_bookings_summary.actions.check_guest_out.${this.isCompleted ? 'after' : 'before'}`;
        },
        onApply: () => {
          this.updateStatus(BookingStatus.checkedOut);
        },
      },
    ];
  }

  @cached
  get automatedReviewsEnabled(): boolean {
    return this.accountConfigurationRepository.currentAccountConfiguration
      .automatedReviewsEnabled;
  }

  @cached
  get hasAutomatedReviews(): boolean {
    return this.automatedReviewsEnabled && this.booking.isAirbnbOfficial;
  }

  @cached
  get canToggleAutomatedReviews(): boolean {
    return this.automatedReviewsEnabled && !this.booking.automatedReviewSent;
  }

  @cached
  get showReportCancellationByOwner(): boolean {
    const { booking } = this;

    return booking.isVrbo && isAfter(booking.checkOut, new Date());
  }

  @cached
  get showReportCancellationByTraveller(): boolean {
    return this.showReportCancellationByOwner;
  }

  @cached
  get showReportInvalidCard(): boolean {
    const { booking } = this;

    if (!booking.isBookingDotCom) {
      return false;
    }

    return isAfter(booking.checkIn, new Date());
  }

  @cached
  get showReportNoShow(): boolean {
    const { booking } = this;

    if (!booking.isBookingDotCom) {
      return false;
    }

    const { checkInDate } = booking;

    return isBetweenDays(
      new Date(),
      addDays(checkInDate, 1),
      addDays(checkInDate, 2),
    );
  }

  @cached
  get showBookingPaymentMarkAsPaid(): boolean {
    const { booking } = this;

    if (booking.isPending || !booking.isPayable) {
      return false;
    }

    if (!booking.bookingPayment) {
      return true;
    }

    return booking.bookingPayment.canBeMarkedAsPaid;
  }

  @cached
  get hasOtherActions(): boolean {
    const actionFields = [
      'showReportCancellationByOwner',
      'showReportCancellationByTraveller',
      'showReportInvalidCard',
      'showReportNoShow',
      'showSecurityDepositStatus',
      'showBookingPaymentMarkAsPaid',
      'showRentalAgreementStatus',
      'showGuestIdentityVerificationStatus',
    ];

    return actionFields.some((field) => this[field]);
  }

  @cached
  get showCustomLockCodeInput(): boolean {
    return this.booking.lockCodeEditable;
  }

  @cached
  get customLockCode(): string {
    const { booking } = this;

    return booking.customLockCode || booking.lockCode?.pin || '';
  }

  @cached
  get canChangeSource(): boolean {
    const { booking } = this;

    return booking.isManual || booking.source == BookingSource.vrbo;
  }

  @cached
  get canMoveBooking(): boolean {
    return (
      this.currentUser.hasAccessTo('calendar.moveEvent') &&
      this.booking.isMoveable
    );
  }

  @action
  handleSaveExpiresIn(event: Event): void {
    debounceTask(this, 'saveExpiresIn', event, 500);
  }

  @action
  async handleMarkBookingAsConfirmed(): Promise<void> {
    await this.handleGenericSave<BookingActionModel>(
      'bookingAction',
      'action_bookings_summary.mark_as_confirmed.notifications.applied',
      { confirmPending: true },
      undefined,
      true,
    );
  }

  @action
  async handleSelectTime(
    field: 'arrivalTime' | 'departureTime',
    option: string,
  ): Promise<void> {
    this.booking[field] = timeOptionToTime(option);

    await this.booking.save();
  }

  @action
  async handleToggleAutomatedMessages(): Promise<void> {
    const { booking } = this;

    booking.automatedMessagesEnabled = !booking.automatedMessagesEnabled;

    await booking.save();
  }

  @action
  async handleToggleAutomatedReviews(): Promise<void> {
    const { booking } = this;

    booking.automatedReviewsEnabled = !booking.automatedReviewsEnabled;

    await booking.save();

    const event = booking.automatedReviewsEnabled
      ? AnalyticsEvents.bookingAutomatedReviewsEnabled
      : AnalyticsEvents.bookingAutomatedReviewsDisabled;

    this.analytics.trackEvent(event);
  }

  @action
  async handleMarkBookingDotComAsNoShowAction(): Promise<void> {
    await this.handleGenericSave<BookingDotComActionModel>(
      'bookingDotComAction',
      'action_bookings_summary.other_actions.booking_dot_com_no_show.notifications.applied',
      { noShow: true },
      { noShow: false },
    );
  }

  @action
  async handleBookingDotComInvalidCard(): Promise<void> {
    await this.handleGenericSave<BookingDotComActionModel>(
      'bookingDotComAction',
      'action_bookings_summary.other_actions.booking_dot_com_invalid_card.notifications.applied',
      { invalidCreditCard: true },
      { invalidCreditCard: false },
    );
  }

  @action
  async handleVrboCancelBy(type: 'owner' | 'traveller'): Promise<void> {
    const record = this.getRecordFor<HomeawayActionModel>('homeawayAction');

    const field =
      type === 'owner' ? 'cancelledByOwner' : 'cancelledByTraveller';

    record[field] = true;

    try {
      await record.save();

      await record.booking.reload();

      this.notifications.info(
        'action_bookings_summary.other_actions.vrbo_cancellation_by_owner.notifications.applied',
      );

      const url = this.router.urlFor('calendar');

      window.open(url, '_self');
    } catch {
      record[field] = false;

      this.notifications.error();
    }
  }

  @action
  async handleOverrideSecurityDepositAction(): Promise<void> {
    await this.handleGenericSave<SecurityDepositActionModel>(
      'securityDepositAction',
      'action_bookings_summary.other_actions.security_deposit.notifications.applied',
      { overridden: true },
      { overridden: false },
    );
  }

  @action
  async handleMarkBookingPaymentActionAsPaid(): Promise<void> {
    await this.handleGenericSave<BookingPaymentActionModel>(
      'bookingPaymentAction',
      'action_bookings_summary.other_actions.booking_payment.notifications.applied',
      { markedAsPaid: true },
      { markedAsPaid: false },
    );
  }

  @action
  async handleMarkBookingRentalAgreementAsSigned(): Promise<void> {
    await this.handleGenericSave<BookingRentalAgreementActionModel>(
      'bookingRentalAgreementAction',
      'action_bookings_summary.other_actions.rental_agreement_sign.notifications.applied',
      { reset: false, markedAsSigned: true },
      { reset: false, markedAsSigned: false },
      true,
    );
  }

  @action
  async handleMarkBookingRentalAgreementAsReset(): Promise<void> {
    await this.handleGenericSave<BookingRentalAgreementActionModel>(
      'bookingRentalAgreementAction',
      'action_bookings_summary.other_actions.rental_agreement_reset.notifications.applied',
      { reset: true, markedAsSigned: false },
      { reset: false, markedAsSigned: false },
      true,
    );
  }

  @action
  async handleMarkGuestIdentityVerificationAsNotNeeded(): Promise<void> {
    await this.handleGenericSave<GuestIdentityVerificationActionModel>(
      'guestIdentityVerificationAction',
      'action_bookings_summary.other_actions.guest_identity_verification.notifications.applied',
      { overridden: true },
    );
  }

  @action
  handleInputLockCode(event: Event): void {
    const value = (event.target as HTMLInputElement).value;

    this.booking.customLockCode = value;
  }

  @action
  async handleSaveCustomLockCode(): Promise<void> {
    try {
      await this.booking.save();

      this.notifications.info(
        'action_bookings_summary.lock_code.notifications.applied',
      );

      this.booking.customLockCode = null;
    } catch {
      this.notifications.error();
    }
  }

  private async saveExpiresIn(event: Event): Promise<void> {
    const value = (event.target as HTMLInputElement).value;

    if (isEmpty(value)) {
      return;
    }

    this.booking.expiresIn = Number(value);

    await this.booking.save();

    this.notifications.info('action_bookings_summary.expires_in.success');
  }

  private async updateStatus(status: BookingStatus) {
    this.booking.status = status;

    await this.booking.save();
  }

  private async handleGenericSave<T extends Model>(
    relationship: string,
    notificationKey: string,
    updateData: object,
    resetData?: object,
    reloadBooking?: boolean,
  ): Promise<void> {
    const record = this.getRecordFor<T>(relationship);

    Object.assign(record, updateData);

    try {
      await record.save();

      this.notifications.info(notificationKey);

      if (reloadBooking) {
        await this.booking.reload();
      }
    } catch {
      this.notifications.error();

      if (resetData) {
        Object.assign(record, resetData);
      }
    }
  }

  private getRecordFor<T>(relationship: string): T {
    const { store, booking } = this;

    const id = booking[relationship]?.id;
    const modelName = dasherize(relationship);

    if (id) {
      return store.peekRecord(modelName, id);
    }

    return store.createRecord(modelName, {
      booking,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Summary': typeof UiBookingSummaryComponent;
  }
}
