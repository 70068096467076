import { cached } from '@glimmer/tracking';
import BaseController from 'uplisting-frontend/pods/base/controller';
import { type ILink } from 'uplisting-frontend/pods/components/ui/nav-links/component';

export default class InsightsController extends BaseController {
  @cached
  get navLinks(): ILink[] {
    return [
      {
        name: this.intl.t('nav_links.insights.all'),
        route: 'insights.all',
        permitted: true,
      },
      {
        name: this.intl.t('nav_links.insights.airbnb'),
        route: 'insights.airbnb',
        permitted: this.currentUser.hasAccessTo('insights.airbnb'),
      },
      {
        name: this.intl.t('nav_links.insights.settings'),
        route: 'insights.settings',
        permitted: this.currentUser.hasAccessTo('insights.settings'),
      },
    ];
  }
}
