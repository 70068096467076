import Model, { attr, belongsTo } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import sumBy from 'lodash-es/sumBy';
import {
  type IIncomeItem,
  type IInvoiceItem,
  type IInvoiceTotal,
  type IClientStatementReportSchema,
} from 'uplisting-frontend/models/schemas';
import ClientStatementModel from 'uplisting-frontend/models/client-statement';

export default class ClientStatementReportModel
  extends Model
  implements IClientStatementReportSchema
{
  @attr('array') incomeItems!: IIncomeItem[];
  @attr('array') invoiceItems!: IInvoiceItem[];
  @attr('array') invoiceTotals!: IInvoiceTotal[];

  @belongsTo('client-statement', {
    inverse: null,
    async: false,
  })
  clientStatement!: ClientStatementModel;

  @cached
  get accommodationTotalSum(): number {
    return sumBy(this.incomeItems, 'accommodation');
  }

  @cached
  get extraGuestChargeSum(): number {
    return sumBy(this.incomeItems, 'extra_guest_charge');
  }

  @cached
  get cleaningFeeSum(): number {
    return sumBy(this.incomeItems, 'cleaning_fee');
  }

  @cached
  get extraChargesSum(): number {
    return sumBy(this.incomeItems, 'extra_charges');
  }

  @cached
  get discountSum(): number {
    return sumBy(this.incomeItems, 'discount');
  }

  @cached
  get channelCommissionSum(): number {
    return sumBy(this.incomeItems, 'channel_commission_with_tax');
  }

  @cached
  get managementFeeSum(): number {
    return sumBy(this.incomeItems, 'management_fee');
  }

  @cached
  get paymentProcessingFeeSum(): number {
    return sumBy(this.incomeItems, 'payment_processing_fee');
  }

  @cached
  get bookingTaxesSum(): number {
    return sumBy(this.incomeItems, 'booking_taxes');
  }

  @cached
  get ownerPayoutSum(): number {
    return sumBy(this.incomeItems, 'owner_payout');
  }

  @cached
  get upsellsSum(): number {
    return sumBy(this.incomeItems, 'upsells');
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'client-statement-report': ClientStatementReportModel;
  }
}
