import DataModel from '@ember-data/model';
import {
  FilterId,
  FilterOptionsType,
  MultiSelectFilter,
} from 'uplisting-frontend/utils/filters/abstract';
import PropertyModel from 'uplisting-frontend/models/property';

export class PropertyFilter extends MultiSelectFilter<PropertyModel> {
  id = FilterId.property;
  optionsType = FilterOptionsType.store;
  modelName = 'property';
  modelField = 'nickname' as keyof DataModel;
}
